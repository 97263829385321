import { useSelector } from "react-redux";
import { selectUser } from "../../../store/slices/authSlice";
import { ListItemIcon, Slide, useMediaQuery } from "@mui/material";
import {
  getTranslation,
  ICON_NAMES,
  transitionDirections,
} from "../../../util/utils";
import { Item } from "../../styles/assets/ListInlineView.styles";
import { messageSuccess } from "../../../util/notification";
import { useTranslation } from "react-i18next";
import {
  AttachmentItemContainer,
  AttachmentNameText,
  DeleteAttachmentButton,
  StyledDeleteIcon,
  StyledDividerWithSpace,
  StyledDownloadIcon,
  StyledEditIcon,
  StyledMoreIcon,
  StyledVisibilityIcon,
} from "../../styles/assets/asset-attachments/AssetResourceAttachment.styles";
import { useState, forwardRef } from "react";
import {
  CancelButton,
  ChangeRoleButton,
  StyledDialog,
  StyledDialogContent,
  StyledDialogTitle,
} from "../../styles/general/General.styles";
import { StyledDivider } from "../../styles/profile/Profile.styles";
import {
  selectGlobalFontSize,
  selectPageView,
} from "../../../store/slices/appSlice";
import {
  useDeleteResourceAttachmentMutation,
  useGetResourceAttachmentQuery,
} from "../../../store/slices/api/resourceAttachmentsApiSlice";
import ConfirmAlert from "../../../store/confirm/ConfirmAlert";
import ErrorHandling from "../../common/ErrorHandling";
import { useNavigate } from "react-router-dom";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import { selectParentId } from "../../../store/slices/columnViewSlice";
import { useDispatch } from "react-redux";
import { deleteResourceAttachment } from "../../../store/slices/resourceAttachmentSlice";
import EditLocalAttachmentDialog from "../asset-form/EditLocalAttachmentDialog";
import { selectCurrentResourceId } from "../../../store/slices/longPressSlice";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const LocalResourceAttachment = ({ attachment, resourceid, index, mode }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const globalFontSize = useSelector(selectGlobalFontSize);
  const [openConfirm, setOpenConfirm] = useState(false);
  const parentId = useSelector(selectParentId);
  const pageView = useSelector(selectPageView);
  const currentResourceId = useSelector(selectCurrentResourceId);

  // Other variables
  const calculatedResourceId = currentResourceId || resourceid || parentId;

  // Queries
  const {
    data: resourceAttachmentUri,
    isLoading: isLoadingAttachment,
    isError: isErrorAttachment,
  } = useGetResourceAttachmentQuery(
    {
      attachmentUri: attachment ? attachment?.selectedFile : "",
      organizationId,
    },
    {
      skip: !attachment || !attachment?.isFromDb,
    }
  );

  // Mutations
  const [deleteResourceAttachmentMutation] =
    useDeleteResourceAttachmentMutation();

  // States
  const [open, setOpen] = useState(false);
  const [openEditAttachment, setOpenEditAttachment] = useState(false);

  // Handlers
  const handleClickOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenEditImage = () => {
    if (mobileMatches) {
      navigate(
        `/resources${
          resourceid ? `/${resourceid}` : ""
        }/edit-local-attachment?direction=${
          transitionDirections.RIGHT_TO_LEFT
        }&index=${index}`
      );
    } else {
      setOpenEditAttachment(true);
    }
  };

  const handleDownloadResourceAttachmentFromLocalFile = (isViewable) => {
    const { file, name } = attachment;
    const url = URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";

    if (!isViewable) {
      link.download = name; // Set the original file name as the downloaded file name
    }

    document.body.appendChild(link);

    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadResourceAttachmentFromDb = (isViewable) => {
    const { name } = attachment;
    const link = document.createElement("a");
    link.href = resourceAttachmentUri;
    link.target = "_blank";

    if (!isViewable) {
      link.download = name; // Set the original file name as the downloaded file name
    }

    document.body.appendChild(link);

    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadResourceAttachment = (isViewable) => {
    if (calculatedResourceId) {
      if (mode === "edit-asset") {
        handleDownloadResourceAttachmentFromDb(isViewable);
      } else {
        handleDownloadResourceAttachmentFromLocalFile(isViewable);
      }
    } else {
      handleDownloadResourceAttachmentFromLocalFile(isViewable);
    }
  };

  const handleDeleteConfirm = () => {
    setOpenConfirm(true);
  };

  const handleDeleteResourceAttachmentFromDb = async (resourceAttachmentId) => {
    await deleteResourceAttachmentMutation({
      resourceid,
      organizationId: user?.organizations?.find((o) => o.default).id,
      resourceAttachmentId,
    }).unwrap();

    messageSuccess(
      getTranslation("RESOURCE_ATTACHMENT_DELETED_SUCCESSFULLY", t, i18n)
    );
  };

  const handleDeleteResourceAttachment = async (resourceAttachmentId) => {
    try {
      if (calculatedResourceId) {
        if (mode === "edit-asset") {
          await handleDeleteResourceAttachmentFromDb(resourceAttachmentId);
        } else {
          dispatch(deleteResourceAttachment(index));
        }
      } else {
        dispatch(deleteResourceAttachment(index));
      }
    } catch (error) {
      console.error("Failed to delete resource attachment", error);
    }
  };

  return (
    <ErrorHandling isLoading={isLoadingAttachment} isError={isErrorAttachment}>
      <AttachmentItemContainer
        mobileMatches={mobileMatches || pageView === "column"}
      >
        <StyledDialog
          fullWidth
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <StyledDialogContent>
            <StyledDialogTitle>{attachment?.name}</StyledDialogTitle>
            <StyledDivider />

            <ChangeRoleButton
              id={`edit-resource-attachment`}
              onClick={() => {
                handleClickOpenEditImage();
                handleClose();
              }}
            >
              <StyledEditIcon customfontsize={globalFontSize * 1.5} />
              {getTranslation("EDIT", t, i18n)}
            </ChangeRoleButton>

            <StyledDivider />

            {attachment.mimeType.includes("pdf") ||
            attachment.mimeType.includes("png") ||
            attachment.mimeType.includes("jpg") ||
            attachment.mimeType.includes("jpeg") ? (
              <ChangeRoleButton
                id={`view-resource-attachment`}
                onClick={() => {
                  handleDownloadResourceAttachment(true);
                  handleClose();
                }}
              >
                <StyledVisibilityIcon customfontsize={globalFontSize * 1.5} />
                {getTranslation("VIEW", t, i18n)}
              </ChangeRoleButton>
            ) : (
              <ChangeRoleButton
                id={`download-resource-attachment`}
                onClick={() => {
                  handleDownloadResourceAttachment(false);
                  handleClose();
                }}
              >
                <StyledDownloadIcon customfontsize={globalFontSize * 1.5} />
                {getTranslation("DOWNLOAD", t, i18n)}
              </ChangeRoleButton>
            )}

            <>
              <StyledDividerWithSpace />
              <DeleteAttachmentButton
                id={`delete-resource-attachment`}
                data-testid={`delete-resource-attachment-${attachment?.id}`}
                color="error"
                onClick={() => {
                  handleClose();
                  handleDeleteConfirm();
                }}
              >
                <StyledDeleteIcon customfontsize={globalFontSize * 1.5} />
                {getTranslation("DELETE", t, i18n)}
              </DeleteAttachmentButton>
            </>
          </StyledDialogContent>
          <CancelButton id={`cancel-button`} onClick={handleClose}>
            {getTranslation("CANCEL", t, i18n)}
          </CancelButton>
        </StyledDialog>

        <ConfirmAlert
          isOpen={openConfirm}
          setIsOpen={setOpenConfirm}
          alert={{
            content: getTranslation("ASSET_ATTACHMENT_DELETE_MESSAGE", t, i18n),
            confirmTitle: getTranslation("DELETE_ASSET_ATTACHMENT", t, i18n),
            closeTitle: getTranslation("CANCEL", t, i18n),
            showConfirm: true,
          }}
          label="delete-attachment"
          handleConfirm={() => handleDeleteResourceAttachment(attachment?.id)}
        />

        {openEditAttachment && (
          <EditLocalAttachmentDialog
            resourceId={mode === "edit-asset" ? calculatedResourceId : null}
            open={openEditAttachment}
            setOpen={setOpenEditAttachment}
            resourceAttachment={attachment}
            index={index}
          />
        )}

        <Item
          onClick={
            attachment.mimeType.includes("pdf") ||
            attachment.mimeType.includes("png") ||
            attachment.mimeType.includes("jpg") ||
            attachment.mimeType.includes("jpeg")
              ? () => handleDownloadResourceAttachment(true)
              : () => handleDownloadResourceAttachment(false)
          }
        >
          <ListItemIcon>
            <img
              width={globalFontSize * 1.5}
              height={globalFontSize * 1.5}
              src={`${process.env.PUBLIC_URL}/icons/${ICON_NAMES["ICN-EXPERT-ATTACHMENTS"]}`}
              alt="icon"
            />
          </ListItemIcon>
          <AttachmentNameText>{attachment.name}</AttachmentNameText>

          <StyledMoreIcon
            customfontsize={globalFontSize * 1.5}
            id="more-attachment-options"
            data-testid={`more-attachment-options-${attachment.name}`}
            onClick={handleClickOpen}
          />
        </Item>
      </AttachmentItemContainer>
    </ErrorHandling>
  );
};

export default LocalResourceAttachment;
