import {
  Box,
  ListItemIcon,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useOrientation from "../../../hooks/useOrientation";
import {
  selectIsSearchOpen,
  selectSearchTerm,
  selectSortingOption,
  selectGraphicalViewAssetDisplay,
  setGraphicalViewAssetDisplay,
  selectGlobalFontSize,
} from "../../../store/slices/appSlice";
import {
  selectColumns,
  selectFullWidthColumnId,
  selectParentId,
  selectTrail,
  setColumns,
  setFullWidthColumnId,
  setOverflowX,
  setTrail,
} from "../../../store/slices/columnViewSlice";
import {
  CATEGORY_SELECT_LIST,
  DESKTOP_COLUMNS,
  getFallbackIcon,
  getTranslation,
  RESOURCE_CATEGORIES,
  TABLET_LANDSCAPE_COLUMNS,
  TABLET_PORTRAIT_COLUMNS,
  transitionDirections,
} from "../../../util/utils";
import { SecondaryText } from "../../styles/assets/ListInlineView.styles";
import { GroupByResourceCategoryContainer } from "../../styles/general/General.styles";
import AssetDetailListColumnVersion from "../asset-detail/AssetDetailListColumnVersion";
import CustomTabPanel from "../../CustomTabPanel";
import { useTranslation } from "react-i18next";
import ResourceItem from "./ResourceItem";
import {
  useGetAllCharacteristicsQuery,
  useGetAllFunctionsQuery,
  useGetResourceChildrenQuery,
  useGetResourceDetailsQuery,
  useGetResourceParentsQuery,
} from "../../../store/slices/api/assetManagementSlice";
import { selectUser } from "../../../store/slices/authSlice";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import CreateAsset from "../asset-form/CreateAsset";
import Floorplan from "../floorplan/Floorplan";
import FullScreenFloorplan from "../floorplan/FullScreenFloorplan";
import CreateNewButton from "../../buttons/CreateNewButton";
import { useGetGraphicalObjectsListQuery } from "../../../store/slices/api/graphicalObjectsSlice";
import CreateGraphicalRoomObject from "../floorplan/CreateGraphicalRoomObject";
import {
  ListColumnViewCardContainer,
  ListContainer,
  StyledTab,
  TabsContainer,
  TabsWrapper,
} from "../../styles/assets/asset-list/ListColumnViewCard.styles";
import GraphicalRackView from "../graphical-rack-view/GraphicalRackView";
import { NoAssetsContainer } from "../../styles/assets/AssetList.styles";
import { StyledMenu } from "./AssetListTabs";
import { ASSET_DISPLAY_MODES } from "../../../util/graphical-rack-view-utils";
import ErrorHandling from "../../common/ErrorHandling";
import LoadingSpinner from "../../common/LoadingSpinner";
import CategoryMenu from "../asset-form/CategoryMenu";
import { selectFitScreen } from "../../../store/slices/floorplanSlice";
import GridViewCardMedia from "./GridViewCardMedia";
import { ChildrenCountText } from "../../styles/assets/asset-list/ResourceItem.styles";
import CarouselSlider from "../asset-detail/CarouselSlider";
import { getSvgIcon } from "../../../util/icons";
import { RouletteSpinner } from "react-spinner-overlay";

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const ListColumnViewCard = ({ column, favorites, userRoles }) => {
  // General hooks
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);
  const desktopMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.DESKTOP);

  // Custom hooks
  const orientation = useOrientation();

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);
  const sortingOption = useSelector(selectSortingOption);
  const searchTerm = useSelector(selectSearchTerm);
  const trail = useSelector(selectTrail);
  const user = useSelector(selectUser);
  const columns = useSelector(selectColumns);
  const isSearchOpen = useSelector(selectIsSearchOpen);
  const fullWidthColumnId = useSelector(selectFullWidthColumnId);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const graphicalViewAssetDisplay = useSelector(
    selectGraphicalViewAssetDisplay
  );
  const fitScreen = useSelector(selectFitScreen);
  const parentId = useSelector(selectParentId);

  // States
  const [value, setValue] = useState(0);
  const [showGraphicalView, setShowGraphicalView] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [anchorElCategoryMenu, setAnchorElCategoryMenu] = useState(null);
  const [initializedCategory, setInitializedCategory] = useState("");

  // Other variables
  const iconSize = globalFontSize * 1.5;

  // Queries
  const resourceParentsData = useGetResourceParentsQuery(
    {
      organizationId,
    },
    {
      skip: Boolean(column.parentId) || column.columnType === "search",
    }
  );

  const resourceChildrenData = useGetResourceChildrenQuery(
    {
      resourceid: column.parentId,
      organizationId,
    },
    {
      skip: !Boolean(column.parentId) || column.columnType === "search",
    }
  );

  const {
    data: resourceData,
    isLoading: isLoadingResource,
    isError: isErrorResource,
  } = useGetResourceDetailsQuery(
    {
      resourceid: column.parentId,
      organizationId,
    },
    {
      skip: !Boolean(column.parentId) || column.columnType === "search",
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: graphicalObjectsData,
    isLoading: isLoadingGraphicalObjects,
    isError: isErrorGraphicalObjects,
  } = useGetGraphicalObjectsListQuery(
    {
      resourceId: column.parentId,
      organizationId: user?.organizations?.find((o) => o.default).id,
    },
    {
      skip: !Boolean(column.parentId) || column.columnType === "search",
    }
  );

  const {
    data: allFunctions,
    isLoading: isLoadingFunctions,
    isError: isErrorFunctions,
  } = useGetAllFunctionsQuery({ organizationId });

  const {
    data: characteristicDefinitionsData,
    isLoading: isLoadingCharacteristicDefinitions,
    isError: isErrorCharacteristicDefinitions,
  } = useGetAllCharacteristicsQuery({ organizationId });

  // Other variables
  const open = Boolean(anchorEl);

  const openCategoryMenu = Boolean(anchorElCategoryMenu);

  const resourceFunction = allFunctions?.find(
    (f) => f.id === resourceData?.functionId
  );

  const isRack = resourceFunction?.category === RESOURCE_CATEGORIES.RACK;
  const isGraphicalActiveTab = isRack && value === 2;
  const isDetailsActiveTab = value === 1;

  const {
    displayId,
    name,
    functionId,
    images: resourceImages,
    type,
  } = resourceData ?? {};

  const combinationDisplayIdAndName = displayId || name;

  const currentResourceFunction = allFunctions?.find(
    (f) => f.id === functionId
  );

  const typeImages =
    type?.images.map((img) => {
      return {
        ...img,
        uri: `/organizations/${user?.organizations?.find((o) => o.default).id}${
          img.uri
        }`,
      };
    }) ?? [];

  const images = resourceImages ?? [];

  const combinedTypeAndResourceImages = [...images, ...typeImages];

  const {
    data: resourcesData,
    isLoading: isLoadingResources,
    isError: isErrorResources,
  } = column.parentId ? resourceChildrenData : resourceParentsData;

  // Handlers
  const handleOpenDialog = (category) => {
    setInitializedCategory(category);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClick = (event) => {
    if (value === 2) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigateToCreateAsset = (category) => {
    if (column.parentId) {
      navigate(
        `/resources/add-new?resourceid=${column.parentId}&category=${category}&direction=${transitionDirections.BOTTOM_TO_TOP}`
      );
    } else {
      navigate(
        `/resources/add-new?category=${category}&direction=${transitionDirections.BOTTOM_TO_TOP}`
      );
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSelectAssetDisplayMode = (mode) => {
    dispatch(setGraphicalViewAssetDisplay(mode));
    handleClose();
  };

  const checkHasRackUnits = () => {
    const rackUnitsCharacteristicId = characteristicDefinitionsData?.find(
      (c) => c.name === "RACK_UNIT_CAPACITY"
    )?.id;

    const resourceRackUnits = resourceData?.characteristics.find(
      (c) => c.id === rackUnitsCharacteristicId
    );

    const typeRackUnits = resourceData?.type?.characteristics.find(
      (c) => c.id === rackUnitsCharacteristicId
    );

    const rackUnits = parseInt(
      resourceRackUnits?.value || typeRackUnits?.value
    );

    return rackUnits > 0;
  };

  const handleOpenCategoryMenu = (e) => {
    setAnchorElCategoryMenu(e.currentTarget);
  };

  const handleCloseCategoryMenu = () => {
    setAnchorElCategoryMenu(null);
  };

  // Effects
  useEffect(() => {
    if (resourcesData) {
      const existingColumn = columns.find(
        (c) => c.parentId === column.parentId
      );

      if (existingColumn) {
        const newColumns = columns.map((c) => {
          return c.parentId === column.parentId
            ? {
                ...c,
                items: resourcesData.data ?? [],
              }
            : c;
        });

        dispatch(
          setColumns({
            columns: newColumns,
            sortBy: sortingOption.value,
            order: sortingOption.order,
            favorites,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourcesData]);

  useEffect(() => {
    if (!isLoadingResource && isErrorResource) {
      const newColumns = columns.filter((c) => {
        return c.level < column.level;
      });

      dispatch(
        setColumns({
          columns: newColumns,
          sortBy: sortingOption.value,
          order: sortingOption.order,
          favorites,
        })
      );

      dispatch(setTrail(newColumns.map((c) => c.parentId)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorResource, isLoadingResource]);

  // Control to directly show details if no children
  useEffect(() => {
    if (column?.items?.length <= 0 && column.parentId) {
      setValue(1);
    } else {
      setValue(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [column?.items?.length]);

  useEffect(() => {
    const newColumns = columns.filter((c) => {
      return c.level <= column.level;
    });

    dispatch(
      setColumns({
        columns: newColumns,
        sortBy: sortingOption.value,
        order: sortingOption.order,
        favorites,
      })
    );

    dispatch(setTrail(newColumns.map((c) => c.parentId)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  // Control whether a column should be full-width
  useEffect(() => {
    if (resourceFunction?.name === "Room" && value === 2) {
      dispatch(setFullWidthColumnId(resourceData.id));
      dispatch(setOverflowX("hidden"));
    } else {
      dispatch(setFullWidthColumnId(""));
      dispatch(setOverflowX("auto"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceFunction?.name, value]);

  useEffect(() => {
    if (
      resourceFunction?.category === RESOURCE_CATEGORIES.RACK &&
      value === 2
    ) {
      setTimeout(() => {
        setShowGraphicalView(true);
      }, 2000);
    } else {
      setShowGraphicalView(false);
    }
  }, [value, resourceFunction]);
  // Tabs container and each Tab individually with height: 36px and minHeight 36px
  return (
    <ErrorHandling
      isLoading={false}
      isError={
        isErrorCharacteristicDefinitions ||
        isErrorFunctions ||
        isErrorGraphicalObjects ||
        isErrorResource ||
        isErrorResources
      }
    >
      <ListColumnViewCardContainer
        isgraphicalactivetab={isGraphicalActiveTab}
        israck={isRack}
        isdesktop={desktopMatches}
        isfullwidth={fullWidthColumnId === column.parentId}
        islandscape={orientation === "landscape"}
        isdetailstab={isDetailsActiveTab}
        item
        xs={
          desktopMatches
            ? fullWidthColumnId === column.parentId
              ? 12
              : DESKTOP_COLUMNS
            : orientation === "landscape"
            ? TABLET_PORTRAIT_COLUMNS
            : TABLET_LANDSCAPE_COLUMNS
        }
      >
        {isLoadingCharacteristicDefinitions ||
        isLoadingFunctions ||
        isLoadingGraphicalObjects ||
        isLoadingResource ||
        isLoadingResources ? (
          <LoadingSpinner />
        ) : (
          <>
            <StyledMenu
              id="graphical-display-options-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                sx={{
                  backgroundColor:
                    graphicalViewAssetDisplay === ASSET_DISPLAY_MODES.IMAGES
                      ? "#E6EFF5"
                      : theme.palette.primary.contrastText,
                }}
                onClick={() =>
                  handleSelectAssetDisplayMode(ASSET_DISPLAY_MODES.IMAGES)
                }
                disableRipple
              >
                {getTranslation("IMAGES_VIEW", t, i18n)}
              </MenuItem>
              <MenuItem
                sx={{
                  backgroundColor:
                    graphicalViewAssetDisplay === ASSET_DISPLAY_MODES.BITMAPS
                      ? "#E6EFF5"
                      : theme.palette.primary.contrastText,
                }}
                onClick={() =>
                  handleSelectAssetDisplayMode(ASSET_DISPLAY_MODES.BITMAPS)
                }
                disableRipple
              >
                {getTranslation("BITMAP_VIEW", t, i18n)}
              </MenuItem>
              <MenuItem
                sx={{
                  backgroundColor:
                    graphicalViewAssetDisplay === ASSET_DISPLAY_MODES.BOX
                      ? "#E6EFF5"
                      : theme.palette.primary.contrastText,
                }}
                onClick={() =>
                  handleSelectAssetDisplayMode(ASSET_DISPLAY_MODES.BOX)
                }
                disableRipple
              >
                {getTranslation("BOX_VIEW", t, i18n)}
              </MenuItem>
            </StyledMenu>

            {column.parentId && Boolean(resourceData) ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "15px",
                    marginTop: "10px",
                  }}
                >
                  <Box>
                    <GridViewCardMedia
                      functionName={currentResourceFunction?.name}
                      resourceImages={resourceImages}
                      size={43}
                    />
                  </Box>

                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <div style={{ marginRight: "5px" }}>
                        {getSvgIcon(
                          resourceFunction?.name?.toUpperCase(),
                          iconSize,
                          iconSize,
                          theme.palette.secondary.light
                        )}
                      </div>

                      <Typography>{combinationDisplayIdAndName}</Typography>
                    </Box>

                    <ChildrenCountText variant="body2">
                      {currentResourceFunction?.name}
                    </ChildrenCountText>
                  </Box>
                </Box>

                <TabsWrapper
                  elevation={0}
                  isfullwidth={fullWidthColumnId === column.parentId}
                  isdesktop={desktopMatches}
                  islandscape={orientation === "landscape"}
                  isGraphicalView={
                    resourceFunction?.category === "RACK" && value === 2
                  }
                >
                  <TabsContainer
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    textColor="primary"
                    fitScreen={fitScreen}
                  >
                    <StyledTab
                      active={value === 0}
                      label={getTranslation("CONTENT", t, i18n)}
                      {...a11yProps(0)}
                    />

                    <StyledTab
                      active={value === 1}
                      label={getTranslation("DETAILS", t, i18n)}
                      {...a11yProps(1)}
                    />

                    {resourceFunction?.category === "RACK" &&
                      checkHasRackUnits() && (
                        <StyledTab
                          onClick={handleClick}
                          active={value === 2}
                          currentValue={value}
                          label={
                            getTranslation("GRAPHICAL_VIEW", t, i18n) +
                            `${value === 2 ? "▼" : ""}`
                          }
                          {...a11yProps(2)}
                        />
                      )}

                    {/* Hide Floorplan  */}
                    {/* {resourceFunction?.name === "Room" && desktopMatches && (
                      <StyledTab
                        active={value === 2}
                        label={getTranslation("FLOORPLAN", t, i18n)}
                        {...a11yProps(2)}
                      />
                    )} */}
                  </TabsContainer>
                </TabsWrapper>

                <CustomTabPanel value={value} index={0}>
                  {column?.items?.length <= 0 && (
                    <SecondaryText sx={{ marginTop: "20px", textWrap: "wrap" }}>
                      {getTranslation("NO_ASSETS", t, i18n)}
                    </SecondaryText>
                  )}

                  {sortingOption.value === "CATEGORY" ? (
                    Boolean(isSearchOpen) ? (
                      CATEGORY_SELECT_LIST.sort(
                        (a, b) => a.label > b.label
                      ).map(
                        (category) =>
                          column.items?.filter((item) => {
                            const { name, displayId } = item;
                            const combination = displayId || name;
                            const itemFunction = allFunctions?.find(
                              (f) => f.id === item.functionId
                            );

                            return (
                              combination
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase()) &&
                              itemFunction?.category === category.value
                            );
                          })?.length > 0 && (
                            <GroupByResourceCategoryContainer
                              key={category.value}
                            >
                              <SecondaryText>
                                {category.label + "s"}
                              </SecondaryText>
                              <ListContainer id="assets-list">
                                {column.items
                                  ?.filter((item) => {
                                    const { name, displayId } = item;
                                    const combination = displayId || name;
                                    const itemFunction = allFunctions?.find(
                                      (f) => f.id === item.functionId
                                    );
                                    return (
                                      combination
                                        .toLowerCase()
                                        .includes(searchTerm.toLowerCase()) &&
                                      itemFunction?.category === category.value
                                    );
                                  })
                                  ?.map((row) => (
                                    <ResourceItem
                                      key={`resource-${row.id}`}
                                      isSelected={trail.some(
                                        (item) => item === row.id
                                      )}
                                      isActive={parentId === row.id}
                                      resource={row}
                                    />
                                  ))}
                              </ListContainer>
                            </GroupByResourceCategoryContainer>
                          )
                      )
                    ) : (
                      CATEGORY_SELECT_LIST.sort(
                        (a, b) => a.label > b.label
                      ).map(
                        (category) =>
                          column.items?.filter((item) => {
                            const itemFunction = allFunctions?.find(
                              (f) => f.id === item.functionId
                            );
                            return itemFunction?.category === category.value;
                          })?.length > 0 && (
                            <GroupByResourceCategoryContainer
                              key={category.value}
                            >
                              <SecondaryText>
                                {category.label + "s"}
                              </SecondaryText>
                              <ListContainer id="assets-list">
                                {column.items
                                  ?.filter((item) => {
                                    const itemFunction = allFunctions?.find(
                                      (f) => f.id === item.functionId
                                    );

                                    return (
                                      itemFunction?.category === category.value
                                    );
                                  })
                                  ?.map((row) => (
                                    <ResourceItem
                                      key={`resource-${row.id}`}
                                      isSelected={trail.some(
                                        (item) => item === row.id
                                      )}
                                      isActive={parentId === row.id}
                                      resource={row}
                                    />
                                  ))}
                              </ListContainer>
                            </GroupByResourceCategoryContainer>
                          )
                      )
                    )
                  ) : (
                    <ListContainer id="assets-list">
                      {Boolean(isSearchOpen)
                        ? column.items
                            ?.filter((item) => {
                              const { name, displayId } = item;
                              const combination = displayId || name;

                              return combination
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase());
                            })
                            ?.map((row) => (
                              <ResourceItem
                                key={`resource-${row.id}`}
                                isSelected={trail.some(
                                  (item) => item === row.id
                                )}
                                isActive={parentId === row.id}
                                resource={row}
                              />
                            ))
                        : column.items?.map((row) => (
                            <ResourceItem
                              key={`resource-${row.id}`}
                              isSelected={trail.some((item) => item === row.id)}
                              isActive={parentId === row.id}
                              resource={row}
                            />
                          ))}
                    </ListContainer>
                  )}
                </CustomTabPanel>

                <CustomTabPanel value={value} index={1}>
                  <React.Fragment>
                    <CarouselSlider
                      resourceImages={combinedTypeAndResourceImages}
                    />

                    <AssetDetailListColumnVersion resourceData={resourceData} />

                    <div id="end"></div>
                  </React.Fragment>
                </CustomTabPanel>

                {resourceFunction?.category === "RACK" &&
                  checkHasRackUnits() && (
                    <CustomTabPanel
                      value={value}
                      index={2}
                      sx={{ overflow: "hidden" }}
                    >
                      {showGraphicalView ? (
                        <GraphicalRackView
                          currentResourceData={resourceData}
                          resourceImages={[]}
                          handleImageChange={() => {}}
                          currentIndex={0}
                        />
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            paddingBlock: "16px",
                          }}
                        >
                          <RouletteSpinner
                            loading={true}
                            color={theme.palette.secondary.contrastText}
                          />
                        </Box>
                      )}
                    </CustomTabPanel>
                  )}

                {/* Hide Floorplan  */}
                {/* {resourceFunction?.name === "Room" && desktopMatches && (
                  <CustomTabPanel type="floorplan" value={value} index={2}>
                    {graphicalObjectsData?.some(
                      (graphicalObject) =>
                        graphicalObject.parentResourceId === column.parentId &&
                        graphicalObject.type === "GENERAL"
                    ) ? (
                      <>
                        <FullScreenFloorplan resourceId={column.parentId} />
                        <Floorplan
                          resourceId={column.parentId}
                          shouldReset={true}
                        />
                      </>
                    ) : (
                      <CreateGraphicalRoomObject
                        resourceId={column.parentId}
                        userRoles={userRoles}
                      />
                    )}
                  </CustomTabPanel>
                )} */}
              </>
            ) : (
              // Parent Column
              <>
                {column?.items?.length <= 0 && (
                  <SecondaryText
                    sx={{
                      marginTop: "20px",
                      textWrap: "wrap",
                      color: theme.palette.primary.main,
                    }}
                  >
                    {getTranslation("NO_ASSETS", t, i18n)}
                  </SecondaryText>
                )}

                {sortingOption.value === "CATEGORY" ? (
                  Boolean(isSearchOpen) ? (
                    CATEGORY_SELECT_LIST.sort((a, b) => a.label > b.label).map(
                      (category) =>
                        column.items?.filter((item) => {
                          const { name, displayId } = item;
                          const combination = displayId || name;
                          const itemFunction = allFunctions?.find(
                            (f) => f.id === item.functionId
                          );
                          return (
                            combination
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase()) &&
                            itemFunction?.category === category.value
                          );
                        })?.length > 0 && (
                          <GroupByResourceCategoryContainer
                            key={category.value}
                          >
                            <SecondaryText>
                              {category.label + "s"}
                            </SecondaryText>
                            <ListContainer id="assets-list">
                              {column.items
                                ?.filter((item) => {
                                  const { name, displayId } = item;
                                  const combination = displayId || name;
                                  const itemFunction = allFunctions?.find(
                                    (f) => f.id === item.functionId
                                  );

                                  return (
                                    combination
                                      .toLowerCase()
                                      .includes(searchTerm.toLowerCase()) &&
                                    itemFunction?.category === category.value
                                  );
                                })
                                ?.map((row) => (
                                  <ResourceItem
                                    key={`resource-${row.id}`}
                                    isSelected={trail.some(
                                      (item) => item === row.id
                                    )}
                                    isActive={parentId === row.id}
                                    resource={row}
                                  />
                                ))}
                            </ListContainer>
                          </GroupByResourceCategoryContainer>
                        )
                    )
                  ) : (
                    CATEGORY_SELECT_LIST.sort((a, b) => a.label > b.label).map(
                      (category) =>
                        column.items?.filter((item) => {
                          const itemFunction = allFunctions?.find(
                            (f) => f.id === item.functionId
                          );

                          return itemFunction?.category === category.value;
                        })?.length > 0 && (
                          <GroupByResourceCategoryContainer
                            key={category.value}
                          >
                            <SecondaryText>
                              {category.label + "s"}
                            </SecondaryText>
                            <ListContainer id="assets-list">
                              {column.items
                                ?.filter((item) => {
                                  const itemFunction = allFunctions?.find(
                                    (f) => f.id === item.functionId
                                  );

                                  return (
                                    itemFunction?.category === category.value
                                  );
                                })
                                ?.map((row) => (
                                  <ResourceItem
                                    key={`resource-${row.id}`}
                                    isSelected={trail.some(
                                      (item) => item === row.id
                                    )}
                                    isActive={parentId === row.id}
                                    resource={row}
                                  />
                                ))}
                            </ListContainer>
                          </GroupByResourceCategoryContainer>
                        )
                    )
                  )
                ) : (
                  <ListContainer id="assets-list">
                    {Boolean(isSearchOpen)
                      ? column.items
                          ?.filter((item) => {
                            const { name, displayId } = item;
                            const combination = displayId || name;

                            return combination
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase());
                          })
                          ?.map((row) => (
                            <ResourceItem
                              key={`resource-${row.id}`}
                              isSelected={trail.some((item) => item === row.id)}
                              isActive={parentId === row.id}
                              resource={row}
                            />
                          ))
                      : column.items?.map((row) => (
                          <ResourceItem
                            key={`resource-${row.id}`}
                            isSelected={trail.some((item) => item === row.id)}
                            isActive={parentId === row.id}
                            resource={row}
                          />
                        ))}
                  </ListContainer>
                )}
              </>
            )}

            {value === 0 && (
              <>
                <CreateNewButton
                  handler={handleOpenCategoryMenu}
                  userRoles={userRoles}
                  name="CREATE_NEW_ASSET"
                />

                {openDialog && (
                  <CreateAsset
                    initializedParentId={column.parentId}
                    initializedCategory={initializedCategory}
                    open={openDialog}
                    handleClose={handleCloseDialog}
                  />
                )}
              </>
            )}
          </>
        )}

        <CategoryMenu
          open={openCategoryMenu}
          handleClose={handleCloseCategoryMenu}
          parentCategory={resourceFunction?.category}
          categoryActionHandler={
            !mobileMatches ? handleOpenDialog : handleNavigateToCreateAsset
          }
          anchorEl={anchorElCategoryMenu}
        />
      </ListColumnViewCardContainer>
    </ErrorHandling>
  );
};

export default memo(ListColumnViewCard);
