import { IconButton, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  useAddTypeFavoriteMutation,
  useDeleteTypeFavoriteMutation,
  useGetTypeFavoritesQuery,
} from "../../../store/slices/api/typesApiSlice";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";
import { selectUser } from "../../../store/slices/authSlice";
import { messageError } from "../../../util/notification";
import { getTranslation } from "../../../util/utils";
import ErrorHandling from "../../common/ErrorHandling";
import { NavigationActionIcon } from "../../styles/menu/Menu.styles";
import { getSvgIcon } from "../../../util/icons";

const AddFavoriteType = ({ typeId }) => {
  // General hooks
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);
  const user = useSelector(selectUser);

  // Other variables
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const iconSize = globalFontSize * 1.5;

  // Mutations
  const [addFavorite] = useAddTypeFavoriteMutation();
  const [deleteFavorite] = useDeleteTypeFavoriteMutation();

  // Queries
  const {
    data: dataFavorites,
    isLoading,
    isError,
  } = useGetTypeFavoritesQuery({ organizationId });

  // Handlers
  const handleAddFavorite = async () => {
    try {
      await addFavorite({ organizationId, typeId }).unwrap();
    } catch (error) {
      messageError(getTranslation("failedAddFavoriteType", t, i18n));
    }
  };

  const handleDeleteFavorite = async () => {
    try {
      await deleteFavorite({ organizationId, typeId }).unwrap();
    } catch (error) {
      messageError(getTranslation("failedRemoveFavoriteType", t, i18n));
    }
  };

  const isFavorite = dataFavorites?.data?.some((f) => f.type.id === typeId);

  return (
    <ErrorHandling isLoading={isLoading} isError={isError}>
      <IconButton
        onClick={isFavorite ? handleDeleteFavorite : handleAddFavorite}
      >
        <NavigationActionIcon active={true} customfontsize={globalFontSize}>
          {isFavorite
            ? getSvgIcon(
                "STAR_FILLED",
                iconSize,
                iconSize,
                theme.palette.secondary.contrastText
              )
            : getSvgIcon(
                "STAR",
                iconSize,
                iconSize,
                theme.palette.secondary.contrastText
              )}
        </NavigationActionIcon>
      </IconButton>
    </ErrorHandling>
  );
};

export default AddFavoriteType;
