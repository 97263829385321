import React from "react";
import { useSelector } from "react-redux";
import { useGetResourceImageQuery } from "../../../store/slices/api/resourceImagesApiSlice";
import ErrorHandling from "../../common/ErrorHandling";
import CanvasImage from "../../common/CanvasImage";
import { selectUser } from "../../../store/slices/authSlice";
import { getSvgIcon } from "../../../util/icons";
import { useTheme } from "@mui/material";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";

const TableMedia = ({ resourceImages, functionName }) => {
  // General hooks
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Other variables
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const iconSize = globalFontSize * 2;

  // Queries
  const {
    data: resourceImageUri,
    isLoading,
    isError,
  } = useGetResourceImageQuery(
    {
      imageUri: resourceImages ? resourceImages[0]?.uri : "",
      organizationId,
    },
    {
      skip: !Boolean(resourceImages) || resourceImages.length <= 0,
    }
  );

  return (
    <ErrorHandling isLoading={isLoading} isError={isError}>
      {resourceImageUri && resourceImages?.length > 0 ? (
        <CanvasImage
          id={resourceImages[0]?.name}
          width={50}
          height={50}
          image={
            resourceImages[0]?.name?.endsWith(".svg")
              ? `${process.env.PUBLIC_URL}/icons/${resourceImages[0]?.name}`
              : resourceImageUri
          }
        />
      ) : (
        getSvgIcon(
          functionName?.toUpperCase(),
          iconSize,
          iconSize,
          theme.palette.secondary.light
        )
      )}
    </ErrorHandling>
  );
};

export default TableMedia;
