import { useState, useEffect, useRef, useMemo } from "react";
import {
  useGetAllCharacteristicsQuery,
  useGetAllFunctionsQuery,
  useGetResourceChildrenQuery,
} from "../../../store/slices/api/assetManagementSlice";
import { Rect } from "react-konva";
import { useMediaQuery } from "@mui/material";
import {
  FIT_WIDTH_MODE_EMPTY_SPACE_PERCENTS,
  PIXELS_PER_RACK_UNIT,
  RESOURCE_CATEGORIES,
  SIDE_FRAMES_PERCENTS,
  USABLE_AREA_PERCENTS,
} from "../../../util/utils";
import { BACKEND_URL } from "../../../Constants";
import KeycloakService from "../../../services/KeycloakService";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../store/slices/authSlice";
import {
  selectGraphicalViewAssetDisplay,
  selectGraphicalViewFitMode,
  selectPageView,
} from "../../../store/slices/appSlice";
import { useCallback } from "react";
import GraphicalRackViewStage from "./GraphicalRackViewStage";
import {
  selectIsGraphicalViewLoading,
  setIsGraphicalViewLoading,
} from "../../../store/slices/assetListSlice";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import {
  useGetGraphicalObjectsListQuery,
  usePatchGraphicalObjectMutation,
} from "../../../store/slices/api/graphicalObjectsSlice";
import GraphicalRackObjects from "./GraphicalRackObjects";
import { mergeCharacteristics } from "../../../util/asset-utils";
import {
  getCalculatedHeight,
  haveIntersection,
  getView,
  ASSET_DISPLAY_MODES,
  DESKTOP_NAV_BAR_HEIGHT,
  DESKTOP_HEADER_HEIGHT,
  COLUMN_VIEW_TABS_HEIGHT,
  TABLET_NAV_BAR_HEIGHT,
  TOP_BORDER_HEIGHT,
  TABLET_DETAILS_PAGE_NAV_BAR_HEIGHT,
  TABLET_DETAILS_PAGE_TABS_HEIGHT,
} from "../../../util/graphical-rack-view-utils";
import {
  GraphicalRackViewContainer,
  GraphicalRackViewStageContainer,
} from "../../styles/assets/graphical-rack-view/GraphicalRackView.styles";
import TopBorder from "./TopBorder";
import { reset } from "../../../store/slices/undoRedoGraphicalViewSlice";
import HardwareAssetCanvas from "./HardwareAssetCanvas";
import { setVisible } from "../../../store/slices/tooltipSlice";
import ErrorHandling from "../../common/ErrorHandling";
import LoadingSpinnerOverlay from "../../common/LoadingSpinnerOverlay";

const GraphicalRackView = ({ currentResourceData }) => {
  const { id: resourceId } = currentResourceData ?? {};

  // General hooks
  const dispatch = useDispatch();
  const tabletMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.TABLET);
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);
  const desktopMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.DESKTOP);

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const isGraphicalViewLoading = useSelector(selectIsGraphicalViewLoading);
  const graphicalViewAssetDisplay = useSelector(
    selectGraphicalViewAssetDisplay
  );
  const appView = useSelector(selectPageView);
  const graphicalViewFitMode = useSelector(selectGraphicalViewFitMode);

  // Queries
  const {
    data: resourcesData,
    isLoading: isLoadingResources,
    isError: isErrorResources,
  } = useGetResourceChildrenQuery({
    resourceid: resourceId,
    organizationId,
  });

  const {
    data: allFunctionsData,
    isLoading: isLoadingFunctionsData,
    isError: isErrorFunctions,
  } = useGetAllFunctionsQuery({
    organizationId,
  });

  const {
    data: graphicalObjects,
    isLoading: isLoadingGraphicalObjects,
    isError: isErrorGraphicalObjects,
  } = useGetGraphicalObjectsListQuery({ resourceId, organizationId });

  const { data: characteristicDefinitionsData } = useGetAllCharacteristicsQuery(
    { organizationId }
  );

  // Mutations
  const [patchGraphicalObject, { isLoading: isLoadingPatchGraphicalObject }] =
    usePatchGraphicalObjectMutation();

  // Refs
  const graphicalViewHeaderRef = useRef();

  // States
  const [graphicalView, setGraphicalView] = useState(null);
  const [graphicalViewSide, setGraphicalViewSide] = useState("front");
  const [loadedAssets, setLoadedAssets] = useState([]);
  const [locked, setLocked] = useState(true);
  const [openGraphicalObjects, setOpenGraphicalObjects] = useState(false);
  const [graphicalRackWidth, setGraphicalRackWidth] = useState(0);

  // Other variables
  const currentResourceFunction = allFunctionsData?.find(
    (f) => f.id === currentResourceData.functionId
  );

  const resizeRackCheck = !mobileMatches && graphicalViewFitMode === "height";
  const hardwareAssetResources = useMemo(
    () =>
      resourcesData?.data?.filter((resource) => {
        const resourceFunction = allFunctionsData?.find(
          (f) => f.id === resource.functionId
        );

        return (
          resourceFunction?.category === RESOURCE_CATEGORIES.HARDWARE_ASSET
        );
      }),
    [resourcesData, allFunctionsData]
  );

  // Handlers

  const getRows = () => {
    const rackUnitsCharacteristicId = characteristicDefinitionsData?.find(
      (c) => c.name === "RACK_UNIT_CAPACITY"
    )?.id;

    const rackUnits = currentResourceData.characteristics.find(
      (c) => c.id === rackUnitsCharacteristicId
    );

    const typeRackUnits = currentResourceData.type?.characteristics.find(
      (c) => c.id === rackUnitsCharacteristicId
    );

    return parseInt(rackUnits?.value || typeRackUnits?.value) + 1;
  };

  const toggleLock = () => {
    setLocked(!locked);
    dispatch(setVisible(false));
    dispatch(reset());
  };

  const handleOpenGraphicalObjects = () => {
    setOpenGraphicalObjects(true);
  };

  const handleDragMoveStage = (e, allowedMaxScroll) => {
    if (e.target.attrs.name === "stage") {
      e.target.x(0);

      if (e.target.attrs.y > 0) {
        e.target.y(0);
      }

      if (e.target.attrs.y <= -allowedMaxScroll) {
        e.target.y(-allowedMaxScroll);
      }

      if (e.target.attrs.y < -PIXELS_PER_RACK_UNIT * 6) {
        graphicalViewHeaderRef.current.style.zIndex = 100;
      } else {
        graphicalViewHeaderRef.current.style.zIndex = 0;
      }
    }
  };

  const handleDragEndStage = (e) => {
    if (e.target.attrs.name === "stage") {
      e.target.x(0);
    }
  };

  const handleChangeGraphicalRackViewSide = () => {
    if (graphicalViewSide === "front") {
      setGraphicalViewSide("rear");
    } else {
      setGraphicalViewSide("front");
    }

    setLoadedAssets([]);
    setIsGraphicalViewLoading(true);
  };

  const handlePrepareGraphicalRackViewInfo = useCallback(
    async (latestImagesInfo = []) => {
      const typeCharacteristics = currentResourceData?.type?.characteristics;
      const resourceCharacteristics = currentResourceData?.characteristics;

      // Resource merge characteristics
      const characteristics = mergeCharacteristics(
        resourceCharacteristics,
        typeCharacteristics,
        characteristicDefinitionsData
      );

      // Current rack characteristic info
      const currentResourceRackUnits = characteristics?.find(
        (characteristic) => characteristic.name === "RACK_UNIT_CAPACITY"
      );

      const currentResourceWidth = characteristics?.find(
        (characteristic) => characteristic.name === "WIDTH"
      );

      const rackUnits = currentResourceRackUnits
        ? parseInt(currentResourceRackUnits.value)
        : 20;

      const rackWidth = currentResourceWidth
        ? Math.round(parseInt(currentResourceWidth.value) / 10)
        : 48;

      // Images info
      let imagesInfo = latestImagesInfo;

      // Collection where we keep track of already loaded images
      let newAssets = loadedAssets;

      // Width calculations
      let screenWidth = graphicalRackWidth;

      // Left margin
      const fitWidthModeEmptySpaceWidth = Math.round(
        (screenWidth * FIT_WIDTH_MODE_EMPTY_SPACE_PERCENTS) / 100
      );

      if (resizeRackCheck) {
        screenWidth = screenWidth - 2 * fitWidthModeEmptySpaceWidth;
      }

      const sideFrameWidth = Math.round(
        (screenWidth * SIDE_FRAMES_PERCENTS) / 100
      );

      const usableAreaWidth = Math.round(
        (screenWidth * USABLE_AREA_PERCENTS) / 100
      );

      const usableAreaWidthCm = Math.round(usableAreaWidth / rackWidth);

      // Height calculations
      let availableHeight = window.innerHeight;

      if (desktopMatches) {
        availableHeight -=
          DESKTOP_NAV_BAR_HEIGHT +
          DESKTOP_HEADER_HEIGHT +
          COLUMN_VIEW_TABS_HEIGHT +
          TOP_BORDER_HEIGHT;
      } else if (tabletMatches) {
        if (appView === "column") {
          availableHeight -=
            TABLET_NAV_BAR_HEIGHT + COLUMN_VIEW_TABS_HEIGHT + TOP_BORDER_HEIGHT;
        } else {
          availableHeight -=
            TABLET_DETAILS_PAGE_NAV_BAR_HEIGHT +
            TABLET_DETAILS_PAGE_TABS_HEIGHT +
            COLUMN_VIEW_TABS_HEIGHT +
            TOP_BORDER_HEIGHT;
        }
      }

      const cellHeight = Math.floor(availableHeight / (rackUnits + 1));

      const rows = rackUnits + 1;

      const rackHeight = resizeRackCheck
        ? (rows + 3) * cellHeight
        : (rows + 3) * PIXELS_PER_RACK_UNIT * 6;

      const rectangleHeight = resizeRackCheck
        ? cellHeight
        : PIXELS_PER_RACK_UNIT * 6;

      // Filling out initial images info
      if (
        (!latestImagesInfo || latestImagesInfo.length <= 0) &&
        Boolean(hardwareAssetResources)
      ) {
        for (const hardwareAsset of hardwareAssetResources) {
          const hardwareAssetResourceCharacteristics =
            hardwareAsset.characteristics;
          const hardwareAssetTypeCharacteristics =
            hardwareAsset.type?.characteristics;

          // Hardware asset merge characteristics
          const hardwareAssetCharacteristics = mergeCharacteristics(
            hardwareAssetResourceCharacteristics,
            hardwareAssetTypeCharacteristics,
            characteristicDefinitionsData
          );

          // Graphical object data for resource
          const graphicalObject = graphicalObjects?.find(
            (go) => go.resourceId === hardwareAsset.id
          );

          const hardwareAssetWidth = hardwareAssetCharacteristics?.find(
            (characteristic) => characteristic?.name === "WIDTH"
          );

          const hardwareAssetHeight = hardwareAssetCharacteristics?.find(
            (characteristic) => characteristic?.name === "HEIGHT"
          );

          // Some assets do not possess these characteristics we do not need them
          const isNoDimensionAsset =
            !Boolean(graphicalObject) ||
            !Boolean(graphicalObject?.yCoordinate) ||
            !Boolean(hardwareAssetWidth) ||
            !Boolean(hardwareAssetHeight);

          if (isNoDimensionAsset) {
            continue;
          }

          // Characteristic values for the given asset
          const currentAssetUnitPosition = graphicalObject
            ? parseInt(graphicalObject.yCoordinate)
            : 1;

          // Scaling the width in the app taking into an account characteristic values from the catalogue
          const currentAssetWidth = hardwareAssetWidth
            ? Math.round(parseInt(hardwareAssetWidth.value) / 10) *
              usableAreaWidthCm
            : 48 * usableAreaWidthCm;

          // Scaling the height to rack units
          const currentAssetHeight = getCalculatedHeight(
            hardwareAssetHeight.value
          );

          // Calculating the empty space on each row, so we can later move the asset to the center depending on how much free space we have
          const currentAssetEmptySpace = Math.round(
            (usableAreaWidth - currentAssetWidth) / 2
          );

          // Parsing the current perspective
          const criteria =
            getView(graphicalObject.angleOfRotation, graphicalViewSide) ===
            "front"
              ? "FRONT"
              : "REAR";

          let imageUri = null;

          // Parsing images information into JSON format
          const imagesData = hardwareAsset.images;

          // Getting the URI of the front/rear image based on the current perspective
          if (graphicalViewAssetDisplay === ASSET_DISPLAY_MODES.IMAGES) {
            imageUri = imagesData?.find(
              (imageData) => imageData.imageCategory === criteria
            )?.uri;
          }

          // Use the type images only when there is no an user-uploaded image
          if (graphicalViewAssetDisplay === ASSET_DISPLAY_MODES.BITMAPS) {
            // Getting type images
            const typeImagesData = hardwareAsset.type?.images;

            // Getting the URI of the front/rear type image based on the current perspective
            imageUri = typeImagesData?.find(
              (imageData) => imageData.imageCategory === criteria
            )?.uri;
          }

          const leftMargin = resizeRackCheck ? fitWidthModeEmptySpaceWidth : 0;

          // Constructing image info object
          const imageInfo = {
            rackUnits: currentAssetHeight,
            startPosition: rackUnits - currentAssetUnitPosition + 1,
            realStartPosition: currentAssetUnitPosition,
            resource: hardwareAsset,
            width: currentAssetWidth,
            emptySpace: currentAssetEmptySpace,
            graphicalView: criteria,
            x: leftMargin + sideFrameWidth + currentAssetEmptySpace,
          };

          // Make request only if there is a imageUri present
          if (imageUri) {
            // Download the image
            const downloadResponse = await fetch(
              BACKEND_URL +
                `/organizations/${
                  user?.organizations?.find((o) => o.default).id
                }/` +
                imageUri.replace(
                  `/organizations/${
                    user?.organizations?.find((o) => o.default).id
                  }/`,
                  ""
                ),
              {
                headers: {
                  authorization: `Bearer ${KeycloakService.getToken()}`,
                },
              }
            );

            // Creating an URL for it
            const blob = await downloadResponse.blob();
            const objectURL = URL.createObjectURL(blob);

            // These will be needed by the `Image` canvas object
            const img = new window.Image();
            img.crossOrigin = "Anonymous";
            img.src = objectURL;

            // Insert all information which is needed to position the image on the correct place
            imagesInfo.push({
              ...imageInfo,
              image: img,
            });

            newAssets.push({ ...imageInfo, image: img });
          } else {
            // Insert all information which is needed to position the image on the correct place
            imagesInfo.push({
              ...imageInfo,
              image: null,
            });
          }
        }
      }

      let newImagesInfo = imagesInfo;
      setLoadedAssets([
        ...loadedAssets,
        ...newAssets.filter(
          (newAsset) =>
            !loadedAssets.some(
              (loaded) => loaded.resource.id === newAsset.resource.id
            )
        ),
      ]);

      // Updating imagesInfo when they are intersections
      for (let i = 0; i < imagesInfo.length; i++) {
        const {
          rackUnits: currentAssetRackUnits,
          realStartPosition: currentAssetRealStartPosition,
          resource: { id: currentAssetresourceid },
          emptySpace: currentAssetEmptySpace,
        } = imagesInfo[i];

        // Here, we will store the range for the current asset, for example [20, 21, 22] being the slots that the current asset occupies
        let currentAssetRange = [];

        for (
          let j = currentAssetRealStartPosition;
          j < currentAssetRealStartPosition + currentAssetRackUnits;
          j++
        ) {
          currentAssetRange.push(j);
        }

        // Getting only the assets that have common rows with the current asset, we do not need to perform any actions for the non-intersected assets
        let intersectedAssets = newImagesInfo
          .filter((info) => {
            const {
              rackUnits: assetRackUnits,
              realStartPosition: assetRealStartPosition,
            } = info;

            let assetRange = [];

            for (
              let j = assetRealStartPosition;
              j < assetRealStartPosition + assetRackUnits;
              j++
            ) {
              assetRange.push(j);
            }

            const intersection = currentAssetRange.filter(
              (current) => assetRange.indexOf(current) !== -1
            );

            return Boolean(intersection) && intersection.length > 0;
          })
          .map((info) => {
            const {
              rackUnits: assetRackUnits,
              realStartPosition: assetRealStartPosition,
            } = info;

            let assetRange = [];

            for (
              let j = assetRealStartPosition;
              j < assetRealStartPosition + assetRackUnits;
              j++
            ) {
              assetRange.push(j);
            }

            const intersection = currentAssetRange.filter(
              (current) => assetRange.indexOf(current) !== -1
            );

            return {
              ...info,
              intersection,
            };
          });

        // Catch any non-intersected assets
        let intersectedAssetsToBeRemoved = [];

        for (let j = 0; j < intersectedAssets.length; j++) {
          const { intersection: currentIntersection } = intersectedAssets[j];

          for (let k = j + 1; k < intersectedAssets.length; k++) {
            const { intersection: nextIntersection } = intersectedAssets[k];

            const intersection = currentIntersection.filter(
              (current) => nextIntersection.indexOf(current) !== -1
            );

            const areIntersected =
              Boolean(intersection) && intersection.length > 0;

            if (!areIntersected) {
              intersectedAssetsToBeRemoved.push(intersectedAssets[k]);
            }
          }
        }

        // Remove any non-intersected assets
        intersectedAssets = intersectedAssets
          .filter(
            (intersected) =>
              !intersectedAssetsToBeRemoved.some(
                (remove) => remove.resource.id === intersected.resource.id
              )
          )
          .map((info) => {
            const {
              rackUnits: assetRackUnits,
              realStartPosition: assetRealStartPosition,
            } = info;

            let assetRange = [];

            for (
              let j = assetRealStartPosition;
              j < assetRealStartPosition + assetRackUnits;
              j++
            ) {
              assetRange.push(j);
            }

            const intersection = currentAssetRange.filter(
              (current) => assetRange.indexOf(current) !== -1
            );

            return {
              ...info,
              intersection,
            };
          });

        // Apply the logic only if we have an intersection between 2 or more assets
        // It is possible to have an array of single object
        // That means the object is intersected by itself which is always true
        if (intersectedAssets.length > 1) {
          // Declaring variable to store the combined width of all intersected assets and getting the leftmost one on the coordinate system (the one that has the lowest `x` coordinate)
          let combinedWidth = 0;
          let leftmostAsset = intersectedAssets.sort((a, b) => a.x - b.x)[0];

          // Filter out the intersected assets
          // We want to add them again when we perform some manipulations over them
          newImagesInfo = newImagesInfo.filter(
            (newImageInfo) =>
              !intersectedAssets.some(
                (intersected) =>
                  intersected.resource.id === newImageInfo.resource.id
              )
          );

          // Calculating the combined width
          for (const intersectedAsset of intersectedAssets) {
            combinedWidth += intersectedAsset.width;

            if (intersectedAsset.x < leftmostAsset.x) {
              leftmostAsset = intersectedAsset;
            }
          }

          // Only if there is enough width, we will place the assets on the same row
          // Otherwise, we will reset the state of the objects which is quite sufficient for the PoC as we do not support validations yet
          if (combinedWidth <= usableAreaWidth) {
            // Calculate the remaining area and the new `x` position of the leftmost asset
            const leftMargin = resizeRackCheck
              ? fitWidthModeEmptySpaceWidth
              : 0;

            const remainingArea = usableAreaWidth - combinedWidth;
            let newX = leftMargin + sideFrameWidth + remainingArea / 2;

            // Updating the `x` of each intersected asset based on the new `x` position of the leftmost asset
            for (const intersectedAsset of intersectedAssets.sort(
              (a, b) => a.x - b.x
            )) {
              const newAsset = { ...intersectedAsset, x: newX };
              newImagesInfo.push(newAsset);
              newX += intersectedAsset.width;
            }
          } else {
            newImagesInfo.push(...intersectedAssets);
          }
        } else {
          const newItem = newImagesInfo.find(
            (info) => info.resource.id === currentAssetresourceid
          );

          let updatedImagesInfo = newImagesInfo.filter(
            (info) => info.resource.id !== currentAssetresourceid
          );

          const leftMargin = resizeRackCheck ? fitWidthModeEmptySpaceWidth : 0;

          newImagesInfo = [
            ...updatedImagesInfo,
            {
              ...newItem,
              x: leftMargin + sideFrameWidth + currentAssetEmptySpace,
            },
          ];
        }
      }

      return {
        rows,
        rectangleHeight,
        fitWidthModeEmptySpaceWidth,
        sideFrameWidth,
        usableAreaWidth,
        newImagesInfo,
        rackUnits,
        rackHeight,
        screenWidth,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      graphicalRackWidth,
      graphicalViewSide,
      hardwareAssetResources,
      characteristicDefinitionsData,
      graphicalObjects,
      graphicalViewAssetDisplay,
      mobileMatches,
      tabletMatches,
      desktopMatches,
      resizeRackCheck,
      window.innerHeight,
      appView,
      graphicalViewFitMode,
    ]
  );

  const handleDrawGraphicalRackView = useCallback(
    async ({
      rows,
      rectangleHeight,
      fitWidthModeEmptySpaceWidth,
      sideFrameWidth,
      usableAreaWidth,
      newImagesInfo,
      rackUnits,
      rackHeight,
      screenWidth,
    }) => {
      // Used to restrict infinite scrolling of stage component
      const allowedMaxScroll = rackHeight - 4 * rectangleHeight;

      // Variables where we will store information about the canvas objects
      let images = [];

      // Preparing the images for being used on the canvas
      for (let i = 0; i < newImagesInfo.length; i++) {
        const hardwareAsset = newImagesInfo[i];

        // Adding image in order to later draw it on the canvas
        images.push(
          <HardwareAssetCanvas
            key={"non-image-" + i}
            hardwareAsset={hardwareAsset}
            locked={locked}
            rectangleHeight={rectangleHeight}
            graphicalObjects={graphicalObjects}
            newImagesInfo={newImagesInfo}
            usableAreaWidth={usableAreaWidth}
            sideFrameWidth={sideFrameWidth}
            rackUnits={rackUnits}
            patchGraphicalObject={patchGraphicalObject}
            organizationId={organizationId}
            loadedAssets={loadedAssets}
            setLoadedAssets={setLoadedAssets}
            setGraphicalView={setGraphicalView}
            handlePrepareGraphicalRackViewInfo={
              handlePrepareGraphicalRackViewInfo
            }
            handleDrawGraphicalRackView={handleDrawGraphicalRackView}
          />
        );
      }
      // Adding red filter if there are overlapping assets
      let nonTextObjects = images.map((i) => {
        if (i.type === "Group") {
          const rect = i.props.children[0];
          return rect;
        }

        return i;
      });

      let filters = [];

      nonTextObjects.push({
        props: {
          x: 0,
          y: 0,
          width: screenWidth,
          height: rectangleHeight,
        },
      });

      nonTextObjects.push({
        props: {
          x: 0,
          y: rows * rectangleHeight,
          width: screenWidth,
          height: rectangleHeight,
        },
      });

      for (let i = 0; i < nonTextObjects.length; i++) {
        const { props: shape1 } = nonTextObjects[i];

        for (let j = i + 1; j < nonTextObjects.length; j++) {
          const { props: shape2 } = nonTextObjects[j];

          if (haveIntersection(shape1, shape2)) {
            const intersection = {
              x: Math.max(shape1.x, shape2.x),
              y: Math.max(shape1.y, shape2.y),
              width:
                Math.min(shape1.x + shape1.width, shape2.x + shape2.width) -
                Math.max(shape1.x, shape2.x),
              height:
                Math.min(shape1.y + shape1.height, shape2.y + shape2.height) -
                Math.max(shape1.y, shape2.y),
            };

            filters.push(
              <Rect
                key={"intersection-" + j}
                x={intersection.x}
                y={intersection.y}
                width={intersection.width}
                height={intersection.height}
                fill="rgba(250, 128, 114, 0.3)"
              />
            );
          }
        }
      }

      // Draw all the objects on the canvas
      return {
        rackHeight,
        screenWidth,
        rows,
        rectangleHeight,
        fitWidthModeEmptySpaceWidth,
        sideFrameWidth,
        usableAreaWidth,
        graphicalViewSide,
        allowedMaxScroll,
        images,
        filters,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [graphicalViewSide, graphicalObjects, locked, graphicalRackWidth]
  );

  const handleUpdateGraphicalRackView = useCallback(async () => {
    const {
      rows,
      rectangleHeight,
      fitWidthModeEmptySpaceWidth,
      sideFrameWidth,
      usableAreaWidth,
      newImagesInfo,
      rackUnits,
      rackHeight,
      screenWidth,
    } = await handlePrepareGraphicalRackViewInfo();

    const result = await handleDrawGraphicalRackView({
      rows,
      rectangleHeight,
      fitWidthModeEmptySpaceWidth,
      sideFrameWidth,
      usableAreaWidth,
      newImagesInfo,
      rackUnits,
      rackHeight,
      screenWidth,
    });

    setGraphicalView(result);
  }, [handlePrepareGraphicalRackViewInfo, handleDrawGraphicalRackView]);

  // Effects
  useEffect(() => {
    if (currentResourceFunction.category === RESOURCE_CATEGORIES.RACK) {
      const renderGraphicalView = async () => {
        await handleUpdateGraphicalRackView();
        dispatch(setIsGraphicalViewLoading(false));
      };

      renderGraphicalView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleUpdateGraphicalRackView, graphicalViewSide, graphicalObjects]);

  useEffect(() => {
    if (!Boolean(graphicalViewHeaderRef?.current)) return;

    const resizedObserver = new ResizeObserver(() => {
      if (graphicalViewHeaderRef.current?.offsetWidth !== graphicalRackWidth) {
        setGraphicalRackWidth(graphicalViewHeaderRef.current?.offsetWidth);
      }
    });

    resizedObserver.observe(graphicalViewHeaderRef.current);

    return () => {
      resizedObserver.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphicalViewHeaderRef?.current]);

  useEffect(() => {
    if (mobileMatches) {
      document.body.style.overflowY = "hidden";
    }

    return () => {
      dispatch(reset());
      if (mobileMatches) {
        document.body.style.overflowY = "scroll";
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorHandling
      isLoading={false}
      isError={isErrorFunctions || isErrorGraphicalObjects || isErrorResources}
    >
      <GraphicalRackViewContainer>
        <TopBorder
          elementRef={graphicalViewHeaderRef}
          graphicalViewSide={graphicalViewSide}
          locked={locked}
          toggleLock={toggleLock}
          handleChangeGraphicalRackViewSide={handleChangeGraphicalRackViewSide}
          handleOpenGraphicalObjects={handleOpenGraphicalObjects}
        />
        {currentResourceFunction.category === RESOURCE_CATEGORIES.RACK &&
          (isGraphicalViewLoading ||
            isLoadingPatchGraphicalObject ||
            isLoadingResources ||
            isLoadingGraphicalObjects ||
            isLoadingFunctionsData) && <LoadingSpinnerOverlay />}

        {Boolean(graphicalView) && graphicalRackWidth > 0 ? (
          <>
            {openGraphicalObjects && (
              <GraphicalRackObjects
                rows={getRows()}
                resourceChildren={hardwareAssetResources}
                graphicalObjects={graphicalObjects}
                characteristicDefinitions={characteristicDefinitionsData}
                setOpen={setOpenGraphicalObjects}
                open={openGraphicalObjects}
              />
            )}

            <GraphicalRackViewStageContainer>
              <GraphicalRackViewStage
                handleDragMoveStage={handleDragMoveStage}
                handleDragEndStage={handleDragEndStage}
                {...graphicalView}
              />
            </GraphicalRackViewStageContainer>
          </>
        ) : (
          ""
        )}
      </GraphicalRackViewContainer>
    </ErrorHandling>
  );
};

export default GraphicalRackView;
