import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { StickySearchTextField } from "../../../components/styles/assets/StickySearch.styles";
import {
  BackInfoContainer,
  BackPageTitle,
  BackTitle,
  HeaderGridContainer,
  HeaderWrapper,
  LeftActionButton,
  PageTitle,
  StyledChevronLeftIcon,
} from "../../../components/styles/header/Header.styles";
import {
  DEFAULT_INDEX,
  selectGlobalFontSize,
  selectIsSearchOpen,
  selectSearchTerm,
  setIndex,
  setIsSearchOpen,
  setSearchTerm,
  setShouldSearch,
} from "../../../store/slices/appSlice";
import { messageError } from "../../../util/notification";
import {
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
  permissions,
  transitionDirections,
} from "../../../util/utils";
import OptionsMenu from "../../options-menu/OptionsMenu";
import { setParentId, setTrail } from "../../../store/slices/columnViewSlice";
import { selectUser } from "../../../store/slices/authSlice";
import {
  useAddFavoriteMutation,
  useDeleteFavoriteMutation,
  useGetAllFunctionsQuery,
} from "../../../store/slices/api/assetManagementSlice";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import { DynamicVisibilityIconButton } from "../../../components/styles/assets/asset-list/ResourceColumnHeading.styles";
import { NavigationActionIcon } from "../../../components/styles/menu/Menu.styles";
import {
  OptionsMenuContainer,
  SearchContainer,
  SecondaryContrastColorSearchIcon,
} from "../../../components/styles/header/assets/AssetListHeader.styles";
import CategoryMenu from "../../../components/assets/asset-form/CategoryMenu";
import { selectTabletTabValue } from "../../../store/slices/assetListSlice";
import { getSvgIcon } from "../../../util/icons";

const AssetListHeader = ({
  userRoles,
  actionAddFunction,
  currentResourceData,
  resourceid,
  favorites,
  queryPathname,
  tagName,
  from,
  tagId,
  queryResourceId,
}) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const tabletMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.TABLET);
  const [searchParams] = useSearchParams();
  const quickAccess = searchParams.get("quick_access");
  const theme = useTheme();

  // States
  const [anchorEl, setAnchorEl] = useState(null);

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const globalFontSize = useSelector(selectGlobalFontSize);
  const searchTerm = useSelector(selectSearchTerm);
  const isSearchOpen = useSelector(selectIsSearchOpen);
  const tabValue = useSelector(selectTabletTabValue);

  // Mutations
  const [addFavorite] = useAddFavoriteMutation();
  const [deleteFavorite] = useDeleteFavoriteMutation();

  // Queries
  const { data: allFunctionsData } = useGetAllFunctionsQuery({
    organizationId,
  });

  // Other variables
  const open = Boolean(anchorEl);
  const currentResourceFunction = allFunctionsData?.find(
    (f) => f.id === currentResourceData?.functionId
  );
  const iconSize = globalFontSize * 1.5;

  // Handlers
  const handleGetBackInfo = () => {
    if (
      Boolean(queryPathname) &&
      (queryPathname.includes("tags-filter") ||
        queryPathname.includes("search"))
    ) {
      const back =
        queryPathname +
        `?direction=${transitionDirections.LEFT_TO_RIGHT}${
          Boolean(tagName) ? `&tagName=${tagName}` : ""
        }${Boolean(from) ? `&from=${from}` : ""}${
          Boolean(tagId) ? `&tagId=${tagId}` : ""
        }${Boolean(queryResourceId) ? `&resourceId=${queryResourceId}` : ""}`;

      const backName = getTranslation("BACK_TO_SEARCH", t, i18n);

      return {
        back,
        backName,
      };
    } else {
      if (Boolean(currentResourceData) && Boolean(resourceid)) {
        const { parent } = currentResourceData;
        const backName =
          parent && parent.id
            ? parent.displayId || parent.name
            : quickAccess
            ? getTranslation("REPORTS", t, i18n)
            : getTranslation("ASSETS", t, i18n);

        const back = `/resources${
          parent && parent.id
            ? `/${parent.id}?direction=${transitionDirections.LEFT_TO_RIGHT}`
            : `?direction=${transitionDirections.LEFT_TO_RIGHT}`
        }`;

        return {
          back,
          backName,
        };
      } else {
        return null;
      }
    }
  };

  const onBackToClickHandler = (back) => {
    if (quickAccess) {
      navigate(
        state?.from.replace(
          "direction=rtl",
          "direction=" + transitionDirections.LEFT_TO_RIGHT
        )
      );
    } else {
      navigate(back);
    }
  };

  const handleSearchClick = () => {
    if (isSearchOpen) {
      if (searchTerm?.length < 3) {
        messageError(getTranslation("SEARCH_TERM_TOO_SHORT", t, i18n));
      } else {
        dispatch(setShouldSearch(true));
        navigate(
          `/search?from=${from}${tagId ? `&tagId=${tagId}` : ""}${
            currentResourceData ? `&resourceId=${currentResourceData.id}` : ""
          }${tagName ? `&tagName=${tagName}` : ""}`
        );

        dispatch(setIsSearchOpen(false));
      }
    } else {
      dispatch(setIsSearchOpen(true));
    }
  };

  const handleClearSearch = () => {
    dispatch(setIsSearchOpen(false));
    dispatch(setSearchTerm(""));
    dispatch(setShouldSearch(false));
    dispatch(setParentId(null));

    if (pathname.includes("search")) {
      navigate(`/resources?direction=${transitionDirections.LEFT_TO_RIGHT}`);
    }
  };

  const handleSearch = (e) => {
    dispatch(setTrail([]));

    if (Boolean(e.target.value)) {
      dispatch(setParentId(null));
    }

    dispatch(setSearchTerm(e.target.value));
    dispatch(setShouldSearch(false));
  };

  const handleAddFavorite = async (e, id) => {
    e.stopPropagation();

    try {
      await addFavorite({
        resourceid: id,
        organizationId: user?.organizations?.find((o) => o.default)?.id,
      }).unwrap();

      dispatch(setIndex(DEFAULT_INDEX));
    } catch (error) {
      console.error("Failed to add favorite", error);
    }
  };

  const handleDeleteFavorite = async (e, id) => {
    e.stopPropagation();

    try {
      await deleteFavorite({
        resourceid: id,
        organizationId: user?.organizations?.find((o) => o.default)?.id,
      }).unwrap();

      dispatch(setIndex(DEFAULT_INDEX));
    } catch (error) {
      console.error("Failed to delete favorite", error);
    }
  };

  const handleOpenMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <HeaderWrapper id="sticky-header">
      <HeaderGridContainer>
        {tabletMatches & Boolean(resourceid) && tabValue === 1 ? (
          <Grid container alignItems="center">
            <Grid item xs={2}>
              {Boolean(handleGetBackInfo()) ? (
                <LeftActionButton
                  id="back-button"
                  onClick={() => onBackToClickHandler(handleGetBackInfo().back)}
                >
                  <StyledChevronLeftIcon
                    id="header-back-button-icon"
                    customfontsize={globalFontSize * 2}
                  />
                  <BackTitle component="span" id="previous_page-title">
                    {handleGetBackInfo().backName}
                  </BackTitle>
                </LeftActionButton>
              ) : (
                <BackPageTitle variant="h4" id="title">
                  {getTranslation("assets", t, i18n)}
                </BackPageTitle>
              )}
            </Grid>
            <Grid item xs={8}>
              <PageTitle variant="h4" id="title">
                {currentResourceData
                  ? currentResourceData.displayId || currentResourceData.name
                  : ""}
              </PageTitle>
            </Grid>
            <Grid display="flex" justifyContent="end" item xs={2}>
              {favorites?.data?.some((f) => f.resource.id === resourceid) ? (
                <DynamicVisibilityIconButton
                  isvisible={Boolean(resourceid)}
                  data-testid="StarIconDetailViewFavored"
                  onClick={(e) => handleDeleteFavorite(e, resourceid)}
                >
                  <NavigationActionIcon
                    active="true"
                    customfontsize={globalFontSize}
                  >
                    star
                  </NavigationActionIcon>
                </DynamicVisibilityIconButton>
              ) : (
                <DynamicVisibilityIconButton
                  isvisible={Boolean(resourceid)}
                  data-testid="StarIconDetailViewNonFavored"
                  onClick={(e) => handleAddFavorite(e, resourceid)}
                >
                  <NavigationActionIcon
                    active="true"
                    className="material-icons material-icons-outlined"
                    customfontsize={globalFontSize}
                  >
                    star_outlined_outlined
                  </NavigationActionIcon>
                </DynamicVisibilityIconButton>
              )}
            </Grid>
          </Grid>
        ) : (
          <>
            <BackInfoContainer>
              {Boolean(handleGetBackInfo()) ? (
                <LeftActionButton
                  id="back-button"
                  onClick={() => onBackToClickHandler(handleGetBackInfo().back)}
                >
                  <StyledChevronLeftIcon
                    id="header-back-button-icon"
                    customfontsize={globalFontSize * 2}
                  />
                  <BackTitle component="span" id="previous_page-title">
                    {handleGetBackInfo().backName}
                  </BackTitle>
                </LeftActionButton>
              ) : (
                <BackPageTitle variant="h4" id="title">
                  {getTranslation("assets", t, i18n)}
                </BackPageTitle>
              )}
            </BackInfoContainer>
            {hasAccess(
              "some",
              [
                permissions.ASSET_MANAGEMENT_HARDWARE_ADD,
                permissions.ASSET_MANAGEMENT_LOCATION_ADD,
                permissions.ASSET_MANAGEMENT_RACK_ADD,
              ],
              getPermissionsFromUserRoles(userRoles)
            ) && (
              <Box>
                {tabletMatches ? (
                  <OptionsMenuContainer>
                    <OptionsMenu />
                    {isSearchOpen ? (
                      <SearchContainer>
                        <StickySearchTextField
                          inputProps={{ "data-testid": "search-field" }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <IconButton
                                  id="search"
                                  onClick={handleSearchClick}
                                >
                                  <NavigationActionIcon
                                    active={true}
                                    customfontsize={globalFontSize}
                                  >
                                    search
                                  </NavigationActionIcon>
                                </IconButton>
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  id="clear"
                                  onClick={handleClearSearch}
                                >
                                  <NavigationActionIcon
                                    active={true}
                                    customfontsize={globalFontSize}
                                  >
                                    clear
                                  </NavigationActionIcon>
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          id="search-field"
                          variant="outlined"
                          placeholder={getTranslation("SEARCH_TERM", t, i18n)}
                          value={searchTerm}
                          onChange={handleSearch}
                        />
                      </SearchContainer>
                    ) : (
                      <IconButton id="search-icon" onClick={handleSearchClick}>
                        <SecondaryContrastColorSearchIcon
                          customfontsize={globalFontSize * 2}
                        />
                      </IconButton>
                    )}
                  </OptionsMenuContainer>
                ) : (
                  <div onClick={handleOpenMenu}>
                    {getSvgIcon(
                      "CREATE_NEW",
                      iconSize,
                      iconSize,
                      theme.palette.secondary.contrastText
                    )}
                  </div>
                )}
                <CategoryMenu
                  open={open}
                  anchorEl={anchorEl}
                  handleClose={handleCloseMenu}
                  parentCategory={currentResourceFunction?.category}
                  categoryActionHandler={actionAddFunction}
                />
              </Box>
            )}
          </>
        )}
      </HeaderGridContainer>
    </HeaderWrapper>
  );
};

export default AssetListHeader;
