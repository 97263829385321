import { Grid, MenuItem, Typography } from "@mui/material";
import { styled } from "@mui/system";
import StarIcon from "@mui/icons-material/Star";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { THEME } from "../../../util/utils";

export const FavoriteRowGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.dark,
  borderBottom: "solid 0.5px " + theme.palette.primary.dark,
  cursor: "pointer",
  marginBottom: 1,
}));

export const FavoriteIcon = styled(StarIcon)({
  color: "#07ABF7",
  marginRight: "30px",
});

export const ForwardIcon = styled(KeyboardArrowRightIcon)({
  color: "white",
});

export const WideText = styled(Typography)(({ theme }) => ({
  marginRight: "auto",
  color: theme.palette.text.primary,
}));

export const SecondaryText = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
}));

export const LightGrayText = styled(Typography)({
  color: "#585858",
});

export const SecondaryWideText = styled(SecondaryText)({
  marginRight: "auto",
});

export const ProgressStatus = styled(Typography)(({ theme, row }) => ({
  padding: "0 20px",
  textAlign: "center",
  minWidth: 80,
  color: theme.palette.text.primary,
  backgroundColor:
    row.status === "Finished"
      ? "rgb(0,137,66)"
      : row.status === "InProgress"
      ? "rgb(47,119,205)"
      : row.status === "Failed"
      ? "rgb(255,0,0)"
      : "rgb(225, 193, 110)",
}));

export const Item = styled(MenuItem)(({ theme }) => ({}));

export const ItemContainer = styled(Grid)(
  ({ theme, longPressed, currentTheme }) => ({
    marginTop: "2px",
    backgroundColor: longPressed
      ? currentTheme === THEME.LIGHT
        ? "#FFF"
        : "#222222"
      : theme.palette.secondary.dark,
    zIndex: longPressed ? 1200 : "auto",
    position: longPressed ? "relative" : "static",
  })
);

export const ItemName = styled(Typography)(({ theme, isSelected }) => ({
  color: theme.palette.primary.main,
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  flexGrow: 1,
  marginLeft: "10px",
  fontWeight: isSelected ? "bold" : "normal",
}));

export const ArrowRightIcon = styled(KeyboardArrowRightIcon)(
  ({ theme, customfontsize }) => ({
    color: theme.palette.primary.main,
    fontSize: `${customfontsize}px`,
  })
);
