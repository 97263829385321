import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
  permissions,
  transitionDirections,
} from "../../../util/utils";
import {
  ActionTitle,
  BackInfoContainer,
  BackTitle,
  HeaderGridContainer,
  HeaderWrapper,
  LeftActionButton,
  StyledChevronLeftIcon,
} from "../../../components/styles/header/Header.styles";
import { Box, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";
import { useGetAllFunctionsQuery } from "../../../store/slices/api/assetManagementSlice";
import { selectUser } from "../../../store/slices/authSlice";
import ErrorHandling from "../../../components/common/ErrorHandling";
import { getSvgIcon } from "../../../util/icons";

const TypeDetailHeader = ({ userRoles, actionEditFunction, typeData }) => {
  // General hooks
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Other variables
  const organizationId = user?.organizations?.find((o) => o.default).id;
  const iconSize = globalFontSize * 1.2;

  // Queries
  const {
    data: allFunctionsData,
    isLoading,
    isError,
  } = useGetAllFunctionsQuery({
    organizationId,
  });

  const typeFunction = allFunctionsData?.find(
    (f) => f.id === typeData.functionId
  );

  // Handlers
  const onBackToClickHandler = () => {
    navigate(
      `/library?direction=${transitionDirections.LEFT_TO_RIGHT}&category=${typeFunction?.category}&functionId=${typeData.functionId}`
    );
  };

  return (
    <ErrorHandling isLoading={isLoading} isError={isError}>
      <HeaderWrapper id="sticky-header">
        <HeaderGridContainer>
          <BackInfoContainer>
            <LeftActionButton id="back-button" onClick={onBackToClickHandler}>
              <StyledChevronLeftIcon
                id="header-back-button-icon"
                customfontsize={globalFontSize * 2}
              />
              <BackTitle component="span" id="previous_page-title">
                {getTranslation("LIBRARY", t, i18n)}
              </BackTitle>
            </LeftActionButton>
          </BackInfoContainer>
          {typeData.organizationId &&
            hasAccess(
              "all",
              [permissions.ASSET_MANAGEMENT_TYPE_EDIT],
              getPermissionsFromUserRoles(userRoles)
            ) && (
              <Box>
                <ActionTitle
                  tabIndex="0"
                  align="right"
                  onClick={actionEditFunction}
                  id="action-title-done"
                  data-testid="action-title-edit"
                >
                  {getSvgIcon(
                    "EDIT",
                    iconSize,
                    iconSize,
                    theme.palette.secondary.contrastText
                  )}

                  {getTranslation("EDIT", t, i18n)}
                </ActionTitle>
              </Box>
            )}
        </HeaderGridContainer>
      </HeaderWrapper>
    </ErrorHandling>
  );
};

export default TypeDetailHeader;
