import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SelectInput from "../../SelectInput";
import { useTranslation } from "react-i18next";
import {
  CATEGORY_SELECT_LIST,
  getTranslation,
  RESOURCE_CATEGORIES,
  showValidationError,
  userLocalStorageKey,
} from "../../../util/utils";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../store/slices/authSlice";
import {
  selectGlobalFontSize,
  selectPageInfo,
  selectTheme,
  setIsActionButtonDisabled,
} from "../../../store/slices/appSlice";
import {
  DEFAULT_RESOURCE_INPUT,
  resetResourceInput,
  selectError,
  selectIsFirstSubmitted,
  selectOriginalInput,
  selectResourceInput,
  setError,
  setIsFirstSubmitted,
  setOriginalInput,
  setResourceInput,
} from "../../../store/slices/resourceInputSlice";
import {
  useCheckNameMutation,
  useDeleteResourceMutation,
  useGetAllFunctionsQuery,
  useGetAllResourcesQuery,
  useGetResourceDetailsQuery,
  useUpdateResourceMutation,
} from "../../../store/slices/api/assetManagementSlice";
import { ValidationText } from "../../styles/inputs/NamesGroup.styles";
import { messageError, messageSuccess } from "../../../util/notification";
import {
  checkResourceChanged,
  transformResourceInputRequest,
  transformResourceResponse,
  validateResourceForm,
} from "../../../util/asset-utils";
import { clearList, setList } from "../../../store/slices/resourceImageSlice";
import {
  clearAttachmentList,
  setAttachmentList,
} from "../../../store/slices/resourceAttachmentSlice";
import { useGetResourceAttachmentsQuery } from "../../../store/slices/api/resourceAttachmentsApiSlice";
import CharacteristicInputGroup from "./CharacteristicInputGroup";
import TypeInput from "./TypeInput";
import ParentTreeInput from "./ParentTreeInput";
import FunctionInput from "./FunctionInput";
import {
  CancelButton,
  CreateButton,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from "../../styles/assets/asset-form/CreateAsset.styles";
import { DialogPaperProps } from "../../styles/general/General.styles";
import DeleteAsset from "./DeleteAsset";
import ConfirmAlert from "../../../store/confirm/ConfirmAlert";
import ErrorHandling from "../../common/ErrorHandling";
import { ParentInputWrapper } from "../../styles/assets/asset-form/AssetForm.styles";
import { SectionTitle } from "../../styles/assets/asset-detail/AssetDetailBasicInfo.styles";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import LocalResourceImagesList from "../asset-attachments/LocalResourceImagesList";
import LocalResourceAttachmentList from "../asset-attachments/LocalResourceAttachmentsList";
import TagsAssetFormSection from "./TagsAssetFormSection";
import {
  resetTags,
  setGlobalTags,
  setInitialTags,
  setTags,
} from "../../../store/slices/tagsSlice";
import {
  useGetResourceTagsQuery,
  useGetTagsQuery,
} from "../../../store/slices/api/tagsSlice";
import { setCurrentResourceId } from "../../../store/slices/longPressSlice";
import { getSvgIcon } from "../../../util/icons";
import { editItem } from "../../../store/slices/assetListSlice";

const EditAsset = ({ open, setOpen, resourceId }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const tabletMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.TABLET);
  const desktopMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.DESKTOP);

  // Selectors
  const user = useSelector(selectUser);
  const resourceInput = useSelector(selectResourceInput);
  const originalInput = useSelector(selectOriginalInput);
  const error = useSelector(selectError);
  const isFirstSubmitted = useSelector(selectIsFirstSubmitted);
  const { isActionButtonDisabled } = useSelector(selectPageInfo);
  const currentTheme = useSelector(selectTheme);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Refs
  const textRefType = useRef(null);
  const textRefBasicData = useRef(null);
  const iconRefType = useRef(null);
  const iconRefBasicData = useRef(null);

  // States
  const [openDiscard, setOpenDiscard] = useState(false);
  const [borderWidthType, setBorderWidthType] = useState("25%");
  const [borderWidthBasicData, setBorderWidthBasicData] = useState("25%");

  // Other variables
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const iconSize = globalFontSize * 1.2;

  // Mutations
  const [updateResource, { isLoading: isLoadingUpdate }] =
    useUpdateResourceMutation();
  const [
    deleteResource,
    { isSuccess: isSuccessDelete, isLoading: isLoadingDelete },
  ] = useDeleteResourceMutation();
  const [checkName] = useCheckNameMutation();

  // Queries
  const {
    data: resourceData,
    isLoading: isLoadingResourceData,
    isError: isErrorResources,
  } = useGetResourceDetailsQuery(
    {
      resourceid: resourceId,
      organizationId,
    },
    { skip: isSuccessDelete || isLoadingDelete }
  );

  const {
    data: allResourcesData,
    isLoading: isLoadingAllResources,
    isError: isErrorAllResources,
  } = useGetAllResourcesQuery({
    organizationId,
  });

  const {
    data: resourceAttachmentsData,
    isLoading: isLoadingAttachments,
    isError: isErrorAttachments,
  } = useGetResourceAttachmentsQuery(
    {
      resourceid: resourceId,
      organizationId,
    },
    { skip: !Boolean(resourceId) }
  );

  const {
    data: allFunctionsData,
    isLoading: isLoadingFunctions,
    isError: isErrorFunctions,
  } = useGetAllFunctionsQuery({
    organizationId,
  });

  const {
    data: tags,
    isLoading: isLoadingTags,
    isError: isErrorTags,
  } = useGetTagsQuery({
    organizationId,
  });

  const {
    data: resourceTags,
    isLoading: isLoadingResourceTags,
    isErrorResourceTags,
  } = useGetResourceTagsQuery({
    resourceid: resourceId,
    organizationId,
  });

  // Other variables
  const leftPadding = 16;
  const resourceImages = resourceData?.images ?? [];

  const {
    id,
    name,
    displayId,
    description,
    functionId,
    category,
    characteristics,
    typeId,
    hasTypeChanged,
  } = resourceInput;

  const isResourceChanged = useMemo(
    () => checkResourceChanged(originalInput, resourceInput, user.region),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resourceInput, originalInput]
  );

  const alert = {
    title: getTranslation("CANCEL_UPDATE", t, i18n),
    content: getTranslation("CANCEL_ALERT_CONTENT", t, i18n),
    confirmTitle: getTranslation("DISCARD_CHANGES", t, i18n),
    closeTitle: getTranslation("CANCEL", t, i18n),
    showConfirm: true,
  };

  const sortedSelectCategoryList = CATEGORY_SELECT_LIST.sort((a, b) => {
    if (a.id > b.id) {
      return 1;
    } else {
      return -1;
    }
  });

  const getFunction = (id) => {
    return allFunctionsData?.find((f) => f.id === Number(id));
  };

  const getCategoryDisabledOptions = () => {
    const parent = allResourcesData?.find(
      (r) => r.id === resourceInput.parentId
    );

    const parentFunction = getFunction(parent?.functionId);
    const isParentHardwareAsset =
      parentFunction?.category === RESOURCE_CATEGORIES.HARDWARE_ASSET;
    const isParentRack = parentFunction?.category === RESOURCE_CATEGORIES.RACK;

    if (isParentHardwareAsset) {
      return [
        { value: RESOURCE_CATEGORIES.RACK },
        { value: RESOURCE_CATEGORIES.LOCATION },
      ];
    }

    if (isParentRack) {
      return [{ value: RESOURCE_CATEGORIES.LOCATION }];
    }

    return [];
  };

  const categoryDisabledOptions = getCategoryDisabledOptions();

  // Handlers
  const handleSubmit = async () => {
    dispatch(setIsFirstSubmitted(true));
    const parent = allResourcesData?.find(
      (r) => r.id === resourceInput.parentId
    );
    const parentFunction = allFunctionsData.find(
      (f) => f.id === parent?.functionId
    );

    const isParentHardwareAsset =
      parentFunction?.category === RESOURCE_CATEGORIES.HARDWARE_ASSET;
    const isParentRack = parentFunction?.category === RESOURCE_CATEGORIES.RACK;
    const hasLocations =
      allResourcesData?.filter((r) => {
        return (
          getFunction(r.functionId)?.category === RESOURCE_CATEGORIES.LOCATION
        );
      })?.length > 0;

    if (
      resourceInput.category === RESOURCE_CATEGORIES.LOCATION &&
      resourceInput.parentId &&
      (isParentHardwareAsset || isParentRack)
    ) {
      messageError(getTranslation("CHANGE_PARENT_VALIDATION", t, i18n));
      return;
    }

    if (
      resourceInput.category === RESOURCE_CATEGORIES.RACK &&
      resourceInput.parentId &&
      isParentHardwareAsset
    ) {
      messageError(
        getTranslation("CHANGE_HARDWARE_PARENT_VALIDATION", t, i18n)
      );
      return;
    }

    if (
      (resourceInput.category === "HARDWARE_ASSET" ||
        resourceInput.category === "RACK") &&
      !hasLocations
    ) {
      messageError(getTranslation("NO_LOCATIONS_VALIDATION", t, i18n));
      return;
    }

    const { error: evaluatedError, firstError } = validateResourceForm(
      resourceInput,
      t
    );

    const isValid =
      Object.keys(evaluatedError).length === 0 && !Boolean(error.name);
    if (!isValid) {
      if (firstError) {
        messageError(firstError);
      }

      if (error.name) {
        messageError(error.name);
      }
      dispatch(setIsActionButtonDisabled(true));
      return;
    }

    try {
      await updateResource({
        resourceInput: transformResourceInputRequest(
          resourceInput,
          user?.region,
          true
        ),
        resourceid: resourceId,
        organizationId: user?.organizations?.find((o) => o.default).id,
        category: resourceInput.category.replace("_", "-").toLowerCase(),
      }).unwrap();

      dispatch(
        editItem({ id: resourceId, newData: { name, displayId, functionId } })
      );

      dispatch(resetResourceInput());
      dispatch(setOriginalInput(DEFAULT_RESOURCE_INPUT));

      messageSuccess(getTranslation("ASSET_UPDATED_SUCCESSFULLY", t, i18n));
      handleClose();
    } catch (error) {
      showValidationError(error, t, i18n);
      console.error("Failed to update a resource", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeName = (e) => {
    const newName = e.target.value.trimStart();

    dispatch(
      setResourceInput({
        ...resourceInput,
        name: newName,
      })
    );
  };

  const handleBlurName = async () => {
    try {
      const checkData = await checkName({
        name: resourceInput.name,
        resourceid: id,
        organizationId: user?.organizations?.find((o) => o.default).id,
      }).unwrap();

      let error = {};
      if (checkData) {
        if (!checkData.valid) {
          error = {
            name: `${getTranslation("UNIQUE_NAME_VALIDATION", t, i18n)}: ${
              checkData.suggestion
            }`,
          };
        }
      }

      dispatch(setError(error));
    } catch (error) {}
  };

  const handleChangeResourceCategory = (value) => {
    const newResourceCategory = value;

    dispatch(
      setResourceInput({
        ...resourceInput,
        category: newResourceCategory,
        functionId: null,
        typeId: null,
      })
    );
  };

  const handleChangeFunctionId = (value) => {
    const newFunctionId = value;
    dispatch(
      setResourceInput({
        ...resourceInput,
        functionId: newFunctionId,
        typeId: null,
        characteristics: [],
      })
    );
  };

  const handleChangeDisplayId = (e) => {
    const newDisplayId = e.target.value.trimStart();
    dispatch(setResourceInput({ ...resourceInput, displayId: newDisplayId }));
  };

  const handleChangeDescription = (e) => {
    const newDescription = e.target.value;
    dispatch(
      setResourceInput({ ...resourceInput, description: newDescription })
    );
  };

  const handleCancelUpdate = () => {
    if (checkResourceChanged(originalInput, resourceInput, user.region)) {
      setOpenDiscard(true);
      return;
    }

    handleClose();
  };

  const handleDialogRef = (node) => {
    if (textRefType.current && iconRefType.current) {
      const textWidth = textRefType.current.offsetWidth;
      const totalWidth = textRefType.current.parentElement.offsetWidth;

      const percentage =
        ((textWidth + leftPadding + iconRefType.current.offsetWidth) /
          totalWidth) *
        100;

      setBorderWidthType(`${percentage}%`);
    }

    if (textRefBasicData.current && iconRefBasicData.current) {
      const textWidth = textRefBasicData.current.offsetWidth;
      const totalWidth = textRefBasicData.current.parentElement.offsetWidth;

      const percentage =
        ((textWidth + leftPadding + iconRefBasicData.current.offsetWidth) /
          totalWidth) *
        100;

      setBorderWidthBasicData(`${percentage}%`);
    }
  };

  // Effects
  useEffect(() => {
    if (!isFirstSubmitted) return;

    const { error: evaluatedError } = validateResourceForm(resourceInput, t);
    const isValid = Object.keys(evaluatedError).length === 0 && !error.name;

    dispatch(setIsActionButtonDisabled(!isValid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceInput, error]);

  useEffect(() => {
    if (open && resourceData) {
      const resource = transformResourceResponse({
        ...resourceData,
        function: getFunction(resourceData.functionId),
      });

      // Load data into resource input
      const finalResourceInput = { ...resourceInput, ...resource };

      dispatch(setResourceInput(finalResourceInput));
      dispatch(setOriginalInput(finalResourceInput));
    }

    return () => {
      localStorage.removeItem(userLocalStorageKey("locations"));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, resourceData]);

  useEffect(() => {
    if (resourceImages) {
      let resultList = [];

      resourceImages?.forEach((resourceImage) => {
        const item = {
          imageCategory: resourceImage.imageCategory,
          selectedFile: resourceImage.uri,
          resourceid: resourceId,
          id: resourceImage.id,
          name: resourceImage.name,
          mimeType: resourceImage.name?.substring(
            resourceImage.name?.lastIndexOf(".")
          ),
          description: resourceImage.description,
          file: null,
          isFromDb: true,
        };

        resultList.push(item);
      });

      dispatch(setList(resultList));
    } else {
      dispatch(clearList());
    }

    return () => dispatch(clearList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceImages?.length]);

  useEffect(() => {
    if (resourceAttachmentsData) {
      let resultList = [];

      resourceAttachmentsData?.forEach((resourceAttachment) => {
        const item = {
          selectedFile: resourceAttachment.uri,
          resourceid: resourceId,
          id: resourceAttachment.id,
          name: resourceAttachment.name,
          description: resourceAttachment.description,
          mimeType: resourceAttachment.name.substring(
            resourceAttachment.name.lastIndexOf(".")
          ),
          file: null,
          isFromDb: true,
        };

        resultList.push(item);
      });

      dispatch(setAttachmentList(resultList));
    } else {
      dispatch(clearAttachmentList());
    }

    return () => dispatch(clearAttachmentList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceAttachmentsData?.length]);

  useEffect(() => {
    if (tags) {
      const mappedTags = tags.map((tag) => {
        return {
          id: tag.id,
          name: tag.displayName,
          color: tag.color,
          lastAssignment: tag.lastAssignment,
          new: false,
          edited: false,
          deleted: false,
          isFromDb: true,
        };
      });

      dispatch(setGlobalTags(mappedTags));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags?.length]);

  useEffect(() => {
    if (resourceTags) {
      const mappedResourceTags = resourceTags.map((tag) => {
        return {
          id: tag.id,
          name: tag.displayName,
          color: tag.color,
          lastAssignment: tag.lastAssignment,
          new: false,
          edited: false,
          deleted: false,
          isFromDb: true,
        };
      });

      dispatch(setInitialTags(mappedResourceTags));
      dispatch(setTags(mappedResourceTags));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceTags?.length]);

  useEffect(() => {
    return () => {
      // Reset resource contents
      dispatch(resetResourceInput());
      dispatch(clearList());
      dispatch(clearAttachmentList());
      dispatch(resetTags());
      dispatch(setCurrentResourceId(null));
      setOpenDiscard(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorHandling
      isLoading={
        isLoadingAllResources ||
        isLoadingAttachments ||
        isLoadingDelete ||
        isLoadingFunctions ||
        isLoadingResourceData ||
        isLoadingUpdate ||
        isLoadingTags ||
        isLoadingResourceTags
      }
      isError={
        isErrorAllResources ||
        isErrorAttachments ||
        isErrorFunctions ||
        isErrorResources ||
        isErrorTags ||
        isErrorResourceTags
      }
    >
      <>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          PaperProps={DialogPaperProps}
          open={open}
          onClose={handleCancelUpdate}
          ref={handleDialogRef}
        >
          {tabletMatches && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Button
                  sx={{ color: theme.palette.secondary.contrastText }}
                  variant="text"
                  onClick={handleCancelUpdate}
                >
                  {getTranslation("CANCEL", t, i18n)}
                </Button>
              </Box>

              <Box>
                <DialogTitle>
                  {getTranslation("EDIT_ASSET", t, i18n)}
                </DialogTitle>
              </Box>

              <Box>
                <Button
                  sx={{ color: theme.palette.secondary.contrastText }}
                  variant="text"
                  disabled={
                    !isResourceChanged ||
                    isActionButtonDisabled ||
                    !resourceInput?.name ||
                    !resourceInput?.functionId ||
                    ((resourceInput.category ===
                      RESOURCE_CATEGORIES.HARDWARE_ASSET ||
                      resourceInput.category === RESOURCE_CATEGORIES.RACK) &&
                      !resourceInput.typeId) ||
                    isLoadingUpdate
                  }
                  onClick={handleSubmit}
                >
                  {getTranslation("DONE", t, i18n)}
                </Button>
              </Box>
            </Box>
          )}

          {desktopMatches && (
            <DialogTitle>{getTranslation("EDIT_ASSET", t, i18n)}</DialogTitle>
          )}

          <DialogContent>
            <ConfirmAlert
              isOpen={openDiscard}
              setIsOpen={setOpenDiscard}
              alert={alert}
              label="discard-changes"
              handleConfirm={handleClose}
            />

            {/* Basic data Accordion */}
            <StyledAccordion currentTheme={currentTheme} defaultExpanded>
              <StyledAccordionSummary
                value={borderWidthBasicData}
                expandIcon={
                  <div
                    style={{ marginTop: "5px", marginRight: "5px" }}
                    ref={iconRefBasicData}
                  >
                    {getSvgIcon(
                      "EXPAND",
                      iconSize,
                      iconSize,
                      theme.palette.secondary.contrastText
                    )}
                  </div>
                }
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <SectionTitle
                  variant="body2"
                  istablet={1}
                  ref={textRefBasicData}
                >
                  {getTranslation("BASIC_DATA", t, i18n)}
                </SectionTitle>
              </StyledAccordionSummary>

              <StyledAccordionDetails>
                <Grid container columnSpacing={2}>
                  <Grid item xs={6}>
                    {/* Resource Name input */}
                    <Box>
                      <TextField
                        inputProps={{
                          "data-testid": "name-input",
                        }}
                        data-testid="name"
                        required={true}
                        label={getTranslation("NAME", t, i18n)}
                        value={name}
                        onChange={handleChangeName}
                        onBlur={handleBlurName}
                        error={(isFirstSubmitted && !name) || error.name}
                      />
                      {error.name && (
                        <ValidationText>{error.name}</ValidationText>
                      )}
                    </Box>

                    {/* Resource DisplayId input */}
                    <Box>
                      <TextField
                        inputProps={{
                          "data-testid": "display-id-input",
                        }}
                        data-testid="display-id"
                        label={getTranslation("DISPLAY_ID", t, i18n)}
                        value={displayId}
                        onChange={handleChangeDisplayId}
                      />
                    </Box>

                    {/* Resource Parent input */}
                    <ParentInputWrapper>
                      <ParentTreeInput />
                    </ParentInputWrapper>
                  </Grid>

                  <Grid item xs={6}>
                    {/* Resource Description input */}
                    <Box>
                      <TextField
                        inputProps={{
                          "data-testid": "notes-input",
                        }}
                        data-testid="notes"
                        label={getTranslation("NOTES", t, i18n)}
                        value={description}
                        onChange={handleChangeDescription}
                        multiline
                        rows={10}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </StyledAccordionDetails>
            </StyledAccordion>

            {/* Type Accordion */}
            <StyledAccordion currentTheme={currentTheme} defaultExpanded>
              <StyledAccordionSummary
                value={borderWidthType}
                expandIcon={
                  <div
                    style={{ marginTop: "5px", marginRight: "5px" }}
                    ref={iconRefType}
                  >
                    {getSvgIcon(
                      "EXPAND",
                      iconSize,
                      iconSize,
                      theme.palette.secondary.contrastText
                    )}
                  </div>
                }
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <SectionTitle variant="body2" istablet={1} ref={textRefType}>
                  {getTranslation("TYPE", t, i18n)}
                </SectionTitle>
              </StyledAccordionSummary>

              <StyledAccordionDetails>
                <Grid container columnSpacing={2}>
                  <Grid item xs={12} sm={6}>
                    {/* Resource Type input */}
                    <Box>
                      <SelectInput
                        fullWidth
                        selectLabelId="resourceCategory-label"
                        label={getTranslation("RESOURCE_CATEGORY", t, i18n)}
                        handleChange={handleChangeResourceCategory}
                        data={sortedSelectCategoryList}
                        value={category}
                        required={true}
                        error={isFirstSubmitted && !resourceInput.category}
                        disabledOptions={categoryDisabledOptions}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    {/* Resource Function input */}
                    <Box>
                      <FunctionInput
                        required={true}
                        isFirstSubmitted={isFirstSubmitted}
                        changeHandler={handleChangeFunctionId}
                        functionId={functionId}
                        category={category}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    {/* Resource Type input  */}
                    <Box>
                      <TypeInput
                        required={
                          Boolean(resourceInput.category) &&
                          resourceInput.category !==
                            RESOURCE_CATEGORIES.LOCATION
                        }
                      />
                    </Box>
                  </Grid>
                </Grid>
              </StyledAccordionDetails>
            </StyledAccordion>

            {/* Resource Characteristics */}
            {functionId && (
              <Box>
                <CharacteristicInputGroup
                  characteristics={characteristics}
                  functionId={functionId}
                  typeId={typeId}
                  hasTypeChanged={hasTypeChanged}
                />
              </Box>
            )}

            {/* Images */}
            <LocalResourceImagesList mode="edit-asset" />

            {/* Attachments */}
            <LocalResourceAttachmentList mode="edit-asset" />

            {/* Tags */}
            <TagsAssetFormSection id={id} />

            {resourceData && (
              <DeleteAsset
                resourceid={resourceId}
                parentId={resourceData.parent?.id}
                category={resourceInput.category?.toLowerCase()}
                organizationId={organizationId}
                deleteResource={deleteResource}
                handleClose={handleClose}
              />
            )}
          </DialogContent>

          {desktopMatches && (
            <DialogActions>
              <CancelButton variant="outlined" onClick={handleCancelUpdate}>
                {getTranslation("CANCEL", t, i18n)}
              </CancelButton>

              <CreateButton
                disabled={
                  !isResourceChanged ||
                  isActionButtonDisabled ||
                  !resourceInput?.name ||
                  !resourceInput?.functionId ||
                  ((resourceInput.category ===
                    RESOURCE_CATEGORIES.HARDWARE_ASSET ||
                    resourceInput.category === RESOURCE_CATEGORIES.RACK) &&
                    !resourceInput.typeId) ||
                  isLoadingUpdate
                }
                onClick={handleSubmit}
              >
                {getTranslation("DONE", t, i18n)}
              </CreateButton>
            </DialogActions>
          )}
        </Dialog>
      </>
    </ErrorHandling>
  );
};

export default EditAsset;
