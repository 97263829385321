import { useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { useGetResourceImageQuery } from "../../../store/slices/api/resourceImagesApiSlice";
import { selectUser } from "../../../store/slices/authSlice";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import CanvasImage from "../../common/CanvasImage";
import ErrorHandling from "../../common/ErrorHandling";
import { getSvgIcon } from "../../../util/icons";

const GridViewCardMedia = ({ resourceImages, functionName, size }) => {
  // General hooks
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);

  // Other variables
  const organizationId = user?.organizations?.find((o) => o.default).id;
  const mobileSize = 100;
  const iconSize = size || 60;

  // Queries
  const {
    data: resourceImageUri,
    isLoading: isLoadingImage,
    isError,
  } = useGetResourceImageQuery(
    {
      imageUri: resourceImages ? resourceImages[0]?.uri : "",
      organizationId,
    },
    {
      skip: !Boolean(resourceImages) || resourceImages.length <= 0,
    }
  );

  return (
    <ErrorHandling isLoading={isLoadingImage} isError={isError}>
      {resourceImageUri && resourceImages?.length > 0 ? (
        <CanvasImage
          id={resourceImages[0]?.name}
          width={mobileMatches ? mobileSize : iconSize}
          height={mobileMatches ? mobileSize : iconSize}
          image={
            resourceImages[0]?.name?.endsWith(".svg")
              ? `${process.env.PUBLIC_URL}/icons/${resourceImages[0]?.name}`
              : resourceImageUri
          }
        />
      ) : (
        getSvgIcon(
          functionName?.toUpperCase(),
          iconSize,
          iconSize,
          theme.palette.secondary.light
        )
      )}
    </ErrorHandling>
  );
};

export default GridViewCardMedia;
