import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectGlobalFontSize, selectTheme } from "../../store/slices/appSlice";
import {
  Button,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
  Tooltip,
  Box,
  useTheme,
} from "@mui/material";
import { SecondaryText } from "../styles/assets/ListInlineView.styles";
import { NavigationActionIcon } from "../styles/menu/Menu.styles";
import { SecondaryContrastButton } from "../styles/general/General.styles";
import { selectUser } from "../../store/slices/authSlice";
import {
  FullScreenWrapper,
  SecondaryTextOverflowWrap,
  StackContainer,
  StickyActionGridContainer,
  TitleSection,
} from "../styles/assets/reports/ReportDetails.styles";
import {
  useDeleteReportDefinitionMutation,
  useGetReportDefinitionAggregationsQuery,
  useGetReportDefinitionByIdQuery,
  useGetReportDefinitionFieldsQuery,
  useGetReportDefinitionFiltersQuery,
  useReportExecutionQuery,
  useReportingFieldsByTypeQuery,
} from "../../store/slices/api/reportsApiSlice";
import ErrorHandling from "../common/ErrorHandling";
import { messageError, messageSuccess } from "../../util/notification";
import {
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
  permissions,
  RESOURCE_CATEGORIES,
  THEME,
} from "../../util/utils";
import { useTranslation } from "react-i18next";
import ReportExecutionResultTable from "./ReportExecutionResultTable";
import { generateSQLWhereClause } from "../../util/reports-utils";
import { useGetResourcesByCategoryQuery } from "../../store/slices/api/assetManagementSlice";
import {
  ASSET_LIST_PER_LOCATION_REPORT_DEFINITION_ID,
  ASSET_LIST_PER_RACK_REPORT_DEFINITION_ID,
} from "../../Constants";
import ConfirmAlert from "../../store/confirm/ConfirmAlert";
import { setReportId } from "../../store/slices/reportSlice";
import SyntaxHighlighter from "react-syntax-highlighter";
import { xcode, a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import {
  ReportExecutionWrapper,
  StyledArrowDropDownIcon,
} from "../styles/reports/Reports.styles";
import { useUserRolePermissionsQuery } from "../../store/slices/api/userManagementSlice";
import { getSvgIcon } from "../../util/icons";
import UpdateReportDialog from "./update-report/UpdateReportDialog";

const ReportDetails = ({
  reportId,
  handleToggleFullscreen,
  handleResetSelected,
}) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);
  const currentTheme = useSelector(selectTheme);

  // States
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  const [executionFurtherFields, setExecutionFurtherFields] = useState([]);
  const [executionGroupFields, setExecutionGroupFields] = useState([]);

  // Other variables
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const iconSize = globalFontSize * 1.2;

  // Mutations
  const [
    deleteReportDefinition,
    {
      isLoading: isLoadingDeleteReportDefinition,
      isSuccess: isSuccessDeleteReportDefinition,
    },
  ] = useDeleteReportDefinitionMutation();

  // Queries
  const { data: reportDefinitionData, isLoading: isLoadingReportDefinition } =
    useGetReportDefinitionByIdQuery(
      { organizationId, reportDefinitionId: reportId },
      {
        skip:
          !reportId ||
          isLoadingDeleteReportDefinition ||
          isSuccessDeleteReportDefinition,
      }
    );

  const { data: locationsData, isLoading: isLoadingLocations } =
    useGetResourcesByCategoryQuery(
      {
        organizationId: user?.organizations?.find((o) => o.default)?.id,
        category: RESOURCE_CATEGORIES.LOCATION,
      },
      {
        skip:
          reportDefinitionData?.id !==
          ASSET_LIST_PER_LOCATION_REPORT_DEFINITION_ID,
      }
    );

  const { data: racksData, isLoading: isLoadingRacks } =
    useGetResourcesByCategoryQuery(
      {
        organizationId: user?.organizations?.find((o) => o.default)?.id,
        category: RESOURCE_CATEGORIES.RACK,
      },
      {
        skip:
          reportDefinitionData?.id !== ASSET_LIST_PER_RACK_REPORT_DEFINITION_ID,
      }
    );

  // States
  const [selectedLocationId, setSelectedLocationId] = useState(
    locationsData?.length > 0 ? locationsData[0]?.id : null
  );

  const [selectedRackId, setSelectedRackId] = useState(
    racksData?.length > 0 ? racksData[0]?.id : null
  );

  // Other variables
  const parentId =
    reportDefinitionData?.id === ASSET_LIST_PER_LOCATION_REPORT_DEFINITION_ID
      ? selectedLocationId
      : reportDefinitionData?.id === ASSET_LIST_PER_RACK_REPORT_DEFINITION_ID
      ? selectedRackId
      : null;

  // Queries
  const { data: userRoles, isLoading: isLoadingUserRoles } =
    useUserRolePermissionsQuery({
      userId: user.id,
      organizationId,
    });

  const { data: reportExecutionData, isLoading: isLoadingReportExecution } =
    useReportExecutionQuery(
      {
        organizationId,
        reportDefinitionId: reportId,
        parentId,
        aggregationResultContent:
          "?aggregation-result-content=GROUP_DETAIL&aggregation-result-content=AGGREGATION",
      },
      { skip: !reportId }
    );

  const { data: reportDefinitionFieldsData, isLoading: isLoadingReportFields } =
    useGetReportDefinitionFieldsQuery(
      { organizationId, reportDefinitionId: reportId },
      { skip: !reportId }
    );

  const {
    data: reportDefinitionFilterData,
    isLoading: isLoadingReportFilters,
  } = useGetReportDefinitionFiltersQuery(
    {
      organizationId,
      reportDefinitionId: reportId,
    },
    {
      skip: !reportId,
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: reportDefinitionAggregationsData,
    isLoading: isLoadingReportAggregations,
  } = useGetReportDefinitionAggregationsQuery(
    {
      organizationId,
      reportDefinitionId: reportId,
    },
    { skip: !reportId }
  );

  const {
    data: reportFieldsByTypeData,
    isLoading: isLoadingReportFieldsByType,
  } = useReportingFieldsByTypeQuery(
    { organizationId, typeId: reportDefinitionData?.reportTypeId },
    {
      skip: !reportDefinitionData?.reportTypeId,
    }
  );

  // Other variables
  const rows = reportExecutionData?.data ?? [];

  const alert = {
    content: getTranslation("DELETE_REPORT_ALERT_CONTENT", t, i18n),
    confirmTitle: getTranslation("DELETE", t, i18n),
    closeTitle: getTranslation("CANCEL", t, i18n),
    showConfirm: true,
  };

  const whereClause = generateSQLWhereClause(
    reportDefinitionFilterData ?? [],
    t,
    i18n,
    user?.region
  );

  // Handlers
  const handleSelectLocation = (e) => {
    setSelectedLocationId(e.target.value);
  };

  const handleSelectRack = (e) => {
    setSelectedRackId(e.target.value);
  };

  const handleDeleteConfirm = () => {
    setOpenConfirm(true);
  };

  const handleDelete = async () => {
    try {
      await deleteReportDefinition({
        reportDefinitionId: reportId,
        organizationId,
      }).unwrap();

      handleResetSelected();
      messageSuccess(getTranslation("successfulDeleteReport", t, i18n));
    } catch (error) {
      messageError(getTranslation("failedDeleteReport", t, i18n));
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpenNewWindow = () => {
    // Store the return of the `open` command in a variable
    const newWindow = window.open(process.env.PUBLIC_URL + "/report-popup");

    // Access it using its variable
    newWindow.reportDefinitionData = reportDefinitionData;
    newWindow.reportDefinitionFieldsData = reportDefinitionFieldsData;
    newWindow.reportDefinitionFilterData = reportDefinitionFilterData;
    newWindow.executionFurtherFields = executionFurtherFields;
    newWindow.executionGroupFields = executionGroupFields;
    newWindow.rows = rows;
    newWindow.whereClause = whereClause;
    newWindow.userRoles = userRoles;
  };

  // Effects
  useEffect(() => {
    if (
      reportDefinitionFieldsData &&
      reportFieldsByTypeData &&
      reportDefinitionAggregationsData
    ) {
      const transformedFields =
        reportFieldsByTypeData
          .filter((field) =>
            reportDefinitionFieldsData.some(
              (reportDefinitionField) =>
                reportDefinitionField.field.id === field.id
            )
          )
          .map((field) => {
            const reportField = reportDefinitionFieldsData.find(
              (reportDefinitionField) =>
                reportDefinitionField.field.id === field.id
            );

            const aggregationTypes = reportDefinitionAggregationsData
              .filter(
                (aggregation) => aggregation.field.id === reportField.field.id
              )
              .map((a) => a.aggregationType);

            return {
              field: field.name,
              displayName: getTranslation(
                reportField.displayName ?? field.name,
                t,
                i18n
              ),
              usedForGrouping: reportField?.usedForGrouping,
              aggregationTypes,
              index: reportField.index,
            };
          }) ?? [];

      const furtherFields = transformedFields
        ?.slice()
        ?.sort((a, b) => a.index - b.index)
        ?.filter((field) => !field.usedForGrouping);

      const groupFields = transformedFields
        ?.slice()
        ?.sort((a, b) => a.index - b.index)
        ?.filter((field) => field.usedForGrouping);

      setExecutionFurtherFields(furtherFields);
      setExecutionGroupFields(groupFields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reportDefinitionFieldsData,
    reportFieldsByTypeData,
    reportDefinitionAggregationsData,
    reportDefinitionData?.organizationId,
  ]);

  useEffect(() => {
    return () => {
      dispatch(setReportId(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorHandling
      isLoading={
        isLoadingReportDefinition ||
        isLoadingReportExecution ||
        isLoadingReportFields ||
        isLoadingReportFilters ||
        isLoadingReportAggregations ||
        isLoadingReportFieldsByType ||
        isLoadingLocations ||
        isLoadingRacks ||
        isLoadingUserRoles
      }
      isError={false}
    >
      {reportId && (
        <>
          {open && (
            <UpdateReportDialog
              reportId={reportId}
              open={open}
              setOpen={setOpen}
            />
          )}

          <StackContainer>
            <FullScreenWrapper direction="row">
              <SecondaryText variant="h6">
                {reportDefinitionData?.name}
              </SecondaryText>

              <Box>
                <IconButton onClick={handleOpenNewWindow}>
                  {getSvgIcon(
                    "OPEN",
                    iconSize,
                    iconSize,
                    theme.palette.secondary.contrastText
                  )}
                </IconButton>

                <IconButton
                  id="fullscreen-reports"
                  onClick={handleToggleFullscreen}
                >
                  <NavigationActionIcon
                    active={true}
                    customfontsize={globalFontSize}
                  >
                    fullscreen
                  </NavigationActionIcon>
                </IconButton>
              </Box>
            </FullScreenWrapper>

            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TitleSection variant="subtitle1">
                  {getTranslation("REPORT_NAME", t, i18n)}
                </TitleSection>
                <SecondaryTextOverflowWrap>
                  {reportDefinitionData?.name}
                </SecondaryTextOverflowWrap>
              </Grid>

              <Grid item xs={6}>
                <TitleSection variant="subtitle1">
                  {getTranslation("REPORT_DESCRIPTION", t, i18n)}
                </TitleSection>

                <Tooltip title={reportDefinitionData?.description}>
                  <SecondaryTextOverflowWrap>
                    {reportDefinitionData?.description}
                  </SecondaryTextOverflowWrap>
                </Tooltip>
              </Grid>

              <Grid item xs={12}>
                <TitleSection variant="subtitle1">
                  {getTranslation("REPORT_QUERY", t, i18n)}
                </TitleSection>
                {Boolean(whereClause) ? (
                  <SyntaxHighlighter
                    language="sql"
                    style={currentTheme === THEME.LIGHT ? xcode : a11yDark}
                    customStyle={{ margin: 0, padding: 0 }}
                  >
                    {whereClause}
                  </SyntaxHighlighter>
                ) : (
                  <SecondaryText>
                    {getTranslation("NO_FILTERS_APPLIED", t, i18n)}
                  </SecondaryText>
                )}
              </Grid>
            </Grid>

            {reportDefinitionData?.id ===
              ASSET_LIST_PER_LOCATION_REPORT_DEFINITION_ID && (
              <Select
                fullWidth
                labelId="select-location-label"
                id="select-location"
                value={selectedLocationId}
                onChange={handleSelectLocation}
                IconComponent={() => (
                  <StyledArrowDropDownIcon globalFontSize={globalFontSize} />
                )}
              >
                {locationsData?.map((a) => (
                  <MenuItem value={a.id}>{a.name}</MenuItem>
                ))}
              </Select>
            )}

            {reportDefinitionData?.id ===
              ASSET_LIST_PER_RACK_REPORT_DEFINITION_ID && (
              <Select
                fullWidth
                labelId="select-rack-label"
                id="select-rack"
                value={selectedRackId}
                onChange={handleSelectRack}
                IconComponent={() => (
                  <StyledArrowDropDownIcon globalFontSize={globalFontSize} />
                )}
              >
                {racksData?.map((a) => (
                  <MenuItem value={a.id}>{a.name}</MenuItem>
                ))}
              </Select>
            )}

            <ReportExecutionWrapper>
              {reportDefinitionData?.organizationId ? (
                reportDefinitionFieldsData?.length > 0 &&
                reportDefinitionFilterData?.length ? (
                  hasAccess(
                    "all",
                    [permissions["REPORTING_USER_DEFINED_EXECUTE"]],
                    getPermissionsFromUserRoles(userRoles)
                  ) && (
                    <ReportExecutionResultTable
                      executionFurtherResultFields={executionFurtherFields}
                      executionGroupFields={executionGroupFields}
                      rows={rows}
                    />
                  )
                ) : (
                  <Typography>
                    {getTranslation("NO_FIELDS", t, i18n)}
                  </Typography>
                )
              ) : (
                hasAccess(
                  "all",
                  [permissions["REPORTING_SYSTEM_REPORT_EXECUTE"]],
                  getPermissionsFromUserRoles(userRoles)
                ) && (
                  <ReportExecutionResultTable
                    executionFurtherResultFields={executionFurtherFields}
                    executionGroupFields={executionGroupFields}
                    rows={rows}
                  />
                )
              )}
            </ReportExecutionWrapper>

            {reportDefinitionData?.organizationId && (
              <StickyActionGridContainer container>
                {hasAccess(
                  "all",
                  [permissions["REPORTING_USER_DEFINED_EDIT"]],
                  getPermissionsFromUserRoles(userRoles)
                ) && (
                  <SecondaryContrastButton
                    id="edit-report"
                    onClick={handleOpen}
                    startIcon={getSvgIcon(
                      "EDIT",
                      iconSize,
                      iconSize,
                      theme.palette.secondary.contrastText
                    )}
                  >
                    {getTranslation("EDIT", t, i18n)}
                  </SecondaryContrastButton>
                )}

                {hasAccess(
                  "all",
                  [permissions["REPORTING_USER_DEFINED_DELETE"]],
                  getPermissionsFromUserRoles(userRoles)
                ) && (
                  <Button
                    id="delete-report"
                    color="warning"
                    variant="text"
                    onClick={handleDeleteConfirm}
                  >
                    {getTranslation("DELETE", t, i18n)}
                  </Button>
                )}
              </StickyActionGridContainer>
            )}
          </StackContainer>

          <ConfirmAlert
            isOpen={openConfirm}
            setIsOpen={setOpenConfirm}
            alert={alert}
            handleConfirm={handleDelete}
            label="delete"
          />
        </>
      )}
    </ErrorHandling>
  );
};

export default ReportDetails;
