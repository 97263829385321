import {
  Box,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { SecondaryContrastButton } from "../../../components/styles/general/General.styles";
import {
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
  permissions,
} from "../../../util/utils";
import { SearchTextField } from "../../../components/styles/header/assets/desktop/AssetListDesktopHeader.styles";
import { NavigationActionIcon } from "../../../components/styles/menu/Menu.styles";
import { useSelector } from "react-redux";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  setIsReportWizardDialogOpen,
  setTypeId,
} from "../../../store/slices/reportsSlice";
import {
  selectSearchQuery,
  setSearchQuery,
} from "../../../store/slices/reportSlice";
import { useUserRolePermissionsQuery } from "../../../store/slices/api/userManagementSlice";
import { selectUser } from "../../../store/slices/authSlice";
import ErrorHandling from "../../../components/common/ErrorHandling";
import { getSvgIcon } from "../../../util/icons";

const DesktopReportsHeader = () => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);
  const searchQuery = useSelector(selectSearchQuery);

  // States
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  // Other variables
  const open = Boolean(anchorEl);
  const iconSize = globalFontSize * 1.2;

  // Queries
  const { data: userRoles, isLoading: isLoadingUserRoles } =
    useUserRolePermissionsQuery({
      userId: user.id,
      organizationId: user?.organizations?.find((o) => o.default)?.id,
    });

  // Handlers
  const handleSearchClick = () => {
    setIsSearchOpen(true);
  };

  const handleClearSearch = () => {
    setIsSearchOpen(false);
    dispatch(setSearchQuery(""));
  };

  const handleSearch = (e) => {
    const newSearchTerm = e.target.value;
    dispatch(setSearchQuery(newSearchTerm));
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenReportsWizard = (typeId) => {
    dispatch(setIsReportWizardDialogOpen(true));
    dispatch(setTypeId(typeId));
    handleClose();
  };

  return (
    <ErrorHandling isLoading={isLoadingUserRoles} isError={false}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ paddingBlock: "8px", borderBottom: "2px solid #e0e0e0" }}
      >
        {hasAccess(
          "all",
          [permissions["REPORTING_USER_DEFINED_CREATE"]],
          getPermissionsFromUserRoles(userRoles)
        ) && (
          <SecondaryContrastButton id="create-report" onClick={handleClick}>
            <NavigationActionIcon customfontsize={globalFontSize}>
              <div style={{ marginRight: "5px" }}>
                {getSvgIcon(
                  "CREATE_NEW",
                  iconSize,
                  iconSize,
                  theme.palette.secondary.contrastText
                )}
              </div>
            </NavigationActionIcon>

            <Typography sx={{ fontWeight: "bolder", marginTop: "5px" }}>
              Create new...
            </Typography>
          </SecondaryContrastButton>
        )}
        <Box sx={{ maxWidth: "320px" }}>
          {isSearchOpen ? (
            <SearchTextField
              sx={{
                ".MuiInputBase-root": {
                  padding: 0,
                },
              }}
              size="small"
              inputProps={{
                "data-testid": "search-field",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={handleSearchClick}>
                      {getSvgIcon(
                        "SEARCH",
                        iconSize,
                        iconSize,
                        theme.palette.secondary.contrastText
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      id="clear-reports-search"
                      onClick={handleClearSearch}
                    >
                      <NavigationActionIcon
                        id="clear"
                        active={true}
                        customfontsize={globalFontSize}
                      >
                        clear
                      </NavigationActionIcon>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              id="search-reports-field"
              variant="outlined"
              placeholder={getTranslation("SEARCH_TERM", t, i18n)}
              value={searchQuery}
              onChange={handleSearch}
            />
          ) : (
            <IconButton id="search-reports" onClick={handleSearchClick}>
              <NavigationActionIcon
                id="search"
                active={true}
                customfontsize={globalFontSize}
              >
                search
              </NavigationActionIcon>
            </IconButton>
          )}
        </Box>
        <Menu
          slotProps={{
            paper: {
              style: {
                borderRadius: "8px",
              },
              elevation: 2,
            },
          }}
          MenuListProps={{
            style: {
              padding: 0,
            },
          }}
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem onClick={() => handleOpenReportsWizard(100)}>
            ... asset management report
          </MenuItem>
        </Menu>
      </Stack>
    </ErrorHandling>
  );
};

export default DesktopReportsHeader;
