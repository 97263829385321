import { Grid, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectAvailableTags } from "../../../store/slices/tagsSlice";
import { getTranslation } from "../../../util/utils";
import { SecondaryText } from "../../styles/assets/ListInlineView.styles";
import { TagsListItemContainer } from "../../styles/assets/tags/Tags.styles";
import TagsList from "./TagsList";
import {
  selectGlobalFontSize,
  selectPageView,
  selectTheme,
} from "../../../store/slices/appSlice";
import { useRef, useState, useEffect } from "react";
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
} from "../../styles/assets/asset-form/CreateAsset.styles";
import { SectionTitle } from "../../styles/assets/asset-detail/AssetDetailBasicInfo.styles";
import EditTagsDialog from "../asset-form/EditTagsDialog";
import { getSvgIcon } from "../../../util/icons";

const AssetDetailTagList = () => {
  // General hooks
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const tags = useSelector(selectAvailableTags);
  const currentTheme = useSelector(selectTheme);
  const pageView = useSelector(selectPageView);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Refs
  const textRef = useRef(null);
  const iconRef = useRef(null);

  // States
  const [borderWidth, setBorderWidth] = useState("25%");
  const [openEditTags, setOpenEditTags] = useState(false);

  // Other variables
  const leftPadding = 16;
  const iconSize = globalFontSize * 1.2;

  // Effects
  useEffect(() => {
    if (textRef.current && iconRef.current) {
      const textWidth = textRef.current.offsetWidth;
      const totalWidth = textRef.current.parentElement.offsetWidth;

      const percentage =
        ((textWidth + leftPadding + iconRef.current.offsetWidth) / totalWidth) *
        100;

      setBorderWidth(`${percentage}%`);
    }
  }, []);

  return (
    <>
      <StyledAccordion currentTheme={currentTheme} defaultExpanded>
        <StyledAccordionSummary
          value={borderWidth}
          expandIcon={
            <div style={{ marginTop: "5px", marginRight: "5px" }} ref={iconRef}>
              {getSvgIcon(
                "EXPAND",
                iconSize,
                iconSize,
                theme.palette.secondary.contrastText
              )}
            </div>
          }
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <SectionTitle variant="body2" istablet={1} ref={textRef}>
            {getTranslation("TAGS", t, i18n)}
          </SectionTitle>
        </StyledAccordionSummary>

        <StyledAccordionDetails>
          <Grid container>
            <Grid item xs={12} sm={pageView === "column" ? 12 : 6}>
              <TagsListItemContainer>
                {tags && tags.length > 0 ? (
                  <TagsList
                    tags={
                      tags?.map((option) => {
                        return {
                          ...option,
                          label: option.name,
                          value: option.id,
                        };
                      }) ?? []
                    }
                  />
                ) : (
                  <SecondaryText id="no-tags">
                    {getTranslation("NO_TAGS_ASSIGNED", t, i18n)}
                  </SecondaryText>
                )}
              </TagsListItemContainer>
            </Grid>

            <Grid item xs={12} sm={6}></Grid>
          </Grid>
        </StyledAccordionDetails>
      </StyledAccordion>

      {openEditTags && (
        <EditTagsDialog open={openEditTags} setOpen={setOpenEditTags} />
      )}
    </>
  );
};

export default AssetDetailTagList;
