import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUser } from "../../store/slices/authSlice";
import { useGetUserInvitationsCheckQuery } from "../../store/slices/api/userManagementSlice";
import { useMediaQuery } from "@mui/material";
import { VIEWPORT_MEDIA_QUERIES } from "../../util/viewport-utils";
import { handleCheckCurrentSubscription } from "../../util/utils";
import { messageWarning } from "../../util/notification";

const SuccessfulLogin = (props) => {
  // General hooks
  let navigate = useNavigate();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);

  // Selectors
  const user = useSelector(selectUser);

  // Other variables
  const invalidUser = !user?.firstName || !user?.lastName;

  // Queries
  const { data, error } = useGetUserInvitationsCheckQuery(
    { email: user?.email, status: "ACCEPTED" },
    { skip: invalidUser || user?.organizations?.length > 0 }
  );

  // Handlers
  const handleResponsiveRedirect = () => {
    if (mobileMatches) {
      navigate("/more");
    } else {
      navigate("/profile");
    }
  };

  const handleRedirection = async () => {
    if (invalidUser) {
      navigate("/onboarding");
      return;
    }

    const organizations = user?.organizations;

    if (
      (!organizations || organizations.length <= 0) &&
      error?.status === 404
    ) {
      navigate("/organization-onboarding");
      return;
    }

    if (!organizations || organizations.length <= 0) {
      handleResponsiveRedirect();
    } else {
      const activeOrganization = organizations?.find((o) => o.default);

      if (activeOrganization?.restricted) {
        messageWarning("Your access to the organization is restricted", {
          toastId: "organization-access-restricted",
        });

        handleResponsiveRedirect();
      } else {
        let hasSubscription = true;

        if (activeOrganization?.owner) {
          hasSubscription = await handleCheckCurrentSubscription(user);
        }

        if (!hasSubscription) {
          messageWarning("No active subscription found", {
            toastId: "no-active-subscription",
          });

          handleResponsiveRedirect();
        } else {
          navigate("/");
        }
      }
    }
  };

  useEffect(() => {
    handleRedirection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error?.status]);

  return <div></div>;
};

export default SuccessfulLogin;
