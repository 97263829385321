export const getSvgIcon = (key, width = 16, height = 16, fill = "#767676") => {
  let icon = null;

  switch (key) {
    case "FORUM":
      icon = (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_924_6794"
            style={{ maskType: "luminance" }}
            maskUnits="userSpaceOnUse"
            x="4"
            y="4"
            width="11"
            height="11"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.99951 4.98384H14.9995V15H4.99951V4.98384Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0_924_6794)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.0065 10.4148C10.8835 10.4148 11.5565 9.31196 11.5565 7.93369C11.5565 6.7247 10.9895 6.00753 10.0065 6.00753C9.02351 6.00753 8.45651 6.7247 8.45651 7.93369C8.45651 9.31196 9.12951 10.4148 10.0065 10.4148ZM9.99951 11.4244C11.4135 11.4244 12.4995 9.81178 12.4995 7.79847C12.4995 6.03157 11.5855 4.98384 9.99951 4.98384C8.41451 4.98384 7.49951 6.03157 7.49951 7.79847C7.49951 9.81178 8.58551 11.4244 9.99951 11.4244ZM14.0065 13.9987H5.99551V13.3215C5.99551 12.7135 6.39951 11.9894 7.00651 11.9894H7.80751C8.43051 12.4862 9.19651 12.7726 9.99251 12.8047C10.7935 12.7756 11.5635 12.4922 12.1925 11.9964H13.0065C13.6145 11.9964 14.0065 12.7206 14.0065 13.3296V13.9987ZM11.9145 10.9827C11.4135 11.5076 10.7245 11.9733 9.99951 11.9843C9.27551 11.9733 8.58651 11.5076 8.08551 10.9827H6.66451C5.74451 10.9877 5.00051 11.9623 4.99951 12.8838L5.00001 15H15L14.9995 12.8838C14.9995 11.9623 14.2555 10.9877 13.3355 10.9827H11.9145Z"
              fill={fill}
            />
          </g>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2 9.99177L1.995 9.3387C1.995 8.7307 2.399 8.00651 3.007 8.00651H3.807C4.431 8.50333 5.196 8.7888 5.993 8.82185C6.332 8.80883 6.663 8.74172 6.983 8.64055V7.71303C6.674 7.88231 6.343 7.99649 6 8.0015C5.276 7.99048 4.501 7.5107 4 6.98684L2.664 6.99986C1.744 7.00386 1 7.97947 1 8.90098V11H5V10L2 9.99177Z"
            fill={fill}
          />
          <mask
            id="mask1_924_6794"
            style={{ maskType: "luminance" }}
            maskUnits="userSpaceOnUse"
            x="3"
            y="1"
            width="6"
            height="7"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.50049 1H8.50049V7.44148H3.50049V1Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask1_924_6794)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.98349 5.87591C6.71849 6.21847 6.38349 6.43182 6.00649 6.43182C5.12949 6.43182 4.45649 5.32801 4.45649 3.94975C4.45649 2.74176 5.02349 2.02458 6.00649 2.02458C6.98949 2.02458 7.55649 2.74176 7.55649 3.94975C7.55649 4.31635 7.50649 4.66092 7.41949 4.97343H8.36249C8.44549 4.60783 8.50049 4.2232 8.50049 3.81452C8.50049 2.04862 7.58549 0.999901 6.00049 0.999901C4.41449 0.999901 3.50049 2.04862 3.50049 3.81452C3.50049 5.82883 4.58549 7.44148 5.99949 7.44148C6.35249 7.44148 6.68349 7.34032 6.98349 7.15902V6.9066V5.87591Z"
              fill={fill}
            />
          </g>
        </svg>
      );

      break;
    case "INSTALL":
      icon = (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_924_6796)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.49 1L7.5 8.51L5.12 6.57L4.5 7.33L8 10.04L11.5 7.33L10.9 6.67L8.49 8.53V1H7.49Z"
              fill={fill}
            />
            <path d="M14 12V14H2V12H14ZM15 11H1V15H15V11Z" fill={fill} />
          </g>
          <defs>
            <clipPath id="clip0_924_6796">
              <rect
                width="14"
                height="14"
                fill="white"
                transform="translate(1 1)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "ABOUT":
      icon = (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_924_6825)">
            <path
              d="M8 2C11.31 2 14 4.69 14 8C14 11.31 11.31 14 8 14C4.69 14 2 11.31 2 8C2 4.69 4.69 2 8 2ZM8 1C4.13 1 1 4.13 1 8C1 11.87 4.13 15 8 15C11.87 15 15 11.87 15 8C15 4.13 11.87 1 8 1Z"
              fill={fill}
            />
            <path d="M8.5 7H7.5V12H8.5V7Z" fill={fill} />
            <path d="M8.5 5H7.5V6H8.5V5Z" fill="white" />
            <path d="M8.5 5H7.5V6H8.5V5Z" fill={fill} />
          </g>
          <defs>
            <clipPath id="clip0_924_6825">
              <rect
                width="14"
                height="14"
                fill="white"
                transform="translate(1 1)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "ORGANIZATION":
      icon = (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_924_6798)">
            <path d="M4 12V14H2V12H4ZM5 11H1V15H5V11Z" fill={fill} />
            <path d="M9 12V14H7V12H9ZM10 11H6V15H10V11Z" fill={fill} />
            <path d="M14 12V14H12V12H14ZM15 11H11V15H15V11Z" fill={fill} />
            <path
              d="M8.5 6H7.5V8H3H2V9V10H3V9H7.5V10H8.5V9H13V10H14V9V8H13H8.5V6Z"
              fill={fill}
            />
            <path d="M10 2V4H6V2H10ZM11 1H5V5H11V1Z" fill={fill} />
          </g>
          <defs>
            <clipPath id="clip0_924_6798">
              <rect
                width="14"
                height="14"
                fill="white"
                transform="translate(1 1)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "PREFERENCES":
      icon = (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_924_6808)">
            <path d="M10 3H0V4H10V3Z" fill="white" />
            <path d="M10 3H0V4H10V3Z" fill={fill} />
            <path d="M11.5 2.5V3.5H15.5H11.5V4.5V2.5Z" fill="white" />
            <path d="M12 2H11V5H12V4H16V3H12V2Z" fill={fill} />
            <path d="M8 9H0V10H8V9Z" fill="white" />
            <path d="M8 9H0V10H8V9Z" fill={fill} />
            <path d="M9.5 8.5V9.5H15.5H9.5V10.5V8.5Z" fill="white" />
            <path d="M10 8H9V11H10V10H16V9H10V8Z" fill={fill} />
            <path d="M16 12H8V13H16V12Z" fill="white" />
            <path d="M16 12H8V13H16V12Z" fill={fill} />
            <path d="M7 11H6V12H0V13H6V14H7V11Z" fill={fill} />
            <path d="M16 6H4V7H16V6Z" fill="white" />
            <path d="M16 6H4V7H16V6Z" fill={fill} />
            <path d="M3 5H2V6H0V7H2V8H3V5Z" fill={fill} />
          </g>
          <defs>
            <clipPath id="clip0_924_6808">
              <rect
                width={width}
                height="12"
                fill="white"
                transform="translate(0 2)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "LOG_OUT":
      icon = (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1 1V15H8V14.001H2V2.001H8V1H1Z"
            fill={fill}
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.1646 5.1211L13.3086 7.5011H4.99957V8.5011H13.3286L11.2686 10.8951L11.9976 11.5001L15.0006 8.0011L11.9976 4.5001L11.1646 5.1211Z"
            fill={fill}
          />
        </svg>
      );

      break;
    case "PROFILE":
      icon = (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14 14H2V12.86C2 12.01 2.69 10.998 3.54 10.997H4.93C5.803 11.692 6.875 12.092 7.99 12.137C9.11 12.096 10.189 11.7 11.07 11.007H12.46C13.31 11.008 14 12.02 14 12.87V14ZM10.68 10.013C9.979 10.746 9.014 11.167 8 11.183C6.986 11.167 6.021 10.746 5.32 10.013H3.33C2.042 10.019 1 11.382 1 12.67V15H15V12.67C15 11.382 13.958 10.019 12.67 10.013H10.68ZM8 2.003C9.58 2.003 10.5 3.112 10.5 5.059C10.5 7.267 9.418 9.001 7.998 9.001C6.578 9.001 5.5 7.267 5.5 5.059C5.5 3.112 6.42 2.003 8 2.003ZM8 10.002C9.98 10.002 11.5 7.749 11.5 4.934C11.5 2.466 10.22 1 8 1C5.78 1 4.5 2.466 4.5 4.934C4.5 7.749 6.02 10.002 8 10.002Z"
            fill={fill}
          />
        </svg>
      );

      break;
    case "ASSETS":
      icon = (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 2.12L14 5.12V10.88L8 13.88L2 10.88V5.12L8 2.12ZM8 1L1 4.5V11.5L8 15L15 11.5V4.5L8 1Z"
            fill={fill}
          />
          <path
            d="M1.75 4.5L8.09 8L14.25 4.5"
            stroke={fill}
            stroke-miterlimit="10"
          />
          <path d="M8 14.55V8" stroke={fill} stroke-miterlimit="10" />
        </svg>
      );

      break;
    case "REPORTS":
      icon = (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_924_6817)">
            <path
              d="M10.54 1L13 3.46V15H3V1H10.54ZM10.95 0H2V16H14V3.04L10.95 0Z"
              fill={fill}
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.03 6C10.03 6 8.03 6 8.03 8C8.03 8 8.03 10 10.03 10C10.03 10 12.03 10 12.03 8H10.03V6Z"
              fill={fill}
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.53 5.5V7.5H12.53C12.53 7.5 12.53 5.5 10.53 5.5Z"
              fill={fill}
            />
            <path d="M6.03 9H4.03V10H6.03V9Z" fill={fill} />
            <path d="M8.03 11H4.03V12H8.03V11Z" fill={fill} />
            <path d="M10.03 13H4.03V14H10.03V13Z" fill={fill} />
            <path
              d="M4.03 5.67L6.07 3.64L7.48 5.03L9.71 2.85"
              stroke={fill}
              stroke-miterlimit="10"
            />
          </g>
          <defs>
            <clipPath id="clip0_924_6817">
              <rect
                width={width}
                height={height}
                fill="white"
                transform="translate(2)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "FAVORITES":
      icon = (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8 1L6.269 6.235L1 6.348L5.2 9.696L3.674 15L8 11.835L12.326 15L10.8 9.696L15 6.348L9.731 6.235L8 1ZM6.16 9.972L6.346 9.33L5.823 8.914L3.784 7.288L6.291 7.235L6.997 7.22L7.219 6.548L8 4.186L8.781 6.548L9.003 7.22L9.709 7.235L12.216 7.288L10.176 8.914L9.654 9.33L9.839 9.972L10.556 12.467L8.59 11.028L8 10.596L7.41 11.028L5.443 12.467L6.16 9.972Z"
            fill={fill}
          />
        </svg>
      );

      break;
    case "MODEL_LIBRARY":
      icon = (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_924_6797)">
            <path d="M4.04 3V14H2V3H4.04ZM5.04 2H1V15H5.04V2Z" fill={fill} />
            <path
              d="M7.00003 2V14H5.03003V2H7.00003ZM8.00003 1H4.03003V15H8.00003V1Z"
              fill={fill}
            />
            <path
              d="M10.23 2.25L13.74 13.18L11.95 13.75L8.43999 2.82L10.23 2.25ZM10.88 1L7.17999 2.17L11.3 15L15 13.83L10.88 1Z"
              fill={fill}
            />
            <path d="M4.03001 12H2.01001V13H4.03001V12Z" fill={fill} />
            <path d="M7.04998 12H5.03998V13H7.04998V12Z" fill={fill} />
            <path d="M4.03001 4H2.01001V5H4.03001V4Z" fill={fill} />
            <path d="M7.04998 3H5.03998V4H7.04998V3Z" fill={fill} />
            <path
              d="M13.89 10.57L11.01 11.48L11.31 12.43L14.2 11.52L13.89 10.57Z"
              fill={fill}
            />
            <path
              d="M10.87 3.57L7.97998 4.48L8.28998 5.43L11.17 4.52L10.87 3.57Z"
              fill={fill}
            />
          </g>
          <defs>
            <clipPath id="clip0_924_6797">
              <rect
                width="14"
                height="14"
                fill="white"
                transform="translate(1 1)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "LAST_USED":
      icon = (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_924_6799)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2 8V8.46C2.26 11.76 5.15 14.23 8.45 13.98C11.76 13.73 14.23 10.84 13.98 7.54C13.74 4.42 11.13 2 7.99 2C6.39 2 4.85 2.64 3.72 3.78C3.63 3.87 3.56 3.94 3.49 4H6V5H2V1H2.98V3.1C2.98 3.1 2.98 3.1 2.99 3.09C2.99 3.09 3 3.08 3.01 3.07C5.73 0.320002 10.17 0.300002 12.92 3.02C15.67 5.74 15.69 10.17 12.97 12.92C11.65 14.25 9.86 14.99 7.99 14.99C4.33 14.99 1.3 12.18 1 8.54V8H2Z"
              fill={fill}
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.19 10.68L7.99999 9.42V5H6.99999V9.97L6.98999 9.99L6.99999 10H7.00999L9.68999 11.55L10.19 10.68Z"
              fill={fill}
            />
          </g>
          <defs>
            <clipPath id="clip0_924_6799">
              <rect
                width="14"
                height="14"
                fill="white"
                transform="translate(1 1)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "STAR":
      icon = (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8 1L6.269 6.235L1 6.348L5.2 9.696L3.674 15L8 11.835L12.326 15L10.8 9.696L15 6.348L9.731 6.235L8 1ZM6.16 9.972L6.346 9.33L5.823 8.914L3.784 7.288L6.291 7.235L6.997 7.22L7.219 6.548L8 4.186L8.781 6.548L9.003 7.22L9.709 7.235L12.216 7.288L10.176 8.914L9.654 9.33L9.839 9.972L10.556 12.467L8.59 11.028L8 10.596L7.41 11.028L5.443 12.467L6.16 9.972Z"
            fill={fill}
          />
        </svg>
      );

      break;
    case "STAR_FILLED":
      icon = (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8 1L6.269 6.235L1 6.348L5.2 9.696L3.674 15L8 11.835L12.326 15L10.8 9.696L15 6.348L9.731 6.235L8 1Z"
            fill={fill}
          />
        </svg>
      );

      break;
    case "EDIT":
      icon = (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_924_6791)">
            <path
              d="M10.89 2.41L13.57 5.01L4.59 14H2V11.42L10.89 2.41ZM10.88 1L1 11.01V15H5L15 5L10.88 1Z"
              fill={fill}
            />
            <path d="M11.5 4.5L5 11" stroke={fill} stroke-miterlimit="10" />
            <path d="M2 11L5 14" stroke={fill} stroke-miterlimit="10" />
          </g>
          <defs>
            <clipPath id="clip0_924_6791">
              <rect
                width="14"
                height="14"
                fill="white"
                transform="translate(1 1)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "CREATE_NEW":
      icon = (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_924_6807)">
            <path
              d="M8.44 1H7.56V7.56H1V8.44H7.56V15H8.44V8.44H15V7.56H8.44V1Z"
              fill={fill}
            />
          </g>
          <defs>
            <clipPath id="clip0_924_6807">
              <rect
                width="14"
                height="14"
                fill="white"
                transform="translate(1 1)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "LIST_VIEW":
      icon = (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_924_6787)">
            <path d="M15 1H6V2H15V1Z" fill="white" />
            <path d="M15 1H6V2H15V1Z" fill={fill} />
            <path d="M15 3H6V4H15V3Z" fill="white" />
            <path d="M15 3H6V4H15V3Z" fill={fill} />
            <path d="M15 8H6V9H15V8Z" fill="white" />
            <path d="M15 8H6V9H15V8Z" fill={fill} />
            <path d="M15 6H6V7H15V6Z" fill="white" />
            <path d="M15 6H6V7H15V6Z" fill={fill} />
            <path d="M15 11H6V12H15V11Z" fill="white" />
            <path d="M15 11H6V12H15V11Z" fill={fill} />
            <path d="M15 13H6V14H15V13Z" fill="white" />
            <path d="M15 13H6V14H15V13Z" fill={fill} />
            <path d="M4 2V4H2V2H4ZM5 1H1V5H5V1Z" fill={fill} />
            <path d="M4 7V9H2V7H4ZM5 6H1V10H5V6Z" fill={fill} />
            <path d="M4 12V14H2V12H4ZM5 11H1V15H5V11Z" fill={fill} />
          </g>
          <defs>
            <clipPath id="clip0_924_6787">
              <rect
                width="14"
                height="14"
                fill="white"
                transform="translate(1 1)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "TILE_VIEW":
      icon = (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1 7H7V1H1V7ZM2 6H6.001V2H2V6Z"
            fill={fill}
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9 7H15V1H9V7ZM10 6H14V2H10V6Z"
            fill={fill}
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1 15H7V9H1V15ZM2 14.001H6.001V10H2V14.001Z"
            fill={fill}
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9 15H15V9H9V15ZM10 14.001H14V10H10V14.001Z"
            fill={fill}
          />
        </svg>
      );

      break;
    case "COLUMN_VIEW":
      icon = (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16 2L0 2L0.0010004 14L16 14L16 2ZM15 2.995L15 13L11 13L11 3L15 2.995ZM10.001 2.995L10 13H6L6 3L10.001 2.995ZM5 2.995L5 13L1 13L1 3L5 2.995Z"
            fill={fill}
          />
        </svg>
      );

      break;
    case "SORTING":
      icon = (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_924_6795)">
            <path d="M4 5.01H1V6.01H4V5.01Z" fill={fill} />
            <path d="M6 8.01H1V9.01H6V8.01Z" fill={fill} />
            <path d="M8 11.01H1V12.01H8V11.01Z" fill={fill} />
            <path d="M10 14.01H1V15.01H10V14.01Z" fill={fill} />
            <path d="M8.96997 2.01H7.96997V8.01H8.96997V2.01Z" fill={fill} />
            <path
              d="M10.34 3.78L10.97 3L8.48 1L7.85 1.78L10.34 3.77M9.1 1.78L8.48 1L6 2.99L6.63 3.77L9.11 1.78"
              fill={fill}
            />
            <path d="M12 11.98H13V6.01H12V11.98Z" fill={fill} />
            <path
              d="M10.63 10.21L10 10.99L12.49 12.98L13.12 12.2L10.63 10.21ZM11.86 12.21L12.49 12.99L14.97 11L14.34 10.22L11.86 12.21Z"
              fill={fill}
            />
          </g>
          <defs>
            <clipPath id="clip0_924_6795">
              <rect
                width="13.97"
                height="14.01"
                fill="white"
                transform="translate(1 1)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "SEARCH":
      icon = (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.7468 10.0398C10.7408 10.0338 10.7328 10.0318 10.7268 10.0268C12.6118 7.76778 12.3858 4.42478 10.2138 2.43978C8.04178 0.455782 4.69178 0.530782 2.61178 2.61178C0.530782 4.69178 0.455782 8.04178 2.43978 10.2138C4.42478 12.3858 7.76778 12.6118 10.0268 10.7268C10.0328 10.7328 10.0338 10.7408 10.0398 10.7468L14.2888 14.9998L14.9998 14.2908L10.7468 10.0398ZM6.50578 11.0048C4.02078 11.0048 2.00578 8.98978 2.00578 6.50478C2.00578 4.02078 4.02078 2.00578 6.50578 2.00578C8.98978 2.00578 11.0048 4.02078 11.0048 6.50478C11.0018 8.98878 8.98878 11.0018 6.50578 11.0048Z"
            fill={fill}
          />
          <mask
            id="mask0_924_6790"
            style={{ maskType: "luminance" }}
            maskUnits="userSpaceOnUse"
            x="1"
            y="1"
            width="14"
            height="14"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.61217 2.61208C0.531168 4.69208 0.455168 8.04108 2.44017 10.2131C4.42417 12.3861 7.76717 12.6121 10.0272 10.7271C10.0322 10.7331 10.0342 10.7401 10.0402 10.7471L14.2882 15.0001L14.9992 14.2911L10.7472 10.0391C10.7412 10.0341 10.7332 10.0321 10.7272 10.0271C12.6122 7.76808 12.3862 4.42508 10.2132 2.44008C9.16017 1.47808 7.83117 1.00008 6.50317 1.00008C5.09217 1.00008 3.68317 1.54008 2.61217 2.61208ZM2.00617 6.50508C2.00617 4.02008 4.02017 2.00608 6.50517 2.00608C8.99017 2.00608 11.0042 4.02008 11.0042 6.50508C11.0022 8.98908 8.98917 11.0021 6.50517 11.0041C4.02017 11.0041 2.00617 8.99008 2.00617 6.50508Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0_924_6790)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.000183105 15.8571H15.8572V8.2016e-05H0.000183105V15.8571Z"
              fill={fill}
            />
          </g>
        </svg>
      );

      break;
    case "EXPAND":
      icon = (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3 5.839L7.999 11L13 5.839L12.188 5L7.999 9.324L3.812 5L3 5.839Z"
            fill={fill}
          />
        </svg>
      );

      break;
    case "IMPORT":
      icon = (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1 1V2.001V14.001V15H2H7V14.001H2V2.001H7.586L11 5.414V9H12V5.001L8 1H1Z"
            fill={fill}
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.8299 14.3823L9.68988 12.9943H14.9999V12.0003H9.66888L10.7269 10.6253L9.94988 10.0003L7.99988 12.4713L10.0469 15.0003L10.8299 14.3823Z"
            fill={fill}
          />
        </svg>
      );

      break;
    case "OPEN":
      icon = (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1 1V2.001V14.001V15H2H14H15V9H14V14.001H2V2L6 2.001V1H1Z"
            fill={fill}
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.9999 1V2.001H13.2929L8.00189 7.291L8.70889 7.998L13.9999 2.707V5.001H15.0009V1H10.9999Z"
            fill={fill}
          />
        </svg>
      );

      break;
    case "COLLAPSE":
      icon = (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3 10.161L7.999 5L13 10.161L12.188 11L7.999 6.676L3.812 11L3 10.161Z"
            fill={fill}
          />
        </svg>
      );

      break;
    case "BUILDING":
      icon = (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_924_6818)">
            <path
              d="M12 2V14H10V10H6V14H4V2H12ZM13 1H3V15H7V11H9V15H13V1Z"
              fill={fill}
            />
            <path d="M2 3H1V15H2V3Z" fill={fill} />
            <path d="M15 3H14V15H15V3Z" fill={fill} />
            <path d="M7 3H5V5H7V3Z" fill={fill} />
            <path d="M11 3H9V5H11V3Z" fill={fill} />
            <path d="M7 6H5V8H7V6Z" fill={fill} />
            <path d="M11 6H9V8H11V6Z" fill={fill} />
          </g>
          <defs>
            <clipPath id="clip0_924_6818">
              <rect
                width="14"
                height="14"
                fill="white"
                transform="translate(1 1)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "CABINET":
      icon = (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_924_6826)">
            <path
              d="M9.52 3.35L13.62 0.85L13.1 0L9 2.5L9.52 3.35ZM13.1 1H14V0H13.1V1ZM13 0V16H14V0H13ZM14 15H13.1V16H14V15ZM13.62 15.15L9.52 12.65L9 13.5L13.1 16L13.62 15.15Z"
              fill={fill}
            />
            <path d="M9 3V13H3V3H9ZM10 2H2V14H10V2Z" fill={fill} />
            <path d="M9 6H3V7H9V6Z" fill={fill} />
            <path d="M9 10H3V11H9V10Z" fill={fill} />
            <path
              d="M4.5 5C4.77614 5 5 4.77614 5 4.5C5 4.22386 4.77614 4 4.5 4C4.22386 4 4 4.22386 4 4.5C4 4.77614 4.22386 5 4.5 5Z"
              fill={fill}
            />
            <path
              d="M4.5 9C4.77614 9 5 8.77614 5 8.5C5 8.22386 4.77614 8 4.5 8C4.22386 8 4 8.22386 4 8.5C4 8.77614 4.22386 9 4.5 9Z"
              fill={fill}
            />
            <path d="M8 4H6V5H8V4Z" fill={fill} />
            <path d="M8 8H6V9H8V8Z" fill={fill} />
          </g>
          <defs>
            <clipPath id="clip0_924_6826">
              <rect
                width={width}
                height={height}
                fill="white"
                transform="translate(2)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "CAMERA":
      icon = (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_924_6829)">
            <path
              d="M10.46 3L12.16 5.55L12.46 6H15V13H1V6H3.54L3.84 5.55L5.54 3H10.47M11 2H5L3 5H0V14H16V5H13L11 2Z"
              fill={fill}
            />
            <path
              d="M8 7C9.1 7 10 7.9 10 9C10 10.1 9.1 11 8 11C6.9 11 6 10.1 6 9C6 7.9 6.9 7 8 7ZM8 6C6.34 6 5 7.34 5 9C5 10.66 6.34 12 8 12C9.66 12 11 10.66 11 9C11 7.34 9.66 6 8 6Z"
              fill={fill}
            />
          </g>
          <defs>
            <clipPath id="clip0_924_6829">
              <rect
                width={width}
                height="12"
                fill="white"
                transform="translate(0 2)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "CITY":
      icon = (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_924_6811)">
            <path d="M16 13H0V14H16V13Z" fill="white" />
            <path d="M16 13H0V14H16V13Z" fill={fill} />
            <path d="M10 6V13H6V6H10ZM11 5H5V14H11V5Z" fill={fill} />
            <path
              d="M2.5 9C2.78 9 3 9.22 3 9.5C3 9.74 2.83 9.91 2.67 9.97L2.5 10.03L2.33 9.97C2.16 9.91 2 9.74 2 9.5C2 9.22 2.22 9 2.5 9ZM2.5 8C1.67 8 1 8.67 1 9.5C1 10.15 1.42 10.7 2 10.91V13H3V10.91C3.58 10.7 4 10.15 4 9.5C4 8.67 3.33 8 2.5 8Z"
              fill={fill}
            />
            <path d="M14 3V13H11V3H14ZM15 2H10V14H15V2Z" fill={fill} />
            <path d="M8.5 11.5H7.5V12.5H8.5V11.5Z" fill="white" />
            <path d="M9 11H7V13H9V11Z" fill={fill} />
            <path d="M7.5 7H6.5V8H7.5V7Z" fill="white" />
            <path d="M7.5 7H6.5V8H7.5V7Z" fill={fill} />
            <path d="M9.5 7H8.5V8H9.5V7Z" fill="white" />
            <path d="M9.5 7H8.5V8H9.5V7Z" fill={fill} />
            <path d="M7.5 9H6.5V10H7.5V9Z" fill="white" />
            <path d="M7.5 9H6.5V10H7.5V9Z" fill={fill} />
            <path d="M9.5 9H8.5V10H9.5V9Z" fill="white" />
            <path d="M9.5 9H8.5V10H9.5V9Z" fill={fill} />
            <path d="M13 4H12V5H13V4Z" fill="white" />
            <path d="M13 4H12V5H13V4Z" fill={fill} />
            <path d="M13 6H12V7H13V6Z" fill="white" />
            <path d="M13 6H12V7H13V6Z" fill={fill} />
            <path d="M13 8H12V9H13V8Z" fill="white" />
            <path d="M13 8H12V9H13V8Z" fill={fill} />
            <path d="M13 10H12V11H13V10Z" fill="white" />
            <path d="M13 10H12V11H13V10Z" fill={fill} />
          </g>
          <defs>
            <clipPath id="clip0_924_6811">
              <rect
                width={width}
                height="12"
                fill="white"
                transform="translate(0 2)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "CLIENT":
      icon = (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_924_6830)">
            <path d="M6 2V14H2V2H6ZM7 1H1V15H7V1Z" fill={fill} />
            <path d="M6 6H2V7H6V6Z" fill={fill} />
            <path d="M6 8H2V9H6V8Z" fill={fill} />
            <path d="M5 10H3V11H5V10Z" fill={fill} />
            <path d="M5 12H3V13H5V12Z" fill={fill} />
            <path
              d="M4 5C4.55228 5 5 4.55228 5 4C5 3.44772 4.55228 3 4 3C3.44772 3 3 3.44772 3 4C3 4.55228 3.44772 5 4 5Z"
              fill={fill}
            />
            <path d="M14 13H7V14H14V13Z" fill={fill} />
            <path
              d="M7 10V11H15V10H7ZM14 11H15V4H14V11ZM15 5V4H7V5H15Z"
              fill={fill}
            />
            <path d="M11 11H9V14H11V11Z" fill={fill} />
          </g>
          <defs>
            <clipPath id="clip0_924_6830">
              <rect
                width="14"
                height="14"
                fill="white"
                transform="translate(1 1)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "FLOOR":
      icon = (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.04163 1L1.04163 5.001H15.0416L8.04163 1Z"
            fill={fill}
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1 10.0004H2V6.0004H1V10.0004Z"
            fill={fill}
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1 15.0004H2V11.0004H1V15.0004Z"
            fill={fill}
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14 14.0004H15.001V11.0004H14V14.0004Z"
            fill={fill}
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1 10.0004H3V9.0004H1V10.0004Z"
            fill={fill}
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13 10.0004H15.001V9.0004H13V10.0004Z"
            fill={fill}
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4 10.0004H6.001V9.0004H4V10.0004Z"
            fill={fill}
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7 10.0004H9V9.0004H7V10.0004Z"
            fill={fill}
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10 10.0004H12V9.0004H10V10.0004Z"
            fill={fill}
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1 15.0004H3V14.0004H1V15.0004Z"
            fill={fill}
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13 15.0004H15.001V14.0004H13V15.0004Z"
            fill={fill}
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4 15.0004H6.001V14.0004H4V15.0004Z"
            fill={fill}
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7 15.0004H9V14.0004H7V15.0004Z"
            fill={fill}
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10 15.0004H12V14.0004H10V15.0004Z"
            fill={fill}
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14 10.0004H15.001V6.0004H14V10.0004Z"
            fill={fill}
          />
        </svg>
      );

      break;
    case "FRAME":
      icon = (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_924_6827)">
            <path d="M13 1V15H3V1H13ZM14 0H2V16H14V0Z" fill={fill} />
            <path d="M13 4H3V5H13V4Z" fill={fill} />
            <path d="M13 8H3V9H13V8Z" fill={fill} />
            <path d="M13 12H3V13H13V12Z" fill={fill} />
          </g>
          <defs>
            <clipPath id="clip0_924_6827">
              <rect
                width={width}
                height={height}
                fill="white"
                transform="translate(2)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "MODEM":
      icon = (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_924_6813)">
            <path d="M14 2V14H9V2H14ZM15 1H8V15H15V1Z" fill={fill} />
            <path
              d="M8.22 14.03L1.22 12.41L1 13.38L8 15L8.22 14.03ZM2 13.38V2.62H1V13.39H2V13.38ZM1.22 3.59L8.22 1.97L8 1L1 2.62L1.22 3.59Z"
              fill={fill}
            />
            <path
              d="M11.5 11C11.78 11 12 11.22 12 11.5C12 11.78 11.78 12 11.5 12C11.22 12 11 11.78 11 11.5C11 11.22 11.22 11 11.5 11ZM11.5 10C10.67 10 10 10.67 10 11.5C10 12.33 10.67 13 11.5 13C12.33 13 13 12.33 13 11.5C13 10.67 12.33 10 11.5 10Z"
              fill={fill}
            />
            <path d="M12 3H11V6H12V3Z" fill={fill} />
            <path d="M12 7H11V9H12V7Z" fill={fill} />
          </g>
          <defs>
            <clipPath id="clip0_924_6813">
              <rect
                width="14"
                height="14"
                fill="white"
                transform="translate(1 1)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "MONITOR":
      icon = (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_924_6812)">
            <path d="M14 2V10H2V2H14ZM15 1H1V11H15V1Z" fill={fill} />
            <path d="M9 11H7V14H9V11Z" fill={fill} />
            <path d="M11 13H5V15H11V13Z" fill={fill} />
          </g>
          <defs>
            <clipPath id="clip0_924_6812">
              <rect
                width="14"
                height="14"
                fill="white"
                transform="translate(1 1)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "RACK":
      icon = (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_924_6802)">
            <path d="M13 1V15H3V1H13ZM14 0H2V16H14V0Z" fill={fill} />
            <path d="M13 4H3V5H13V4Z" fill={fill} />
            <path d="M13 8H3V9H13V8Z" fill={fill} />
            <path d="M13 12H3V13H13V12Z" fill={fill} />
            <path
              d="M4.5 2C4.22 2 4 2.22 4 2.5C4 2.78 4.22 3 4.5 3C4.78 3 5 2.78 5 2.5C5 2.22 4.78 2 4.5 2Z"
              fill={fill}
            />
            <path
              d="M4.5 6C4.22 6 4 6.22 4 6.5C4 6.78 4.22 7 4.5 7C4.78 7 5 6.78 5 6.5C5 6.22 4.78 6 4.5 6Z"
              fill={fill}
            />
            <path
              d="M4.5 10C4.22 10 4 10.22 4 10.5C4 10.78 4.22 11 4.5 11C4.78 11 5 10.78 5 10.5C5 10.22 4.78 10 4.5 10Z"
              fill={fill}
            />
            <path
              d="M6.5 2C6.22 2 6 2.22 6 2.5C6 2.78 6.22 3 6.5 3C6.78 3 7 2.78 7 2.5C7 2.22 6.78 2 6.5 2Z"
              fill={fill}
            />
            <path
              d="M6.5 6C6.22 6 6 6.22 6 6.5C6 6.78 6.22 7 6.5 7C6.78 7 7 6.78 7 6.5C7 6.22 6.78 6 6.5 6Z"
              fill={fill}
            />
            <path
              d="M6.5 10C6.22 10 6 10.22 6 10.5C6 10.78 6.22 11 6.5 11C6.78 11 7 10.78 7 10.5C7 10.22 6.78 10 6.5 10Z"
              fill={fill}
            />
            <path d="M12 2H8V3H12V2Z" fill={fill} />
            <path d="M12 6H8V7H12V6Z" fill={fill} />
            <path d="M12 10H8V11H12V10Z" fill={fill} />
          </g>
          <defs>
            <clipPath id="clip0_924_6802">
              <rect
                width="12"
                height={height}
                fill="white"
                transform="translate(2)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "REGION":
      icon = (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_924_6820)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.98 0C9.32999 0 7.98999 1.34 7.98999 3C7.98999 5 10.98 9 10.98 9C10.98 9 13.97 5 13.97 3C13.97 1.34 12.64 0 10.98 0ZM10.98 4C10.43 4 9.97999 3.55 9.97999 3C9.97999 2.45 10.43 2 10.98 2C11.53 2 11.98 2.45 11.98 3C11.98 3.55 11.53 4 10.98 4Z"
              fill={fill}
            />
            <path
              d="M7.26999 4.99999L7.11999 4.00999C7.05999 4.00999 6.20999 4.14999 5.22999 4.61999L5.65999 5.51999C6.51999 5.10999 7.25999 4.98999 7.26999 4.98999M4.92999 5.94999L4.36999 5.11999C3.85999 5.46999 3.34999 5.91999 2.93999 6.49999L3.75999 7.06999C4.05999 6.63999 4.44999 6.25999 4.92999 5.93999M3.35999 7.77999L2.43999 7.37999C2.19999 7.92999 2.03999 8.56999 1.98999 9.31999L2.98999 9.37999C3.01999 8.79999 3.14999 8.25999 3.35999 7.77999ZM3.00999 10.24L2.00999 10.32C2.01999 10.48 2.03999 10.64 2.05999 10.8C2.05999 10.86 2.15999 11.47 2.45999 12.26L3.38999 11.9C3.11999 11.21 3.04999 10.67 3.04999 10.67C3.02999 10.52 3.01999 10.38 2.99999 10.24M3.75999 12.68L2.87999 13.16C3.17999 13.7 3.57999 14.25 4.10999 14.72L4.76999 13.97C4.37999 13.62 4.03999 13.19 3.74999 12.67M5.41999 14.44L4.91999 15.31C5.44999 15.61 6.06999 15.84 6.79999 15.94L6.93999 14.95C6.37999 14.87 5.86999 14.7 5.41999 14.44ZM7.78999 15L7.80999 16C8.11999 16 8.44999 15.97 8.79999 15.92C8.84999 15.92 9.22999 15.85 9.77999 15.68L9.46999 14.73C8.98999 14.88 8.65999 14.93 8.65999 14.93C8.35999 14.97 8.06999 15 7.78999 15ZM10.27 14.4L10.7 15.3C11.24 15.04 11.83 14.68 12.35 14.19L11.66 13.46C11.27 13.82 10.8 14.14 10.26 14.39M12.2 12.86L13.01 13.44C13.37 12.94 13.66 12.34 13.83 11.63L12.86 11.4C12.73 11.94 12.51 12.43 12.2 12.85M12.98 10.59L13.98 10.65C14.01 10.18 13.99 9.67999 13.91 9.12999L12.92 9.26999C12.98 9.72999 13 10.18 12.98 10.59Z"
              fill={fill}
            />
          </g>
          <defs>
            <clipPath id="clip0_924_6820">
              <rect
                width="11.99"
                height={height}
                fill="white"
                transform="translate(2)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "ROOM":
      icon = (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_924_6814)">
            <path
              d="M1.75 4.5L8.09 8L14.25 4.5"
              stroke={fill}
              stroke-miterlimit="10"
            />
            <path d="M8 8V14.55" stroke={fill} stroke-miterlimit="10" />
            <path
              d="M8 2.12L14 5.12V10.88L8 13.88L2 10.88V5.12L8 2.12ZM8 1L1 4.5V11.5L8 15L15 11.5V4.5L8 1Z"
              fill={fill}
            />
            <path d="M8.12 2L14 4.84L8.12 8L2 4.67L8.12 2Z" fill={fill} />
            <path
              d="M3.48999 12V8L6.00999 9.5V13.5"
              stroke={fill}
              stroke-miterlimit="10"
            />
            <path
              d="M10 9.00999L12.51 7.50999V10.51L10 11.75V9.00999Z"
              stroke={fill}
              stroke-miterlimit="10"
            />
          </g>
          <defs>
            <clipPath id="clip0_924_6814">
              <rect
                width="14"
                height="14"
                fill="white"
                transform="translate(1 1)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "ROUTER":
      icon = (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_924_6806)">
            <path
              d="M5.44 2.68C5.29 2.53 5.15 2.39 5 2.25C6.59 0.62 9.33 0.56 11 2.25C10.85 2.39 10.71 2.54 10.56 2.68C9.85 2 9 1.63 8.01 1.63C7.02 1.63 6.16 1.99 5.45 2.68H5.44Z"
              fill={fill}
            />
            <path
              d="M6.27002 3.51C6.12002 3.36 5.98002 3.22 5.83002 3.08C6.91002 1.93 8.96002 1.82 10.17 3.08C10.03 3.22 9.88002 3.36 9.73002 3.51C9.25002 3.05 8.67002 2.8 8.00002 2.8C7.33002 2.8 6.75002 3.04 6.27002 3.51Z"
              fill={fill}
            />
            <path
              d="M9.38 3.86C9.23 4.01 9.09 4.15 8.94 4.29C8.31 3.78 7.68 3.78 7.05 4.29C6.91 4.15 6.76 4.01 6.62 3.86C7.3 3.14 8.57 3.05 9.38 3.86Z"
              fill={fill}
            />
            <path
              d="M8.00002 5.54C7.70002 5.54 7.46002 5.3 7.46002 5C7.46002 4.7 7.70002 4.46 8.00002 4.46C8.30002 4.46 8.54002 4.71 8.54002 5.01C8.54002 5.31 8.29002 5.54 8.00002 5.54Z"
              fill={fill}
            />
            <path
              d="M14 8.04V12.04H2V8.04H14ZM15 7.04H1V13.04H15V7.04Z"
              fill={fill}
            />
            <path
              d="M4.5 9.04C3.95 9.04 3.5 9.49 3.5 10.04C3.5 10.59 3.95 11.04 4.5 11.04C5.05 11.04 5.5 10.59 5.5 10.04C5.5 9.49 5.05 9.04 4.5 9.04Z"
              fill={fill}
            />
            <path d="M9 9.04H8V11.04H9V9.04Z" fill={fill} />
            <path d="M11 9.04H10V11.04H11V9.04Z" fill={fill} />
            <path d="M13 9.04H12V11.04H13V9.04Z" fill={fill} />
            <path
              d="M3 12.41H2V15.04H3V12.41ZM2 14.04V15.04H14V14.04H2ZM13 15.04H14V12.04H13V15.04Z"
              fill={fill}
            />
            <path
              d="M2.01001 1.45L1.14001 1.95L4.57001 7.88L5.43001 7.38L2.01001 1.45Z"
              fill={fill}
            />
            <path
              d="M13.99 1.45L10.57 7.38L11.43 7.88L14.86 1.95L13.99 1.45Z"
              fill={fill}
            />
          </g>
          <defs>
            <clipPath id="clip0_924_6806">
              <rect
                width="14"
                height="14.04"
                fill="white"
                transform="translate(1 1)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "SERVER":
      icon = (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_924_6804)">
            <path d="M14 2V4H2V2H14ZM15 1H1V5H15V1Z" fill={fill} />
            <path
              d="M3.5 2.5C3.22 2.5 3 2.72 3 3C3 3.28 3.22 3.5 3.5 3.5C3.78 3.5 4 3.28 4 3C4 2.72 3.78 2.5 3.5 2.5Z"
              fill={fill}
            />
            <path
              d="M5.5 2.5C5.22 2.5 5 2.72 5 3C5 3.28 5.22 3.5 5.5 3.5C5.78 3.5 6 3.28 6 3C6 2.72 5.78 2.5 5.5 2.5Z"
              fill={fill}
            />
            <path
              d="M7.5 2.5C7.22 2.5 7 2.72 7 3C7 3.28 7.22 3.5 7.5 3.5C7.78 3.5 8 3.28 8 3C8 2.72 7.78 2.5 7.5 2.5Z"
              fill={fill}
            />
            <path d="M13 2.5H9V3.5H13V2.5Z" fill={fill} />
            <path d="M14 7V9H2V7H14ZM15 6H1V10H15V6Z" fill={fill} />
            <path
              d="M3.5 7.5C3.22 7.5 3 7.72 3 8C3 8.28 3.22 8.5 3.5 8.5C3.78 8.5 4 8.28 4 8C4 7.72 3.78 7.5 3.5 7.5Z"
              fill={fill}
            />
            <path
              d="M5.5 7.5C5.22 7.5 5 7.72 5 8C5 8.28 5.22 8.5 5.5 8.5C5.78 8.5 6 8.28 6 8C6 7.72 5.78 7.5 5.5 7.5Z"
              fill={fill}
            />
            <path
              d="M7.5 7.5C7.22 7.5 7 7.72 7 8C7 8.28 7.22 8.5 7.5 8.5C7.78 8.5 8 8.28 8 8C8 7.72 7.78 7.5 7.5 7.5Z"
              fill={fill}
            />
            <path d="M13 7.5H9V8.5H13V7.5Z" fill={fill} />
            <path d="M14 12V14H2V12H14ZM15 11H1V15H15V11Z" fill={fill} />
            <path
              d="M3.5 12.5C3.22 12.5 3 12.72 3 13C3 13.28 3.22 13.5 3.5 13.5C3.78 13.5 4 13.28 4 13C4 12.72 3.78 12.5 3.5 12.5Z"
              fill={fill}
            />
            <path
              d="M5.5 12.5C5.22 12.5 5 12.72 5 13C5 13.28 5.22 13.5 5.5 13.5C5.78 13.5 6 13.28 6 13C6 12.72 5.78 12.5 5.5 12.5Z"
              fill={fill}
            />
            <path
              d="M7.5 12.5C7.22 12.5 7 12.72 7 13C7 13.28 7.22 13.5 7.5 13.5C7.78 13.5 8 13.28 8 13C8 12.72 7.78 12.5 7.5 12.5Z"
              fill={fill}
            />
            <path d="M13 12.5H9V13.5H13V12.5Z" fill={fill} />
            <path
              d="M2.5 6.5L2.5 4.5L2.5 6.5Z"
              stroke={fill}
              stroke-miterlimit="10"
            />
            <path d="M5.5 6.5V4.5V6.5Z" stroke={fill} stroke-miterlimit="10" />
            <path d="M2.5 9.5V11.5V9.5Z" stroke={fill} stroke-miterlimit="10" />
            <path d="M5.5 9.5V11.5V9.5Z" stroke={fill} stroke-miterlimit="10" />
          </g>
          <defs>
            <clipPath id="clip0_924_6804">
              <rect
                width="14"
                height="14"
                fill="white"
                transform="translate(1 1)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "SHELF":
      icon = (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_924_6822)">
            <path d="M13 3V13H3V3H13ZM14 2H2V14H14V2Z" fill={fill} />
            <path d="M13 6H3V7H13V6Z" fill={fill} />
            <path d="M13 9H3V10H13V9Z" fill={fill} />
            <path d="M3 0H2V2H3V0Z" fill={fill} />
            <path d="M14 0H13V2H14V0Z" fill={fill} />
            <path d="M3 14H2V16H3V14Z" fill={fill} />
            <path d="M14 14H13V16H14V14Z" fill={fill} />
            <path
              d="M4.5 4C4.22 4 4 4.22 4 4.5C4 4.78 4.22 5 4.5 5C4.78 5 5 4.78 5 4.5C5 4.22 4.78 4 4.5 4Z"
              fill={fill}
            />
            <path
              d="M6.5 4C6.22 4 6 4.22 6 4.5C6 4.78 6.22 5 6.5 5C6.78 5 7 4.78 7 4.5C7 4.22 6.78 4 6.5 4Z"
              fill={fill}
            />
            <path
              d="M4.5 11C4.22 11 4 11.22 4 11.5C4 11.78 4.22 12 4.5 12C4.78 12 5 11.78 5 11.5C5 11.22 4.78 11 4.5 11Z"
              fill={fill}
            />
            <path
              d="M6.5 11C6.22 11 6 11.22 6 11.5C6 11.78 6.22 12 6.5 12C6.78 12 7 11.78 7 11.5C7 11.22 6.78 11 6.5 11Z"
              fill={fill}
            />
            <path d="M12 4H8V5H12V4Z" fill={fill} />
            <path d="M12 11H8V12H12V11Z" fill={fill} />
          </g>
          <defs>
            <clipPath id="clip0_924_6822">
              <rect
                width={width}
                height={height}
                fill="white"
                transform="translate(2)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "SWITCH":
      icon = (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_924_6823)">
            <path d="M16 2H0V3H16V2Z" fill="white" />
            <path d="M16 2H0V3H16V2Z" fill={fill} />
            <path d="M16 13H0V14H16V13Z" fill="white" />
            <path d="M16 13H0V14H16V13Z" fill={fill} />
            <path d="M15 5V11H1V5H15ZM16 4H0V12H16V4Z" fill={fill} />
            <path d="M3 6H2V10H3V6Z" fill={fill} />
            <path d="M5 6H4V10H5V6Z" fill={fill} />
            <path d="M10 6H9V7H10V6Z" fill={fill} />
            <path d="M12 6H11V7H12V6Z" fill={fill} />
            <path d="M14 6H13V7H14V6Z" fill={fill} />
            <path d="M10 8H9V9H10V8Z" fill={fill} />
            <path d="M12 8H11V9H12V8Z" fill={fill} />
            <path d="M14 8H13V9H14V8Z" fill={fill} />
            <path d="M8 6H7V7H8V6Z" fill={fill} />
            <path d="M8 8H7V9H8V8Z" fill={fill} />
          </g>
          <defs>
            <clipPath id="clip0_924_6823">
              <rect
                width={width}
                height="12"
                fill="white"
                transform="translate(0 2)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "ZONE":
      icon = (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_924_6821)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8 11C4.14 11 1 11.89 1 13C1 14.11 4.14 15 8 15C11.86 15 15 14.1 15 13C15 11.9 11.86 11 8 11ZM7.99 14C5.24 14 3 13.55 3 13C3 12.45 5.24 12 7.99 12C10.74 12 13 12.45 13 13C13 13.55 10.76 14 7.99 14Z"
              fill={fill}
            />
            <path
              d="M8 2C9.65 2 11 3.35 11 5C11 5.36 10.93 5.72 10.79 6.07L10.75 6.17L10.36 6.87L8 10.99L5.64 6.87L5.25 6.17L5.21 6.06C5.07 5.71 5 5.35 5 4.99C5 3.34 6.35 1.99 8 1.99M8 1C5.79 1 4 2.79 4 5C4 5.51 4.1 5.99 4.28 6.44C4.28 6.48 4.3 6.52 4.32 6.55L4.35 6.61L4.77 7.36L8 13L11.23 7.36L11.65 6.61L11.68 6.55C11.68 6.55 11.72 6.48 11.72 6.44C11.9 5.99 12 5.51 12 5C12 2.79 10.21 1 8 1Z"
              fill={fill}
            />
            <path
              d="M8 4C8.55 4 9 4.45 9 5C9 5.55 8.55 6 8 6C7.45 6 7 5.55 7 5C7 4.45 7.45 4 8 4ZM8 3C6.9 3 6 3.9 6 5C6 6.1 6.9 7 8 7C9.1 7 10 6.1 10 5C10 3.9 9.1 3 8 3Z"
              fill={fill}
            />
          </g>
          <defs>
            <clipPath id="clip0_924_6821">
              <rect
                width="14"
                height="14"
                fill="white"
                transform="translate(1 1)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    default:
      icon = (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_924_6794"
            style={{ maskType: "luminance" }}
            maskUnits="userSpaceOnUse"
            x="4"
            y="4"
            width="11"
            height="11"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.99951 4.98384H14.9995V15H4.99951V4.98384Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0_924_6794)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.0065 10.4148C10.8835 10.4148 11.5565 9.31196 11.5565 7.93369C11.5565 6.7247 10.9895 6.00753 10.0065 6.00753C9.02351 6.00753 8.45651 6.7247 8.45651 7.93369C8.45651 9.31196 9.12951 10.4148 10.0065 10.4148ZM9.99951 11.4244C11.4135 11.4244 12.4995 9.81178 12.4995 7.79847C12.4995 6.03157 11.5855 4.98384 9.99951 4.98384C8.41451 4.98384 7.49951 6.03157 7.49951 7.79847C7.49951 9.81178 8.58551 11.4244 9.99951 11.4244ZM14.0065 13.9987H5.99551V13.3215C5.99551 12.7135 6.39951 11.9894 7.00651 11.9894H7.80751C8.43051 12.4862 9.19651 12.7726 9.99251 12.8047C10.7935 12.7756 11.5635 12.4922 12.1925 11.9964H13.0065C13.6145 11.9964 14.0065 12.7206 14.0065 13.3296V13.9987ZM11.9145 10.9827C11.4135 11.5076 10.7245 11.9733 9.99951 11.9843C9.27551 11.9733 8.58651 11.5076 8.08551 10.9827H6.66451C5.74451 10.9877 5.00051 11.9623 4.99951 12.8838L5.00001 15H15L14.9995 12.8838C14.9995 11.9623 14.2555 10.9877 13.3355 10.9827H11.9145Z"
              fill={fill}
            />
          </g>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2 9.99177L1.995 9.3387C1.995 8.7307 2.399 8.00651 3.007 8.00651H3.807C4.431 8.50333 5.196 8.7888 5.993 8.82185C6.332 8.80883 6.663 8.74172 6.983 8.64055V7.71303C6.674 7.88231 6.343 7.99649 6 8.0015C5.276 7.99048 4.501 7.5107 4 6.98684L2.664 6.99986C1.744 7.00386 1 7.97947 1 8.90098V11H5V10L2 9.99177Z"
            fill={fill}
          />
          <mask
            id="mask1_924_6794"
            style={{ maskType: "luminance" }}
            maskUnits="userSpaceOnUse"
            x="3"
            y="1"
            width="6"
            height="7"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.50049 1H8.50049V7.44148H3.50049V1Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask1_924_6794)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.98349 5.87591C6.71849 6.21847 6.38349 6.43182 6.00649 6.43182C5.12949 6.43182 4.45649 5.32801 4.45649 3.94975C4.45649 2.74176 5.02349 2.02458 6.00649 2.02458C6.98949 2.02458 7.55649 2.74176 7.55649 3.94975C7.55649 4.31635 7.50649 4.66092 7.41949 4.97343H8.36249C8.44549 4.60783 8.50049 4.2232 8.50049 3.81452C8.50049 2.04862 7.58549 0.999901 6.00049 0.999901C4.41449 0.999901 3.50049 2.04862 3.50049 3.81452C3.50049 5.82883 4.58549 7.44148 5.99949 7.44148C6.35249 7.44148 6.68349 7.34032 6.98349 7.15902V6.9066V5.87591Z"
              fill={fill}
            />
          </g>
        </svg>
      );

      break;
  }

  return icon;
};
