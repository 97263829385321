import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../util/utils";
import { StyledReportsMoreIcon } from "../../styles/reports/Reports.styles";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  editResultField,
  removeResultField,
  selectFurtherResultFields,
  selectGroupResultFields,
  setShouldExecute,
  setShouldRefreshDialogTable,
} from "../../../store/slices/reportsSlice";
import { useDispatch } from "react-redux";
import ConfirmAlert from "../../../store/confirm/ConfirmAlert";
import ResultFieldsDialog from "./ResultFieldsDialog";
import { getSvgIcon } from "../../../util/icons";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";

const ResultFieldsListItem = ({ resultField, index }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const resultFields = useSelector(selectFurtherResultFields);
  const groupFields = useSelector(selectGroupResultFields);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // State
  const [openConfirm, setOpenConfirm] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [fieldValue, setFieldValue] = useState(resultField.field);
  const [columnTitle, setColumnTitle] = useState(resultField.displayName);
  const [aggregationTypes, setAggregationTypes] = useState(
    resultField.aggregationTypes
  );
  const [orderIndex, setOrderIndex] = useState(resultField.orderIndex);
  const [sortMethod, setSortMethod] = useState(resultField.sortMethod);

  // Other variables
  const openMenu = Boolean(anchorEl);

  const alert = {
    content: "After this action, this result field will be removed",
    confirmTitle: "Remove result field",
    closeTitle: getTranslation("CANCEL", t, i18n),
    showConfirm: true,
  };

  const haveChanges =
    fieldValue !== resultField.field ||
    columnTitle !== resultField.displayName ||
    aggregationTypes?.length !== resultField?.aggregationTypes?.length ||
    aggregationTypes?.some(
      (aggregation) =>
        !resultField?.aggregationTypes?.some(
          (fieldAggregation) => fieldAggregation === aggregation
        )
    ) ||
    Boolean(orderIndex) ||
    Boolean(sortMethod);

  const iconSize = globalFontSize * 1.5;

  // Handlers
  const handleOpenConfirm = () => {
    handleCloseMenu();
    setOpenConfirm(true);
  };

  const handleConfirm = () => {
    dispatch(removeResultField(resultField.uniqueId));
    setOpenConfirm(false);
  };

  const handleReturnCountForColumnsMenuItems = () => {
    const menuItems = [];

    for (let i = 1; i <= resultFields.length; i++) {
      menuItems.push(i);
    }

    return menuItems;
  };

  const handleReturnCountForGroupsMenuItems = () => {
    const menuItems = [];

    for (let i = 1; i <= groupFields.length; i++) {
      menuItems.push(i);
    }

    return menuItems;
  };

  const handleOnChangeFieldValue = (fieldValue) => {
    setFieldValue(fieldValue);
    setColumnTitle(getTranslation(fieldValue, t, i18n));
    dispatch(setShouldRefreshDialogTable(false));
  };

  const handleOnChangeColumnTitle = (e) => {
    const newColumnTitle = e.target.value;
    setColumnTitle(newColumnTitle);
    dispatch(setShouldRefreshDialogTable(false));
  };

  const handleOnChangeAggregationType = (event, newValue) => {
    const newAggregationTypes = newValue;
    setAggregationTypes(newAggregationTypes);
    dispatch(setShouldRefreshDialogTable(false));
  };

  const handleOnChangeOrderIndex = (e) => {
    const newOrderIndex = e.target.value;
    setOrderIndex(newOrderIndex);

    if (!sortMethod) {
      setSortMethod("asc");
    }

    dispatch(setShouldRefreshDialogTable(false));
  };

  const handleOnChangeSortMethod = (e) => {
    const newSortMethod = e.target.value;
    setSortMethod(newSortMethod);
    dispatch(setShouldRefreshDialogTable(false));
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenEditDialog = () => {
    handleCloseMenu();
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    dispatch(setShouldRefreshDialogTable(false));
    setOpenEditDialog(false);
  };

  const handleDoneEdit = () => {
    handleCloseEditDialog();

    const newResultField = {
      uniqueId: resultField.uniqueId,
      displayName: columnTitle,
      field: fieldValue,
      orderIndex,
      sortMethod,
      usedForGrouping: resultField.usedForGrouping,
      aggregationTypes,
    };

    dispatch(editResultField(newResultField));

    dispatch(setShouldExecute(false));
  };

  return (
    <>
      <Draggable
        key={resultField.uniqueId}
        draggableId={resultField.uniqueId}
        index={index}
      >
        {(provided) => (
          <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: "10px",
                marginBottom: "10px",
              }}
            >
              <Box>
                <DragIndicatorIcon id="drag-icon" />
              </Box>

              <Box sx={{ flexGrow: 1 }}>
                <Typography>
                  {getTranslation(resultField.field, t, i18n) !==
                  resultField.displayName
                    ? `${resultField.displayName}*`
                    : resultField.displayName}
                </Typography>
              </Box>

              <Box>
                <IconButton
                  id="more-menu-result-fields-button"
                  onClick={handleOpenMenu}
                >
                  <StyledReportsMoreIcon />
                </IconButton>
              </Box>
            </Box>
          </div>
        )}
      </Draggable>

      <Menu
        id="more-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          sx={{ color: theme.palette.secondary.contrastText }}
          onClick={handleOpenEditDialog}
        >
          <div style={{ marginTop: "5px", marginRight: "10px" }}>
            {getSvgIcon(
              "EDIT",
              iconSize,
              iconSize,
              theme.palette.secondary.contrastText
            )}
          </div>
          Edit
        </MenuItem>

        <MenuItem sx={{ color: "#EE0000" }} onClick={handleOpenConfirm}>
          <DeleteIcon sx={{ marginRight: "10px" }} />
          Delete
        </MenuItem>
      </Menu>

      <ConfirmAlert
        isOpen={openConfirm}
        setIsOpen={setOpenConfirm}
        alert={alert}
        label="delete-rule"
        handleConfirm={handleConfirm}
      />

      {openEditDialog && (
        <ResultFieldsDialog
          uniqueId={resultField.uniqueId}
          isGroup={resultField.usedForGrouping}
          isEdit={true}
          open={openEditDialog}
          handleClose={handleCloseEditDialog}
          handleDone={handleDoneEdit}
          fieldValue={fieldValue}
          columnTitle={columnTitle}
          aggregationTypes={aggregationTypes}
          orderIndex={orderIndex}
          sortMethod={sortMethod}
          handleOnChangeFieldValue={handleOnChangeFieldValue}
          handleOnChangeColumnTitle={handleOnChangeColumnTitle}
          handleOnChangeAggregationType={handleOnChangeAggregationType}
          handleOnChangeOrderIndex={handleOnChangeOrderIndex}
          handleOnChangeSortMethod={handleOnChangeSortMethod}
          handleReturnCountForMenuItems={
            resultField.usedForGrouping
              ? handleReturnCountForGroupsMenuItems
              : handleReturnCountForColumnsMenuItems
          }
          haveChanges={haveChanges}
          setFieldValue={setFieldValue}
          setColumnTitle={setColumnTitle}
          setAggregationTypes={setAggregationTypes}
          setOrderIndex={setOrderIndex}
          setSortMethod={setSortMethod}
          resultField={resultField}
        />
      )}
    </>
  );
};

export default ResultFieldsListItem;
