import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  Radio,
  Stack,
  TableCell,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  alpha,
  Checkbox,
} from "@mui/material";
import { styled } from "@mui/system";
import DeleteIcon from "@mui/icons-material/Delete";
import { DatePicker } from "@mui/x-date-pickers";
import CachedIcon from "@mui/icons-material/Cached";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  timelineItemClasses,
} from "@mui/lab";
import { SecondaryText } from "../assets/ListInlineView.styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ReportSecondaryText } from "../home/ChartPage.styles";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { THEME } from "../../../util/utils";

export const AddGroupButton = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  padding: 0,
}));

export const CustomCombinatorSelectorFormControl = styled(FormControl)({
  margin: "20px 0 20px 30px",
});

export const SecondaryContrastDeleteIcon = styled(DeleteIcon)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
}));

export const CustomValueEditorAutocomplete = styled(Autocomplete)(
  ({ fullWidth }) => ({
    "&.MuiAutocomplete-root .MuiFormControl-root": {
      margin: 0,
    },
    width: fullWidth ? "100%" : "33%",
  })
);

export const CustomValueEditorDatePicker = styled(DatePicker)(
  ({ fullWidth }) => ({
    marginTop: 0,
    width: fullWidth ? "100%" : "33%",
  })
);

export const CustomValueEditorTextField = styled(TextField)(
  ({ fullWidth }) => ({
    margin: 0,
    width: fullWidth ? "100%" : "33%",
  })
);

export const SecondaryContrastRadioButton = styled(Radio)(({ theme }) => ({
  "&.Mui-checked": {
    color: theme.palette.secondary.contrastText,
  },
}));

export const BasicDataWizardStepStack = styled(Stack)({
  height: "100%",
});

export const BasicDataWizardStepTextField = styled(TextField)({
  flexGrow: 1,
});

export const DefineQueryWizardStepWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  flexGrow: 1,
});

export const ReportWizardTimeLineContainer = styled(Box)({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

export const ReportRecordsFoundTypography = styled(Typography)({
  fontWeight: "bold",
  marginTop: "20px",
});

export const ReportPreviewWrapper = styled(Box)({
  marginTop: "20px",
});

export const ReportPreviewTypography = styled(Typography)({
  fontWeight: "bold",
  marginBottom: "10px",
});

export const ReportRefreshButton = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  marginLeft: "15px",
}));

export const ReportRefreshCachedIcon = styled(CachedIcon)({
  marginRight: "5px",
});

export const FormatResultPreviewTableWrapper = styled(Box)({
  marginBottom: "20px",
});

export const EmptyRowTable = styled(TableRow)(({ rowHeight }) => ({
  height: rowHeight,
}));

export const FormatResultWizardStepGridContainer = styled(Grid)({
  marginTop: "40px",
});

export const FormatResultWizardStepFieldsListWrapper = styled(Box)({
  paddingInline: "30px",
});

export const FieldsHeaderTypography = styled(Typography)({
  color: "#C3C3C3",
  marginBottom: "10px",
});

export const AddFieldButton = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  padding: 0,
  marginLeft: "30px",
  marginTop: "20px",
}));

export const QueryDefinitionPreviewTableHeaderCell = styled(TableCell)(
  ({ color }) => ({
    color,
  })
);

export const QueryDefinitionPreviewTableBodyRow = styled(TableRow)({
  "&:last-child td, &:last-child th": { border: 0 },
});

export const StyledReportsWizardTimeline = styled(Timeline)({
  [`& .${timelineItemClasses.root}:before`]: {
    flex: 0,
    padding: 0,
  },
});

export const StyledTimelineConnector = styled(TimelineConnector)({
  "&.MuiTimelineConnector-root": {
    backgroundColor: "#7D8182",
    height: "50px",
  },
});

export const StyledTimelineDot = styled(TimelineDot)(
  ({ wizardStep, targetWizardStep, isEdit }) => ({
    "&.MuiTimelineDot-root": {
      backgroundColor: wizardStep === targetWizardStep ? "#1976D2" : "#7D8182",
      height: "15px",
      width: "15px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: isEdit ? "pointer" : "",
    },
  })
);

export const StyledTimelineContent = styled(TimelineContent)(
  ({ theme, wizardStep, targetWizardStep }) => ({
    marginTop: "5px",
    color:
      wizardStep === targetWizardStep ? "#1976D2" : theme.palette.primary.main,
  })
);

export const ResultFieldsListItemGridContainer = styled(Grid)({
  marginBottom: "20px",
});

export const DragIndicatorIconWrapper = styled(Box)({
  marginTop: "20px",
});

export const FieldsStack = styled(Stack)({
  width: "100%",
});

export const ReportsSecondaryText = styled(SecondaryText)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const DeleteFieldButtonWrapper = styled(Box)({
  marginTop: "15px",
});

export const ReportsWizardGridContainer = styled(Grid)({
  height: "100%",
});

export const CreateReportWrapper = styled(Box)({
  padding: "20px",
  height: "calc(100vh - 104px)",
  overflow: "auto",
  display: "flex",
  flexDirection: "column",
});

export const CreateReportTypography = styled(SecondaryText)({
  fontWeight: "bold",
  marginBottom: "20px",
});

export const SaveAndContinueWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  rowGap: "20px",
  padding: "20px",
});

export const ReportsWizardRightSectionGridContainer = styled(Grid)(
  ({ theme }) => ({
    backgroundColor: theme.palette.secondary.dark,
  })
);

export const ReportsWizardBackButton = styled(Button)({
  borderColor: "#1976D2",
  color: "#1976D2",
  boxShadow: "none",
  "&:hover": {
    borderColor: "#1976D2",
  },
  width: "100%",
  borderRadius: "5px",
});

export const ReportsWizardContinueButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#1976D2",
  color: theme.palette.primary.contrastText,
  marginRight: "10px",
  boxShadow: "none",
  "&:hover": {
    backgroundColor: "#1976D2",
  },
  width: "100%",
  borderRadius: "5px",
}));

export const ReportDetailsWizardDialog = styled(Dialog)({
  "& .MuiDialogContent-root": { padding: 0 },
});

export const CloseReportsDialogButton = styled(IconButton)({
  position: "absolute",
  right: 8,
  top: 8,
  zIndex: 1,
  color: (theme) => theme.palette.grey[500],
});

export const StyledArrowDropDownIcon = styled(ArrowDropDownIcon)(
  ({ globalFontSize }) => ({
    fontSize: `${globalFontSize * 1.5}px`,
  })
);

export const ReportExecutionWrapper = styled(Box)({
  flexGrow: 1,
});

export const ReportSecondaryGrowingText = styled(ReportSecondaryText)({
  flexGrow: 1,
});

export const ReportTableListSortLabel = styled(TableSortLabel)(({ theme }) => ({
  ".MuiSvgIcon-root": {
    stroke: theme.palette.secondary.contrastText,
    marginLeft: "16px",
  },
}));

export const ReportTableListBodyRow = styled(TableRow)(
  ({ theme, longPressed, currentTheme }) => ({
    cursor: "pointer",
    position: longPressed ? "relative" : "static",
    zIndex: longPressed ? 1200 : "auto",
    backgroundColor: longPressed
      ? currentTheme === THEME.LIGHT
        ? "#FFF"
        : "#222222"
      : "",
    "&.Mui-selected": {
      backgroundColor: alpha("#0076bc", 0.1),
    },
    "&.Mui-selected:hover": {
      backgroundColor: alpha("#0076bc", 0.1),
    },
  })
);

export const ReportName = styled(SecondaryText)(({ active }) => ({
  fontWeight: active ? "bold" : "normal",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  maxWidth: 180,
}));

export const ReportTableListDescriptionCell = styled(TableCell)({
  maxWidth: 200,
  overflow: "hidden",
});

export const TableReportPagination = styled(TablePagination)(
  ({ globalFontSize }) => ({
    ".MuiTablePagination-selectIcon": {
      fontSize: `${globalFontSize * 1.5}px`,
    },
    ".MuiTablePagination-select": {
      fontSize: `${globalFontSize}px`,
      p: globalFontSize >= 18 ? 1 : 0,
      mr: globalFontSize >= 18 ? 1 : 0,
    },
    ".MuiTablePagination-actions button svg": {
      fontSize: `${globalFontSize * 1.5}px`,
    },
  })
);

export const StyledReportsMoreIcon = styled(MoreHorizIcon)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
}));

export const QueryCheckboxDesktop = styled(Checkbox)({
  marginRight: "8px",
});

export const QueryCheckboxMobile = styled(Checkbox)({
  marginRight: "8px",
});

export const ResultCheckboxDesktop = styled(Checkbox)({
  marginRight: "8px",
});

export const ResultCheckboxMobile = styled(Checkbox)({
  marginRight: "8px",
});
