/* eslint-disable react-hooks/exhaustive-deps */
import {
  Grid,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  selectGlobalFontSize,
  selectGraphicalViewAssetDisplay,
  selectGraphicalViewFitMode,
  setGraphicalViewAssetDisplay,
  setGraphicalViewFitMode,
} from "../../../store/slices/appSlice";
import {
  TopBorderContainer,
  TopBorderText,
  TopBorderWrapper,
} from "../../styles/assets/graphical-rack-view/GraphicalRackView.styles";
import { NavigationActionIcon } from "../../styles/menu/Menu.styles";
import {
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
  permissions,
} from "../../../util/utils";
import { useEffect } from "react";
import {
  useCreateGraphicalObjectMutation,
  useDeleteGraphicalObjectMutation,
  usePatchGraphicalObjectMutation,
} from "../../../store/slices/api/graphicalObjectsSlice";
import { selectUser } from "../../../store/slices/authSlice";
import { messageSuccess } from "../../../util/notification";
import { get_single_graphical_object_url } from "../../../Constants";
import KeycloakService from "../../../services/KeycloakService";
import {
  decreaseConstant,
  DEFAULT_UNDO_REDO_STATE,
  increaseConstant,
  redo,
  selectConstant,
  selectEvent,
  selectFirstRedo,
  selectLastUndo,
  setEvent,
  undo,
  updateHistory,
} from "../../../store/slices/undoRedoGraphicalViewSlice";
import ConfirmAlert from "../../../store/confirm/ConfirmAlert";
import useShakeDetector from "../../../hooks/useShakeDetector";
import { ASSET_DISPLAY_MODES } from "../../../util/graphical-rack-view-utils";
import { InvitationInputFormControl } from "../../styles/invitation/InvitationForm.styles";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import { useUserRolePermissionsQuery } from "../../../store/slices/api/userManagementSlice";
import ErrorHandling from "../../common/ErrorHandling";
import { getSvgIcon } from "../../../util/icons";

const TopBorder = ({
  elementRef,
  graphicalViewSide,
  locked,
  toggleLock,
  handleChangeGraphicalRackViewSide,
  handleOpenGraphicalObjects,
}) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [isShaking, setIsShaking] = useShakeDetector(16);
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);
  const graphicalViewAssetDisplay = useSelector(
    selectGraphicalViewAssetDisplay
  );
  const graphicalViewFitMode = useSelector(selectGraphicalViewFitMode);
  const constant = useSelector(selectConstant);
  const lastUndo = useSelector(selectLastUndo);
  const firstRedo = useSelector(selectFirstRedo);
  const event = useSelector(selectEvent);

  // State
  const [openUndo, setOpenUndo] = useState(false);

  // Other variables
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const iconSize = globalFontSize * 1.5;

  // Queries
  const { data: userRoles, isLoading: isLoadingUserRoles } =
    useUserRolePermissionsQuery({
      userId: user.id,
      organizationId,
    });

  // Mutations
  const [createGraphicalObject] = useCreateGraphicalObjectMutation();
  const [deleteGraphicalObject] = useDeleteGraphicalObjectMutation();
  const [patchGraphicalObject] = usePatchGraphicalObjectMutation();

  // Handlers
  const handleUndo = () => {
    dispatch(decreaseConstant());
  };

  const handleRedo = () => {
    dispatch(increaseConstant());
  };

  const handleConfirm = () => {
    dispatch(decreaseConstant());
  };

  const handleSelectAssetDisplayMode = (e) => {
    dispatch(setGraphicalViewAssetDisplay(e.target.value));
  };

  const handleChangeGraphicalRackViewFitMode = (fitMode) => {
    dispatch(setGraphicalViewFitMode(fitMode));
  };

  const handleMutations = useCallback(async () => {
    if (event !== "undo" && event !== "redo") {
      return;
    }

    if (!lastUndo && !firstRedo) {
      return;
    }

    if (event === "undo" && !lastUndo) {
      return;
    }

    if (event === "redo" && !firstRedo) {
      return;
    }

    const { action, payload: objects } =
      event === "undo" ? lastUndo : firstRedo;

    try {
      if (action === "CREATE") {
        await objects.forEach(async (o) => {
          const { id } = o;

          await deleteGraphicalObject({
            organizationId,
            graphicalObjectId: id,
          }).unwrap();

          messageSuccess(getTranslation("OBJECT_DELETED", t, i18n));
        });
      } else if (action === "UPDATE") {
        const promises = await objects.map(async (o) => {
          const { id, yCoordinate, angleOfRotation } = o;
          const graphicalObjectDto = {
            angleOfRotation,
            yCoordinate,
          };
          const response = await fetch(
            get_single_graphical_object_url
              .replace(":organizationId", organizationId)
              .replace(":graphicalObjectId", id),
            {
              headers: {
                Authorization: `Bearer ${KeycloakService.getToken()}`,
              },
            }
          );

          const data = await response.json();

          await patchGraphicalObject({
            organizationId,
            graphicalObjectId: id,
            graphicalObjectDto,
          }).unwrap();

          return data;
        });
        let responseDataList = await Promise.all(promises);

        if (event === "undo") {
          dispatch(undo(responseDataList));
        } else if (event === "redo") {
          dispatch(redo(responseDataList));
        }

        dispatch(setEvent(DEFAULT_UNDO_REDO_STATE.event));

        messageSuccess(getTranslation("OBJECT_UPDATED", t, i18n));
        return;
      } else if (action === "DELETE") {
        const promises = await objects.map(async (o) => {
          const { id, resourceId, yCoordinate, angleOfRotation } = o;
          const graphicalObjectDto = {
            resourceId,
            parentResourceId: null,
            type: "ASSET",
            shape: "RECTANGLE",
            xCoordinate: 0,
            yCoordinate,
            zCoordinate: 0,
            angleOfRotation,
            characteristics: [],
          };

          const createdGraphicalObject = await createGraphicalObject({
            organizationId,
            graphicalObjectDto,
          }).unwrap();

          return {
            oldId: id,
            newId: createdGraphicalObject.id,
          };
        });

        let ids = await Promise.all(promises);

        dispatch(updateHistory({ ids }));

        messageSuccess(getTranslation("OBJECT_CREATED", t, i18n));
      }

      if (event === "undo") {
        dispatch(undo(null));
      } else if (event === "redo") {
        dispatch(redo(null));
      }

      dispatch(setEvent(DEFAULT_UNDO_REDO_STATE.event));
    } catch (error) {
      console.error("Failed mutation", error);
    }
  }, [lastUndo, firstRedo, constant, event]);

  useEffect(() => {
    handleMutations();
  }, [constant]);

  useEffect(() => {
    if (isShaking && Boolean(lastUndo)) {
      setOpenUndo(true);
    }
  }, [isShaking, lastUndo]);

  useEffect(() => {
    if (!openUndo && isShaking) {
      setIsShaking(false);
    }
  }, [openUndo, isShaking]);

  return (
    <ErrorHandling isLoading={isLoadingUserRoles} isError={false}>
      <TopBorderContainer ref={elementRef}>
        <TopBorderWrapper container>
          <ConfirmAlert
            isOpen={openUndo}
            setIsOpen={setOpenUndo}
            alert={{
              content: getTranslation("Undo change", t, i18n),
              confirmTitle: getTranslation("UNDO", t, i18n),
              closeTitle: getTranslation("CANCEL", t, i18n),
              showConfirm: true,
            }}
            label="undo"
            handleConfirm={handleConfirm}
          />
          <Grid item xs sx={{ display: "flex", alignItems: "center" }}>
            <TopBorderText>
              {graphicalViewSide === "front"
                ? getTranslation("GRAPHICAL_VIEW_FRONT", t, i18n)
                : getTranslation("REAR", t, i18n)}
            </TopBorderText>

            <IconButton onClick={handleChangeGraphicalRackViewSide}>
              <NavigationActionIcon
                sx={{ marginLeft: "8px" }}
                active={true}
                customfontsize={globalFontSize}
              >
                rotate_left
              </NavigationActionIcon>
            </IconButton>

            {!mobileMatches && graphicalViewFitMode === "height" && (
              <Tooltip title={getTranslation("FIT_WIDTH", t, i18n)}>
                <IconButton
                  onClick={() => handleChangeGraphicalRackViewFitMode("width")}
                >
                  <NavigationActionIcon
                    sx={{ marginLeft: "8px" }}
                    active={true}
                    customfontsize={globalFontSize}
                  >
                    document_scanner
                  </NavigationActionIcon>
                </IconButton>
              </Tooltip>
            )}

            {!mobileMatches && graphicalViewFitMode === "width" && (
              <Tooltip title={getTranslation("FIT_HEIGHT", t, i18n)}>
                <IconButton
                  onClick={() => handleChangeGraphicalRackViewFitMode("height")}
                >
                  <NavigationActionIcon
                    sx={{ marginLeft: "8px" }}
                    active={true}
                    customfontsize={globalFontSize}
                  >
                    fit_screen
                  </NavigationActionIcon>
                </IconButton>
              </Tooltip>
            )}
          </Grid>
          {!locked && (
            <Grid item xs sx={{ display: "flex", justifyContent: "center" }}>
              <IconButton onClick={handleUndo} disabled={!Boolean(lastUndo)}>
                <NavigationActionIcon
                  active={Boolean(lastUndo)}
                  customfontsize={globalFontSize}
                >
                  undo
                </NavigationActionIcon>
              </IconButton>
              <IconButton onClick={handleRedo} disabled={!Boolean(firstRedo)}>
                <NavigationActionIcon
                  active={Boolean(firstRedo)}
                  customfontsize={globalFontSize}
                >
                  redo
                </NavigationActionIcon>
              </IconButton>
            </Grid>
          )}

          <Grid
            item
            xs
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            {mobileMatches && locked && (
              <InvitationInputFormControl variant="standard">
                <Select
                  disableUnderline
                  id="mobile-graphical-view-display-options"
                  value={graphicalViewAssetDisplay}
                  onChange={handleSelectAssetDisplayMode}
                  label={getTranslation("role", t, i18n)}
                >
                  <MenuItem value={ASSET_DISPLAY_MODES.IMAGES}>
                    {getTranslation("IMAGES_VIEW", t, i18n)}
                  </MenuItem>

                  <MenuItem value={ASSET_DISPLAY_MODES.BITMAPS}>
                    {getTranslation("BITMAP_VIEW", t, i18n)}
                  </MenuItem>

                  <MenuItem value={ASSET_DISPLAY_MODES.BOX}>
                    {getTranslation("BOX_VIEW", t, i18n)}
                  </MenuItem>
                </Select>
              </InvitationInputFormControl>
            )}

            {!locked && (
              <IconButton onClick={handleOpenGraphicalObjects}>
                {getSvgIcon(
                  "LIST_VIEW",
                  iconSize,
                  iconSize,
                  theme.palette.secondary.contrastText
                )}
              </IconButton>
            )}

            {hasAccess(
              "all",
              [permissions["GRAPHICAL_OBJECT_EDIT"]],
              getPermissionsFromUserRoles(userRoles)
            ) && (
              <IconButton onClick={toggleLock}>
                {locked ? (
                  getSvgIcon(
                    "EDIT",
                    iconSize,
                    iconSize,
                    theme.palette.secondary.contrastText
                  )
                ) : (
                  <NavigationActionIcon
                    active={true}
                    customfontsize={globalFontSize}
                  >
                    done
                  </NavigationActionIcon>
                )}
              </IconButton>
            )}
          </Grid>
        </TopBorderWrapper>
      </TopBorderContainer>
    </ErrorHandling>
  );
};

export default TopBorder;
