import { Box, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import {
  addGroupField,
  selectGroupResultFields,
  setShouldExecute,
} from "../../../store/slices/reportsSlice";
import { Droppable } from "react-beautiful-dnd";
import ResultFieldsListItem from "./ResultFieldsListItem";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../util/utils";
import {
  AddFieldButton,
  FieldsHeaderTypography,
} from "../../styles/reports/Reports.styles";
import { getSvgIcon } from "../../../util/icons";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";

const GroupFieldsList = () => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const groupFields = useSelector(selectGroupResultFields);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Other variables
  const iconSize = globalFontSize * 1.2;

  // Handlers
  const handleAddGroupField = () => {
    dispatch(addGroupField());
    dispatch(setShouldExecute(false));
  };

  return (
    <>
      <Droppable droppableId="group-fields">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <FieldsHeaderTypography>
              {getTranslation("GROUPS", t, i18n)}
            </FieldsHeaderTypography>

            <Box>
              {groupFields.map((field, index) => (
                <ResultFieldsListItem
                  key={field.uniqueId}
                  resultField={field}
                  index={index}
                />
              ))}
            </Box>

            {provided.placeholder}
          </div>
        )}
      </Droppable>

      <AddFieldButton
        id="add-grouping"
        onClick={handleAddGroupField}
        variant="text"
      >
        <div style={{ marginTop: "5px", marginRight: "5px" }}>
          {getSvgIcon(
            "CREATE_NEW",
            iconSize,
            iconSize,
            theme.palette.secondary.contrastText
          )}
        </div>

        {getTranslation("ADD_GROUPING", t, i18n)}
      </AddFieldButton>
    </>
  );
};

export default GroupFieldsList;
