import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  addFieldWithPayload,
  deleteAllGroupFields,
  selectGroupResultFields,
  setShouldExecute,
  setShouldRefreshDialogTable,
} from "../../../store/slices/reportsSlice";
import { Droppable } from "react-beautiful-dnd";
import { Box, useTheme } from "@mui/material";
import {
  AddFieldButton,
  FieldsHeaderTypography,
} from "../../styles/reports/Reports.styles";
import { getTranslation } from "../../../util/utils";
import ResultFieldsListItem from "./ResultFieldsListItem";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import ConfirmAlert from "../../../store/confirm/ConfirmAlert";
import ResultFieldsDialog from "./ResultFieldsDialog";
import { REPORT_FIELDS } from "../../../util/reports-utils";
import { v4 as uuidv4 } from "uuid";
import { getSvgIcon } from "../../../util/icons";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";

const GroupFieldsList = () => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const groupFields = useSelector(selectGroupResultFields);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // State
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [fieldValue, setFieldValue] = useState(REPORT_FIELDS["NAME"]);
  const [columnTitle, setColumnTitle] = useState(
    getTranslation(REPORT_FIELDS["NAME"], t, i18n)
  );
  const [aggregationTypes, setAggregationTypes] = useState([]);
  const [orderIndex, setOrderIndex] = useState(null);
  const [sortMethod, setSortMethod] = useState(null);

  // Other variables
  const alert = {
    content: "After this action, all groups will be removed",
    confirmTitle: "Remove all groups",
    closeTitle: getTranslation("CANCEL", t, i18n),
    showConfirm: true,
  };

  const haveChanges =
    fieldValue !== REPORT_FIELDS["NAME"] ||
    columnTitle !== getTranslation(REPORT_FIELDS["NAME"], t, i18n) ||
    aggregationTypes?.length > 0 ||
    Boolean(orderIndex) ||
    Boolean(sortMethod);

  const iconSize = globalFontSize * 1.2;

  // Handlers
  const handleOpen = () => {
    setOpen(true);
    setColumnTitle(getTranslation(REPORT_FIELDS["NAME"], t, i18n));
  };

  const handleReset = () => {
    setFieldValue(REPORT_FIELDS["NAME"]);
    setColumnTitle("");
    setAggregationTypes([]);
    setOrderIndex(null);
    setSortMethod(null);
  };

  const handleClose = () => {
    dispatch(setShouldRefreshDialogTable(false));
    handleReset();
    setOpen(false);
  };

  const handleDone = () => {
    handleClose();

    const newResultField = {
      uniqueId: uuidv4(),
      displayName: columnTitle,
      field: fieldValue,
      orderIndex,
      sortMethod,
      usedForGrouping: true,
      aggregationTypes,
    };

    dispatch(addFieldWithPayload(newResultField));

    dispatch(setShouldExecute(false));
  };

  const handleDeleteAllGroupFields = () => {
    dispatch(deleteAllGroupFields());
    dispatch(setShouldExecute(false));
  };

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleConfirm = () => {
    handleDeleteAllGroupFields();
    setOpenConfirm(false);
  };

  const handleReturnCountForGroupsMenuItems = () => {
    const menuItems = [];

    for (let i = 1; i <= groupFields.length + 1; i++) {
      menuItems.push(i);
    }

    return menuItems;
  };

  const handleOnChangeFieldValue = (fieldValue) => {
    setFieldValue(fieldValue);
    setColumnTitle(getTranslation(fieldValue, t, i18n));
  };

  const handleOnChangeColumnTitle = (e) => {
    const newColumnTitle = e.target.value;
    setColumnTitle(newColumnTitle);
  };

  const handleOnChangeAggregationType = (event, newValue) => {
    const newAggregationTypes = newValue;
    setAggregationTypes(newAggregationTypes);
  };

  const handleOnChangeOrderIndex = (e) => {
    const newOrderIndex = e.target.value;

    if (!sortMethod) {
      setSortMethod("asc");
    }

    setOrderIndex(newOrderIndex);
  };

  const handleOnChangeSortMethod = (e) => {
    const newSortMethod = e.target.value;
    setSortMethod(newSortMethod);
  };

  return (
    <>
      <Droppable droppableId="group-fields">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <FieldsHeaderTypography>
              {getTranslation("GROUPS", t, i18n)}
            </FieldsHeaderTypography>

            <Box>
              {groupFields.map((field, index) => (
                <ResultFieldsListItem
                  key={field.uniqueId}
                  resultField={field}
                  index={index}
                />
              ))}
            </Box>

            {provided.placeholder}
          </div>
        )}
      </Droppable>

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <AddFieldButton
            sx={{ marginLeft: 0, marginTop: 0 }}
            id="add-grouping"
            onClick={handleOpen}
            variant="text"
          >
            <div style={{ marginTop: "5px", marginRight: "5px" }}>
              {getSvgIcon(
                "CREATE_NEW",
                iconSize,
                iconSize,
                theme.palette.secondary.contrastText
              )}
            </div>

            {getTranslation("ADD_GROUPING", t, i18n)}
          </AddFieldButton>
        </Box>

        {groupFields.length > 0 && (
          <Box>
            <AddFieldButton
              sx={{ marginLeft: 0, marginTop: 0 }}
              id="delete-all-groups"
              onClick={handleOpenConfirm}
              variant="text"
            >
              <DeleteIcon sx={{ marginRight: "5px" }} />
              Delete all
            </AddFieldButton>
          </Box>
        )}
      </Box>

      <ConfirmAlert
        isOpen={openConfirm}
        setIsOpen={setOpenConfirm}
        alert={alert}
        label="delete-all-groups-alert"
        handleConfirm={handleConfirm}
      />

      {open && (
        <ResultFieldsDialog
          isGroup={true}
          open={open}
          handleClose={handleClose}
          handleDone={handleDone}
          fieldValue={fieldValue}
          columnTitle={columnTitle}
          aggregationTypes={aggregationTypes}
          orderIndex={orderIndex}
          sortMethod={sortMethod}
          handleOnChangeFieldValue={handleOnChangeFieldValue}
          handleOnChangeColumnTitle={handleOnChangeColumnTitle}
          handleOnChangeAggregationType={handleOnChangeAggregationType}
          handleOnChangeOrderIndex={handleOnChangeOrderIndex}
          handleOnChangeSortMethod={handleOnChangeSortMethod}
          handleReturnCountForMenuItems={handleReturnCountForGroupsMenuItems}
          haveChanges={haveChanges}
          setFieldValue={setFieldValue}
          setColumnTitle={setColumnTitle}
          setAggregationTypes={setAggregationTypes}
          setOrderIndex={setOrderIndex}
          setSortMethod={setSortMethod}
        />
      )}
    </>
  );
};

export default GroupFieldsList;
