import { useCallback, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Backdrop } from "@mui/material";
import {
  ReportCardItem,
  StyledKeyboardArrowRightIcon,
} from "../styles/home/Home.styles";
import { ReportName } from "../styles/assets/reports/ReportsPage.styles";
import LongPressReportsItemMenu from "./LongPressReportsItemMenu";
import { LongPressEventType, useLongPress } from "use-long-press";
import { selectGlobalFontSize, selectTheme } from "../../store/slices/appSlice";
import { getTranslation } from "../../util/utils";

const ReportCard = ({ report, index }) => {
  // General hooks
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);
  const currentTheme = useSelector(selectTheme);

  // State
  const [anchorEl, setAnchorEl] = useState(null);
  const [longPressed, setLongPressed] = useState(false);

  // Refs
  const elementRef = useRef();

  // Callbacks
  const callback = useCallback(() => {
    handleOpen();
  }, []);

  // Longpress
  const bind = useLongPress(report?.organizationId ? callback : null, {
    filterEvents: (event) => true, // All events can potentially trigger long press
    threshold: 1000,
    captureEvent: true,
    cancelOnMovement: false,
    cancelOutsideElement: false,
    detect: LongPressEventType.Pointer,
  });

  // Other variables
  const handlers = bind("longpress context");
  const open = longPressed;

  // Handlers
  const handleOpen = () => {
    setAnchorEl(elementRef?.current);
    setLongPressed(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setLongPressed(false);
  };

  const handleNavigate = () => {
    navigate(`/reports/${report?.id}`);
  };

  return (
    <>
      {open && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer - 1 }}
          open={open}
          onClick={handleClose}
        />
      )}

      <LongPressReportsItemMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        reportId={report?.id}
      />

      <ReportCardItem
        ref={elementRef}
        key={report.name}
        {...handlers}
        onClick={handleNavigate}
        index={index}
        longPressed={open}
        currentTheme={currentTheme}
      >
        <ReportName>{getTranslation(report.name, t, i18n)}</ReportName>
        <StyledKeyboardArrowRightIcon customfontsize={globalFontSize * 1.5} />
      </ReportCardItem>
    </>
  );
};

export default ReportCard;
