import { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";
import {
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
  permissions,
} from "../../../util/utils";
import {
  BackTitle,
  HeaderGridContainer,
  HeaderWrapper,
  LeftActionButton,
  PageTitle,
  StyledChevronLeftIcon,
} from "../../../components/styles/header/Header.styles";
import { Grid, useTheme } from "@mui/material";
import CategoryTypeMenu from "../../../components/types/type-form/CategoryTypeMenu";
import { getSvgIcon } from "../../../util/icons";

const LibraryHeader = ({ goBackHandler, userRoles, actionAddFunction }) => {
  // General hooks
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);

  // States
  const [anchorEl, setAnchorEl] = useState(null);

  // Other variables
  const open = Boolean(anchorEl);
  const iconSize = globalFontSize * 1.5;

  // Handlers
  const handleOpenCategoryMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseCategoryMenu = () => {
    setAnchorEl(null);
  };

  return (
    <HeaderWrapper id="sticky-header">
      <HeaderGridContainer container>
        <Grid item xs={3}>
          <LeftActionButton id="back-button" onClick={goBackHandler}>
            <StyledChevronLeftIcon
              id="header-back-button-icon"
              customfontsize={globalFontSize * 2}
            />
            <BackTitle component="span" id="previous_page-title">
              {getTranslation("MORE", t, i18n)}
            </BackTitle>
          </LeftActionButton>
        </Grid>
        <Grid item xs={6}>
          <PageTitle variant="h6" id="title">
            {getTranslation("LIBRARY", t, i18n)}
          </PageTitle>
        </Grid>
        <Grid display="flex" justifyContent="end" item xs={3}>
          {hasAccess(
            "some",
            [
              permissions.ASSET_MANAGEMENT_HARDWARE_ADD,
              permissions.ASSET_MANAGEMENT_LOCATION_ADD,
              permissions.ASSET_MANAGEMENT_RACK_ADD,
            ],
            getPermissionsFromUserRoles(userRoles)
          ) && (
            <div onClick={handleOpenCategoryMenu}>
              {getSvgIcon(
                "CREATE_NEW",
                iconSize,
                iconSize,
                theme.palette.secondary.contrastText
              )}
            </div>
          )}
        </Grid>
        <CategoryTypeMenu
          open={open}
          handleClose={handleCloseCategoryMenu}
          categoryActionHandler={actionAddFunction}
          anchorEl={anchorEl}
        />
      </HeaderGridContainer>
    </HeaderWrapper>
  );
};

export default LibraryHeader;
