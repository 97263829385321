import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useGetAllFunctionsQuery } from "../../../store/slices/api/assetManagementSlice";
import { selectUser } from "../../../store/slices/authSlice";
import GridViewCardMedia from "./GridViewCardMedia";
import { transitionDirections } from "../../../util/utils";
import { selectTheme, setIsSearchOpen } from "../../../store/slices/appSlice";
import useOrientation from "../../../hooks/useOrientation";
import { clearHistory } from "../../../store/slices/assetListSlice";
import { Backdrop, Grid, useMediaQuery } from "@mui/material";
import {
  AssetGridViewCard,
  AssetText,
  CardMediaContainer,
  ItemsNumberText,
} from "../../styles/assets/asset-list/GridViewCard.styles";
import ErrorHandling from "../../common/ErrorHandling";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import LongPressAssetsItemMenu from "./LongPressAssetsItemMenu";
import { useCallback, useRef, useState } from "react";
import { LongPressEventType, useLongPress } from "use-long-press";

const GridViewCard = ({ row }) => {
  // General hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);
  const tabletMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.TABLET);
  const desktopMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.DESKTOP);

  // Custom hooks
  const orientation = useOrientation();

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const currentTheme = useSelector(selectTheme);

  // State
  const [anchorEl, setAnchorEl] = useState(null);
  const [longPressed, setLongPressed] = useState(false);
  const [rightClicked, setRightClicked] = useState(false);

  // Refs
  const elementRef = useRef(null);

  // Callbacks
  const callback = useCallback(() => {
    handleOpen();
  }, []);

  // Longpress
  const bind = useLongPress(mobileMatches || tabletMatches ? callback : null, {
    filterEvents: (event) => true, // All events can potentially trigger long press
    threshold: 1000,
    captureEvent: true,
    cancelOnMovement: false,
    cancelOutsideElement: false,
    detect: LongPressEventType.Pointer,
  });

  // Queries
  const {
    data: allFunctionsData,
    isLoading,
    isError,
  } = useGetAllFunctionsQuery({
    organizationId,
  });

  // Other variables
  const favoritesRecentsRouteCheck =
    pathname.includes("favorites") || pathname.includes("recents");

  const { id, displayId, name, functionId, childrenCount, images } =
    favoritesRecentsRouteCheck ? row.resource : row;
  const combinationDisplayIdAndName = displayId || name;
  const resourceFunction = allFunctionsData?.find((f) => f.id === functionId);

  const tagName = searchParams.get("tagName");
  const from = searchParams.get("from");
  const tagId = searchParams.get("tagId");
  const resourceId = searchParams.get("resourceId");

  const handlers = bind("longpress context");
  const open = longPressed || rightClicked;

  // Handlers
  const handleNavigate = () => {
    navigate(
      `/resources/${id}?direction=${
        transitionDirections.RIGHT_TO_LEFT
      }&pathname=${pathname}${Boolean(tagName) ? `&tagName=${tagName}` : ""}${
        Boolean(from) ? `&from=${from}` : ""
      }${Boolean(tagId) ? `&tagId=${tagId}` : ""}${
        Boolean(resourceId) ? `&resourceId=${resourceId}` : ""
      }`
    );

    dispatch(setIsSearchOpen(false));
    dispatch(clearHistory());
  };

  const handleOpen = () => {
    setAnchorEl(elementRef?.current);
    setLongPressed(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setLongPressed(false);
    setRightClicked(false);
  };

  const handleRightClick = (e) => {
    e.preventDefault();

    if (desktopMatches) {
      setAnchorEl(elementRef?.current);
      setRightClicked(true);
    }
  };

  return (
    <ErrorHandling isLoading={isLoading} isError={isError}>
      {open && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer - 1 }}
          open={open}
          onClick={handleClose}
        />
      )}

      <LongPressAssetsItemMenu
        resourceFunction={resourceFunction}
        anchorEl={anchorEl}
        handleClose={handleClose}
        resourceId={id}
        parentId={row?.parent?.id}
      />

      <Grid
        item
        xs={6}
        sm={orientation === "landscape" ? 1.5 : 2}
        lg={1.2}
        key={`asset-image-grid-item-${id}`}
      >
        <AssetGridViewCard
          elevation={0}
          onClick={handleNavigate}
          onContextMenu={handleRightClick}
          {...handlers}
          ref={elementRef}
          longPressed={open}
          currentTheme={currentTheme}
        >
          <CardMediaContainer
            isicon={
              !images || !images.length || images[0]?.name?.endsWith(".svg")
            }
          >
            <GridViewCardMedia
              isIcon={
                !images || !images.length || images[0]?.name?.endsWith(".svg")
              }
              functionName={resourceFunction?.name}
              category={resourceFunction?.category}
              resourceImages={images}
            />
          </CardMediaContainer>
          <AssetText id="asset-name" variant="subtitle1">
            {combinationDisplayIdAndName}
          </AssetText>
          <ItemsNumberText id="asset-children-count" variant="subtitle2">
            {`${childrenCount} items`}
          </ItemsNumberText>
        </AssetGridViewCard>
      </Grid>
    </ErrorHandling>
  );
};

export default GridViewCard;
