import { useState, forwardRef } from "react";
import {
  Grid,
  IconButton,
  Slide,
  ListItem,
  Divider,
  useTheme,
} from "@mui/material";
import {
  SearchFieldContainer,
  SortingOptionContainer,
  SortingOptionText,
  StickySearchContainer,
  StickySearchListItemText,
  StickySearchListOptions,
  StickySearchListOptionsCheckIcon,
  StickySearchListOptionsHiddenCheckIcon,
  StickySearchStyledDialogListItemButton,
  StickySearchTextField,
  StyledClearIcon,
} from "../../styles/assets/StickySearch.styles";
import { useDispatch, useSelector } from "react-redux";
import {
  selectGlobalFontSize,
  selectIsSearchOpen,
  selectSearchTerm,
  selectSortingOption,
  setIsSearchOpen,
  setSearchTerm,
  setShouldSearch,
  setSortingOption,
  SORTING_OPTIONS,
} from "../../../store/slices/appSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { messageError } from "../../../util/notification";
import {
  CancelButton,
  StyledDialog,
  StyledDialogContent,
  StyledDialogTitle,
} from "../../styles/general/General.styles";
import { getTranslation, transitionDirections } from "../../../util/utils";
import { getSvgIcon } from "../../../util/icons";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StickySearch = ({
  formatClickHandler,
  currentpageview,
  from,
  tagId,
  resourceData,
  tagName,
}) => {
  // General hooks
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const sortingOption = useSelector(selectSortingOption);
  const searchTerm = useSelector(selectSearchTerm);
  const isSearchOpen = useSelector(selectIsSearchOpen);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // State
  const [open, setOpen] = useState(false);

  // Other variables
  const iconSize = globalFontSize * 1.2;

  // Handlers
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearchClick = () => {
    if (isSearchOpen) {
      if (searchTerm?.length < 3) {
        messageError(getTranslation("SEARCH_TERM_TOO_SHORT", t, i18n));
      } else {
        dispatch(setShouldSearch(true));
        navigate(
          `/search?from=${from}${tagId ? `&tagId=${tagId}` : ""}${
            resourceData ? `&resourceId=${resourceData.id}` : ""
          }${tagName ? `&tagName=${tagName}` : ""}`
        );
      }

      dispatch(setIsSearchOpen(false));
    } else {
      dispatch(setIsSearchOpen(true));
    }
  };

  const clearSearch = () => {
    dispatch(setIsSearchOpen(false));
    dispatch(setSearchTerm(""));
    dispatch(setShouldSearch(false));

    if (location.pathname.includes("search")) {
      if (from === "resources") {
        navigate(`/resources?direction=${transitionDirections.LEFT_TO_RIGHT}`);
      } else if (from === "favorites") {
        navigate(`/favorites?direction=${transitionDirections.LEFT_TO_RIGHT}`);
      } else if (from === "recents") {
        navigate(`/recents?direction=${transitionDirections.LEFT_TO_RIGHT}`);
      } else if (from === "tags-filter") {
        navigate(
          `/tags-filter/${resourceData?.id}/${tagId}?direction=${
            transitionDirections.RIGHT_TO_LEFT
          }${tagName ? `&tagName=${tagName}` : ""}`
        );
      }
    }
  };

  return (
    <StickySearchContainer>
      <StyledDialog
        id="options-dialog"
        fullWidth
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <StyledDialogContent>
          <StyledDialogTitle>
            {getTranslation("SORT", t, i18n)}:
          </StyledDialogTitle>
          <Divider />
          <StickySearchListOptions id="sorting-options-list">
            {SORTING_OPTIONS.map((option) => {
              const { value } = option;
              return (
                <div key={value}>
                  <ListItem
                    onClick={() => {
                      dispatch(setSortingOption(option));
                      handleClose();
                    }}
                    id="change-sorting-option"
                    secondaryAction={
                      sortingOption.value === value ? (
                        <StickySearchListOptionsCheckIcon
                          customfontsize={globalFontSize * 1.5}
                        />
                      ) : (
                        <StickySearchListOptionsHiddenCheckIcon
                          customfontsize={globalFontSize * 1.5}
                        />
                      )
                    }
                    disablePadding
                  >
                    <StickySearchStyledDialogListItemButton>
                      <StickySearchListOptionsHiddenCheckIcon
                        customfontsize={globalFontSize * 1.5}
                      />
                      <StickySearchListItemText
                        primary={getTranslation(value, t, i18n)}
                      />
                    </StickySearchStyledDialogListItemButton>
                  </ListItem>
                </div>
              );
            })}
          </StickySearchListOptions>
        </StyledDialogContent>

        <CancelButton id={`cancel-button`} onClick={handleClose}>
          {getTranslation("CANCEL", t, i18n)}
        </CancelButton>
      </StyledDialog>
      <Grid container>
        <Grid
          item
          xs={location.pathname.includes("recents") && !isSearchOpen ? true : 2}
        >
          <IconButton data-testid="search-icon" onClick={handleSearchClick}>
            {getSvgIcon(
              "SEARCH",
              iconSize,
              iconSize,
              theme.palette.primary.main
            )}
          </IconButton>
        </Grid>
        {!location.pathname.includes("recents") && !isSearchOpen && (
          <SortingOptionContainer item xs>
            <SortingOptionText>
              {getTranslation(sortingOption.value, t, i18n)}
            </SortingOptionText>
            <IconButton id="open-sorting-options" onClick={handleClickOpen}>
              {getSvgIcon(
                "EXPAND",
                iconSize,
                iconSize,
                theme.palette.secondary.contrastText
              )}
            </IconButton>
          </SortingOptionContainer>
        )}
        {isSearchOpen && (
          <SearchFieldContainer item xs>
            <StickySearchTextField
              inputProps={{ "data-testid": "search-field" }}
              id="search-field"
              variant="standard"
              placeholder={getTranslation("SEARCH", t, i18n)}
              value={searchTerm}
              onChange={(e) => {
                dispatch(setSearchTerm(e.target.value));
                dispatch(setShouldSearch(false));
              }}
            />
            <IconButton id="clear-search" onClick={clearSearch}>
              <StyledClearIcon customfontsize={globalFontSize * 1.5} />
            </IconButton>
          </SearchFieldContainer>
        )}
        <Grid item>
          <IconButton onClick={() => formatClickHandler("inline")}>
            {getSvgIcon(
              "LIST_VIEW",
              iconSize,
              iconSize,
              currentpageview === "inline"
                ? theme.palette.secondary.contrastText
                : theme.palette.primary.main
            )}
          </IconButton>
          <IconButton onClick={() => formatClickHandler("app")}>
            {getSvgIcon(
              "TILE_VIEW",
              iconSize,
              iconSize,
              currentpageview === "app"
                ? theme.palette.secondary.contrastText
                : theme.palette.primary.main
            )}
          </IconButton>
        </Grid>
      </Grid>
    </StickySearchContainer>
  );
};

export default StickySearch;
