import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ConfigProvider, TreeSelect } from "antd";
import {
  useGetAllFunctionsQuery,
  useGetResourceDetailsQuery,
  useGetResourceParentsQuery,
} from "../../../store/slices/api/assetManagementSlice";
import { get_resource_children } from "../../../Constants";
import {
  DEFAULT_SORTING_OPTION,
  selectGlobalFontSize,
} from "../../../store/slices/appSlice";
import { selectUser } from "../../../store/slices/authSlice";
import KeycloakService from "../../../services/KeycloakService";
import {
  selectResourceInput,
  setResourceInput,
} from "../../../store/slices/resourceInputSlice";
import { RESOURCE_CATEGORIES, getTranslation } from "../../../util/utils";
import { LOCATION_DETAILS } from "../../../util/asset-utils";
import { Typography, Box, useTheme } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import "./ParentInput.css";
import { ParentLabel } from "../../styles/assets/asset-form/ParentInput.styles";
import { NavigationActionIcon } from "../../styles/menu/Menu.styles";
import ErrorHandling from "../../common/ErrorHandling";

const DISABLE_PARENT_CATEGORIES = {
  [RESOURCE_CATEGORIES.LOCATION]: [
    RESOURCE_CATEGORIES.RACK,
    RESOURCE_CATEGORIES.HARDWARE_ASSET,
  ],
  [RESOURCE_CATEGORIES.RACK]: [RESOURCE_CATEGORIES.HARDWARE_ASSET],
  [RESOURCE_CATEGORIES.HARDWARE_ASSET]: [],
  DEFAULT: [],
};

const ParentTreeInput = ({ isFirstSubmitted }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const resourceInput = useSelector(selectResourceInput);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // States
  const [index, setIndex] = useState(1);
  const [treeData, setTreeData] = useState([]);
  const [showHint, setShowHint] = useState(false);

  // Queries
  const {
    data: resourceParentsData,
    isLoading: isLoadingResourceParents,
    isError: isErrorResourceParents,
  } = useGetResourceParentsQuery({
    organizationId,
    index,
    size: -1,
    sortBy: DEFAULT_SORTING_OPTION.type,
    order: DEFAULT_SORTING_OPTION.order,
  });

  const {
    data: allFunctionsData,
    isLoading: isLoadingAllFunctions,
    isError: isErrorAllFunctions,
  } = useGetAllFunctionsQuery({
    organizationId,
  });

  const {
    data: resourceData,
    isLoading: isLoadingResourceDetails,
    isError: isErrorResourceDetails,
  } = useGetResourceDetailsQuery(
    {
      resourceid: resourceInput.parentId,
      organizationId,
    },
    {
      skip: !resourceInput.parentId,
    }
  );

  // Handlers
  const getFunction = (functionId) => {
    return allFunctionsData?.find((f) => f.id === Number(functionId));
  };

  const transformData = (data = []) => {
    return data.map((d) => {
      let isLeaf = true;
      if (d.childrenCount > 0) {
        isLeaf = false;
      }

      const title = d.displayId || d.name;
      const dFunction = getFunction(d.functionId);

      return {
        id: d.id,
        pId: d.parent.id,
        value: d.id,
        title,
        isLeaf,
        disabled: disableCategories.some(
          (category) => category === dFunction?.category
        ),
      };
    });
  };

  const removeDuplicates = (currentData, comingData) => {
    return comingData.filter(
      (comingItem) =>
        !currentData.some((currentItem) => comingItem.id === currentItem.id)
    );
  };

  const onLoadData = async ({ id }) => {
    const response = await fetch(
      `${get_resource_children
        .replace(":resourceid", id)
        .replace(":organizationId", organizationId)}?sort_by=${
        DEFAULT_SORTING_OPTION.type
      }&order=${DEFAULT_SORTING_OPTION.order}&size=-1`,
      {
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }
    );
    const resourceChildrenData = await response.json();
    if (resourceChildrenData) {
      const { data } = resourceChildrenData;
      const newTransformedData = transformData(data.slice());
      setTreeData((prev) => {
        const filteredDuplicates = removeDuplicates(prev, newTransformedData);
        return prev.concat(filteredDuplicates);
      });
    }
  };

  const onChange = (newValue) => {
    dispatch(
      setResourceInput({
        ...resourceInput,
        parentId: newValue,
        category: null,
        functionId: null,
        typeId: null,
        hasTypeChanged: true,
      })
    );
  };

  const handleFocus = (e) => setShowHint(true);

  const handleBlur = (e) => setShowHint(false);

  // Effects
  useEffect(() => {
    if (resourceParentsData) {
      const { data } = resourceParentsData;
      const newTransformedData = transformData(data.slice());
      setTreeData((prev) => {
        const filteredDuplicates = removeDuplicates(prev, newTransformedData);
        return prev.concat(filteredDuplicates);
      });
    }
  }, [resourceParentsData]);

  useEffect(() => {
    if (resourceData) {
      setTreeData((prev) => {
        if (prev.some((t) => t.id === resourceData.id)) return prev;
        const newTransFormData = transformData([resourceData]);

        return prev.concat(newTransFormData);
      });
    }
  }, [resourceData]);

  // Other variables
  const resourceFunction = getFunction(resourceInput.functionId);
  const disableCategories =
    DISABLE_PARENT_CATEGORIES[resourceFunction?.category ?? "DEFAULT"];
  const themeClassName =
    theme.palette.mode === "light" ? "light-tree-select" : "dark-tree-select";

  const isError =
    isFirstSubmitted &&
    resourceFunction?.category !== RESOURCE_CATEGORIES.LOCATION &&
    !resourceInput.parentId;

  const errorClassName = isError ? "custom-tree-select-error" : "";
  const emptyClassName = resourceInput.parentId ? "non-empty" : "empty";
  const parentLabelClassName =
    theme.palette.mode === "light" ? "light-label" : "dark-label";
  const parentLabelTextClassName =
    theme.palette.mode === "light" ? "light-text" : "dark-text";

  return (
    <ErrorHandling
      isLoading={
        isLoadingAllFunctions ||
        isLoadingResourceDetails ||
        isLoadingResourceParents
      }
      isError={
        isErrorAllFunctions || isErrorResourceDetails || isErrorResourceParents
      }
    >
      <Box>
        <ConfigProvider
          theme={{
            token: {
              colorBgContainer: "inherit",
              colorBgElevated: theme.palette.primary.contrastText,
              colorText: theme.palette.primary.main,
              controlItemBgActive: theme.palette.secondary.dark,
              colorTextDisabled: "#6A6A6A",
              borderRadius: "8px",
              colorBorder: isError
                ? "#f44336"
                : theme.palette.mode === "light"
                ? "#DFDFDF"
                : theme.palette.secondary.dark,
              controlHeight: 56,
              colorPrimary: theme.palette.primary.main,
              fontSize: globalFontSize,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              marginBottom: showHint ? "5px" : 0,
              visibility: showHint ? "visible" : "hidden",
            }}
          >
            <WarningIcon sx={{ color: "#F4510A" }} fontSize="small" />

            <Typography
              sx={{ textWrap: "wrap" }}
              variant="caption"
              align="center"
            >
              {getTranslation("PARENT_CHANGE_WARNING", t, i18n)}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              marginBottom: showHint ? "5px" : 0,
              visibility: showHint ? "visible" : "hidden",
            }}
          >
            <WarningIcon sx={{ color: "#F4510A" }} fontSize="small" />

            <Typography
              sx={{ textWrap: "wrap" }}
              variant="caption"
              align="center"
            >
              {getTranslation("RESOURCE_AUTO_MOVED_TOP_LEVEL", t, i18n)}
            </Typography>
          </Box>
          <div className="parent-tree-select-container">
            <TreeSelect
              id="parent-tree-select"
              className={`parent-tree-select custom-tree-select ${themeClassName} ${errorClassName} ${emptyClassName}`}
              dropdownStyle={{
                maxHeight: 400,
                overflow: "auto",
                zIndex: "5000",
              }}
              style={{ width: "100%" }}
              virtual={false}
              treeDataSimpleMode
              bordered
              value={resourceInput.parentId}
              treeDefaultExpandedKeys={[resourceInput.parentId]}
              onChange={onChange}
              loadData={onLoadData}
              treeData={treeData}
              onFocus={handleFocus}
              onBlur={handleBlur}
              showSearch
              treeNodeFilterProp="title"
              suffixIcon={
                <NavigationActionIcon customfontsize={globalFontSize}>
                  arrow_drop_down
                </NavigationActionIcon>
              }
            />
            <ParentLabel
              fontSize={globalFontSize}
              className={`parent-tree-select-label ${parentLabelClassName}`}
              htmlFor="parent-tree-select"
              id="parent-tree-select-label"
            >
              <div
                className={`parent-tree-select-label-text ${parentLabelTextClassName}`}
              >
                {getTranslation(
                  LOCATION_DETAILS[
                    resourceInput?.category ?? LOCATION_DETAILS.DEFAULT
                  ],
                  t,
                  i18n
                )}
              </div>
            </ParentLabel>
          </div>
        </ConfigProvider>
      </Box>
    </ErrorHandling>
  );
};

export default ParentTreeInput;
